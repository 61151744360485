import React from 'react';
import i18n from 'i18n-js';

import FormInputs from './FormInputs';
import Preview from './Preview';

import styles from './index.module.scss';

const Popup = ({ uploadFile, closeModal, onSend }) => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.title}>{i18n.t('uploadMediaHeadline.name')}</div>
      <div className={styles.content}>
        <FormInputs onClose={closeModal} onSend={onSend} />
        <Preview type={uploadFile.type} scr={URL.createObjectURL(uploadFile.data)} />
      </div>
    </div>
  </div>
);

export default Popup;
