import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import i18n from 'i18n-js';

import Region from './Region';
import Footer from './Footer';

import styles from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(),
  externalLink: Yup.string(),
  local: Yup.string(),
});

const INITIAL_VALUES = {
  title: '',
  externalLink: '',
  local: '',
};

const FormInputs = ({ onSend, onClose }) => (
  <Formik initialValues={INITIAL_VALUES} validationSchema={VALIDATION_SCHEMA} validateOnMount onSubmit={onSend}>
    {({ values, isValid, handleChange, setFieldTouched, handleSubmit }) => (
      <div className={styles.container}>
        <div className={clsx([styles.inputContainer, styles.inputContainerGap])}>
          <div className={styles.label}>{i18n.t('uploadMediaFieldNameTitle.name')}</div>
          <input
            className={styles.input}
            placeholder={i18n.t('uploadMediaFieldNameTitle.name')}
            placeholderTextColor={'#6b6b6b'}
            value={values.title}
            onChange={handleChange('title')}
            onBlur={() => {
              setFieldTouched('title');
            }}
          />
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.label}>{i18n.t('uploadMediaFieldNameURL.name')}</div>
          <textarea
            rows={3}
            className={clsx([styles.input, styles.textField])}
            placeholder={i18n.t('uploadMediaFieldNameURL.name')}
            value={values.externalLink}
            onChange={handleChange('externalLink')}
            onBlur={() => {
              setFieldTouched('externalLink');
            }}
          />
        </div>

        <Region onChange={value => handleChange('local')(value)} value={values.local} />

        <Footer {...{ isValid, handleSubmit, onClose }} />
      </div>
    )}
  </Formik>
);

export default FormInputs;
