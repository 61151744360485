import { Colours, CommonStyles } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';

export default {
  container: {
    backgroundColor: Colours.black,
    height: '100vh',
  },
  videoContainer: {
    ...CommonStyles.flexCenter,
    width: isLandscapeMode() ? '60vw' : '80vw',
    height: '50vh',
    marginTop: '20vh',
    marginLeft: isLandscapeMode() ? '20vw' : '10vw',
  },
  video: {
    width: isLandscapeMode() ? '40vw' : '60vw',
    height: '40vh',
  },
  closeContainer: {
    position: 'absolute',
    right: '10vw',
    top: '15vh',
    cursor: 'pointer',
    zIndex: 100,
  },
  iconClose: {
    fontSize: '5vmin',
    color: Colours.white,
    fontWeight: 'regular',
  },
  iconArrow: {
    width: '5vmin',
    height: '5vmin',
    fill: Colours.white,
  },
  slideContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
  },
  fadeContainer: {
    width: isLandscapeMode() ? '60vw' : '80vw',
    height: '60vh',
    overflow: 'hidden',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  wrapChild: {
    maxWidth: '100%',
    position: 'relative',
    opacity: '0',
  },
  img: {
    width: isLandscapeMode() ? '60vw' : '80vw',
    height: '60vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
  },
};
