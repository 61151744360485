import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Colours, CommonStyles, StyleClass } from '../../../resources';
import { isMobile } from '../../../lib/utils';
import { Slide } from '@material-ui/core';
import './style.css';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
  },
  reloadContainer: {
    ...CommonStyles.flexCenter,
    marginTop: '8vmin',
    marginBottom: '10vmin',
    width: isMobile() ? '8vmin' : '5vmin',
    height: isMobile() ? '8vmin' : '5vmin',
    borderRadius: isMobile() ? '4vmin' : '2.5vmin',
    backgroundColor: Colours.white,
    cursor: 'pointer',
  },
  iconReload: {
    fontSize: isMobile() ? '4.5vmin' : '2.8vmin',
  },
};

export default ({ children, loading, listRef, isInModal = false }) => (
  <div
    style={styles.container}
    ref={ref => {
      listRef && listRef(ref);
    }}>
    {children}
    <Slide direction="up" in={loading} mountOnEnter unmountOnExit>
      <div
        style={isInModal ? { ...styles.reloadContainer, position: 'absolute', bottom: 0 } : styles.reloadContainer}
        className={StyleClass.imageTouch}>
        <Icon className="fa fa-redo rotate" style={styles.iconReload} />
      </div>
    </Slide>
  </div>
);
