import React, { useState, useEffect } from 'react';
import qs from 'qs';

import MobileAppLinks from './MobileAppLinks';
import Logos from './Logos';

import styles from './index.module.scss';
import { APP_STORAGE } from '../../../../constants/common';

const Header = props => {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkHeaderVisiblity = () => {
    const queryResult = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const { id, token } = queryResult;
    const verifyInApp = localStorage.getItem(APP_STORAGE.webView);
    if ((id && token) || verifyInApp) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    checkHeaderVisiblity();
  }, []);

  useEffect(() => {
    checkHeaderVisiblity();
  }, [props]);

  return !isMobileView ? (
    <div className={styles.container}>
      <Logos />
      <MobileAppLinks />
    </div>
  ) : null;
};

export default Header;
