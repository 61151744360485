export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
    height: 54,
  },

  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 5px',
  },

  button: {
    width: 25,
    height: 25,
  },

  labelComment: {
    marginLeft: 5,
    fontSize: 17,
    fontFamily: 'TeleNeoWeb',
    fontWeight: '600',
  },

  rightBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  creatorContainer: {
    display: 'flex',
    flex: 1,
  },

  creator: {
    fontSize: 16,
    fontFamily: 'TeleNeoWeb',
    fontWeight: '600',
  },

  date: {
    fontSize: 14,
    fontFamily: 'TeleNeoWeb',
  },
};
