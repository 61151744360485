import React, { useState } from 'react';
import i18n from 'i18n-js';

import { SVG } from '../../../../../../resources';

import styles from './index.module.scss';

const SearchInput = ({ onSearchInput }) => {
  const [value, setValue] = useState('');

  const onChangeText = event => {
    setValue(event.target.value);
  };

  const onClear = () => {
    setValue('');
    onSearchInput(null);
  };

  const onSearch = () => {
    onSearchInput(value);
  };

  const onKeyPress = event => {
    if (event.keyCode === 13) {
      onSearch();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchButton} onClick={onSearch}>
        <SVG.LoupeNew className={styles.loupeIcon} />
      </div>
      <input
        className={styles.input}
        value={value}
        placeholder={i18n.t('uploadMediaSearchField.name')}
        onChange={onChangeText}
        onKeyDown={onKeyPress}
      />

      {value ? (
        <div className={styles.clearButton} onClick={onClear}>
          <SVG.CancelNew className={styles.clearIcon} />
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
