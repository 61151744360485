import React from 'react';
import i18n from 'i18n-js';
import clsx from 'clsx';

import styles from './styles.module.scss';

// `url(${PNG.bgLionLeft}), url(${PNG.bgStartPageBlue}),

const Info = ({ charts, onPressMore }) => (
  <div className={styles.container}>
    <div className={styles.description}>{i18n.t('monthlyRankingCopy.name')}</div>

    <div className={styles.optionContainer}>
      <div className={styles.square} />
      <div className={styles.optionName}>{i18n.t('lastScore.name')}</div>
    </div>

    <div className={styles.optionContainer}>
      <div className={clsx([styles.square, styles.white])} />
      <div className={styles.optionName}>{charts.month_last_score_month > 0 ? i18n.t(`monthNames.${charts.month_last_score_month - 1}`) : ''}</div>
    </div>

    <div className={styles.button} onClick={onPressMore}>
      {i18n.t('moreInformation.name')}
    </div>
  </div>
);

export default Info;
