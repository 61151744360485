import React from 'react';
import i18n from 'i18n-js';

import CloseButton from '../../../../Common/CloseButton';

import styles from './index.module.scss';

const Header = ({ history, title }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title || i18n.t('samsungFact.name')}</div>
    <div className={styles.closeContainer}>
      <CloseButton onPress={() => history.goBack()} />
    </div>
  </div>
);

export default Header;
