import React from 'react';

const HashTagText = ({ text, hashTagStyle }) => {
  let currentStr = text;
  const result = [];
  const hashtags = currentStr.match(/#\w+/g);

  if (!hashtags) {
    return [text];
  }

  for (let i = 0; i < hashtags.length; i++) {
    let matchPos = currentStr.search(hashtags[i]);

    result.push(currentStr.substring(0, matchPos));
    result.push(
      <span key={'hashtag-' + i} style={hashTagStyle}>
        {hashtags[i]}
      </span>,
    );

    currentStr = currentStr.substring(matchPos + hashtags[i].length);

    if (i + 1 === hashtags.length) {
      result.push(currentStr.substring(0));
    }
  }

  return result;
};

export default HashTagText;
