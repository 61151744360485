import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.8 42.31">
      <title>icon_cup</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path d="M48.75,10.68c-.18-2.18-.9-3.15-3.09-3.26a57.18,57.18,0,0,0-6,0c-1.55.08-2.12-.37-2.11-2,0-3.47-2-5.37-5.47-5.38H16.72c-3.47,0-5.49,1.91-5.47,5.38,0,1.65-.56,2.1-2.11,2a57.19,57.19,0,0,0-6,0C1,7.53.24,8.5.05,10.68c-.43,5,2,8.54,6,11a15.81,15.81,0,0,0,6.53,2.44c2.85.22,4.55,1.81,6.32,3.53a5.42,5.42,0,0,1,1.42,5.73c-.62,2-2.39,2.38-4.27,2.56-3.13.29-5.1,2.29-4.8,4.88a2.13,2.13,0,0,0,1.46,1.48c3.88,0,19.44,0,23.33,0a2.14,2.14,0,0,0,1.45-1.48c.3-2.59-1.67-4.59-4.8-4.88-1.88-.18-3.65-.51-4.26-2.56a5.41,5.41,0,0,1,1.41-5.73c1.77-1.72,3.47-3.31,6.32-3.53a15.76,15.76,0,0,0,6.53-2.44C46.75,19.22,49.18,15.72,48.75,10.68ZM3.43,11.6c.08-.41.64-1,1-1,2.18-.1,4.37-.05,6.44-.05.71,3.53,1.38,6.84,2,10.15C8.61,21.57,2.57,15.92,3.43,11.6Zm32.46,9.09c.67-3.31,1.33-6.62,2-10.15,2.06,0,4.25,0,6.43.05.36,0,.92.6,1,1C46.23,15.92,40.19,21.57,35.89,20.69Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
