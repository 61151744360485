import React from 'react';
import i18n from 'i18n-js';
import clsx from 'clsx';

import styles from './index.module.scss';

const ReportDialog = ({ onReportPost, closeReportDialog }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{i18n.t('ReportPostHeadline.name')}</div>
      <div className={styles.description}>{i18n.t('ReportPostCopy.name')}</div>
      <div className={styles.buttons}>
        <div className={clsx([styles.button, styles.buttonGap])} onClick={onReportPost}>
          <div className={styles.buttonText}>{i18n.t('ReportPostSelectOption1.name')}</div>
        </div>

        <div className={styles.button} onClick={closeReportDialog}>
          <div className={styles.buttonText}>{i18n.t('ReportPostSelectOption2.name')}</div>
        </div>
      </div>
    </div>
  );
};

export default ReportDialog;
