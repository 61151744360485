import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M16.005,36c0,0.55-0.45,1-1,1h-8c-0.55,0-1-0.45-1-1v-8c0-0.55,0.45-1,1-1h8c0.55,0,1,0.45,1,1V36z M58.005,28c0-0.55-0.45-1-1-1h-8c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1V28z M37.005,28c0-0.55-0.45-1-1-1 h-8c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1V28z" />
    </svg>
  </SvgIcon>
);
