import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M53.497,5.992H39.018V5.045C39.018,3.92,38.094,3,36.971,3h-9.929c-1.125,0-2.041,0.92-2.041,2.045v0.947 H10.505c-0.83,0-1.504,0.674-1.504,1.506C9.001,8.326,9.675,9,10.505,9h42.992c0.826,0,1.504-0.674,1.504-1.502 C55.001,6.666,54.323,5.992,53.497,5.992z M13.263,58c0,1.1,0.9,2,2,2h33.302c1.1,0,2-0.9,2-2l1.604-46H11.701L13.263,58z M39.501,22.5c0-0.828,0.671-1.5,1.5-1.5c0.829,0,1.5,0.672,1.5,1.5v27c0,0.828-0.671,1.5-1.5,1.5c-0.829,0-1.5-0.672-1.5-1.5V22.5 z M30.5,22.578c0-0.828,0.671-1.5,1.5-1.5c0.829,0,1.5,0.672,1.5,1.5v26.918c0,0.828-0.671,1.5-1.5,1.5c-0.829,0-1.5-0.672-1.5-1.5 V22.578z M21.501,22.5c0-0.828,0.671-1.5,1.5-1.5c0.829,0,1.5,0.672,1.5,1.5v27c0,0.828-0.671,1.5-1.5,1.5 c-0.829,0-1.5-0.672-1.5-1.5V22.5z" />
    </svg>
  </SvgIcon>
);
