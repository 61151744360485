import { Colours } from '../../../../../resources';

export default {
  topTitleBox: {
    backgroundColor: Colours.whiteGray,
    padding: '1rem',
    position: 'relative',
  },
  userName: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  userNameTitle: {
    display: 'block',
  },
  addTitleDate: {
    paddingLeft: '5px',
    borderLeft: '1px solid',
  },
  title: {
    fontWeight: 'bold',
    marginTop: '10px',
  },
  titleTags: {
    fontWeight: 'normal',
  },
  closeContainer: {
    display: 'none',
  },
  iconClose: {
    fontSize: '3vmin',
    fontWeight: 'regular',
    color: 'white',
  },
  likeCommentBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  like: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  likeBtn: {
    padding: '10px 5px',
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  messageBtn: {
    padding: '10px 5px',
  },
  icon: {},
  likeText: {},
};
