import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M21.59,20H28v16h-4L21.59,20z M41,36h9V20h-9V36z M60.26,18l0.69-3c0.26-0.8-0.18-1.67-0.98-1.93
        c-0.19-0.06-0.38-0.09-0.58-0.07H20.53l0.75,5H60.26z M30,36h9V20h-9V36z M53.51,43H23.38c-0.4-0.03-0.73-0.31-0.82-0.7l-4.76-31
        C17.5,9.42,15.9,8.03,14,8H3.51c-0.83,0-1.5,0.67-1.5,1.5S2.68,11,3.51,11H14c0.4,0.03,0.73,0.31,0.82,0.7l4.76,31
        c0.3,1.88,1.9,3.27,3.8,3.3h30.13c0.83,0,1.5-0.67,1.5-1.5S54.34,43,53.51,43z M52,20v16h2.21c1.17-0.05,2.16-0.86,2.44-2l3.16-14
        H52z M47,48c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4S49.21,48,47,48z M25,48c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4
        S27.21,48,25,48z"
      />
    </svg>
  </SvgIcon>
);
