import { call, put } from 'redux-saga/effects';
import { getNodeDetail as apiGetNodeDetail } from '../../lib/api';
import { parseURLSearch } from '../../lib/utils';
import NodeActions from '../node';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getNodeDetail({ auth, location, showPublished = true, query }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetNodeDetail, authRefreshed, location, showPublished, parseURLSearch(query));
      if (!ret.error) {
        yield put(NodeActions.getNodeDetailSuccess(ret));
      } else {
        yield put(NodeActions.requestFailed('get node detail'));
        if (ret.status === 403) {
          if (getNavigation().location.pathname !== '/') {
            localStorage.setItem('lastPath', getNavigation().location.pathname);
          }
          yield put(AuthActions.signOut());
        }
      }
    }
  } catch (err) {
    yield put(NodeActions.requestFailed('get node detail', err));
  }
}
