import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import i18n from 'i18n-js';

import { authSelector, userSettingsSelector } from '../../../../../redux/selector';
import { getUnseenCount } from '../../../../../redux/selector/notifications';

import { trackScreen } from '../../../../../lib/matomo';

import { Link } from '../../../../index';

import { Const, SVG } from '../../../../../resources';
import playStore from '../../../../../resources/png/google_play.png';

import { MEMBERSHIP_DETAILS, TROPHY_TYPE } from '../../../../../constants/common';

import styles from './index.module.scss';
import { UserSettingsActions } from '../../../../../redux';

const MobileAppLinks = ({ auth, web_matomo, unseenCount, userSettings, getUserClubMedal }) => {
  const onTrackScreen = path => {
    if (web_matomo) trackScreen(path);
  };

  const [memberShipType, setMemberShipType] = useState(false);

  // Add Membership in Mobile Device - #34091
  // Load memberships from locale file - #34087
  // else use default.
  const membershipLinks = {
    Gold: i18n.t('shopGold') || MEMBERSHIP_DETAILS.Gold,
    Silver: i18n.t('shopSilver') || MEMBERSHIP_DETAILS.Silver,
    Bronze: i18n.t('shopBronze') || MEMBERSHIP_DETAILS.Bronze,
  };

  const openShop = () => {
    // Use locale memberships shop url - #34079
    // Use only shop link - #34079 - note-#10
    let shopUrl = i18n.t('shop.link_web');
    let shopUrl2 = shopUrl.slice(shopUrl.indexOf('iframe') + 7);

    if (shopUrl2.includes('/?embedded=1')) {
      // (!) The link to the trophy shop should always have a "/" at the end! - #34079 - note-#12
      shopUrl2 = shopUrl2.replace('?embedded=1', '');
    }

    window.open(shopUrl2, '_blank');
  };

  const openAppstor = () => {
    window.open(Const.appStoreLink);
  };

  const openPlayStore = () => {
    window.open(Const.playStoreLink);
  };

  const renderUnseenCount = () => {
    if (unseenCount === 0) {
      return null;
    }

    return (
      <div className={styles.counter}>
        <div className={styles.counterText}>{unseenCount}</div>
      </div>
    );
  };

  // Add Membership in Mobile Device - #34091
  // Get logged in user current membership status
  // const checkUserMembership = () => {
  // getUserMembershipType(auth).then(response => {
  //   // console.log({memberShipType: response});
  //   // To Fix #34138 validate user trophy befor assigning to state.
  //   if (response && response.current_medal && membershipLinks[response.current_medal] && membershipLinks[response.current_medal].link_web) {
  //     setMemberShipType(response.current_medal);
  //   }
  // });
  // };

  useEffect(() => {
    if (
      userSettings &&
      userSettings.currentMedal &&
      membershipLinks[userSettings.currentMedal] &&
      membershipLinks[userSettings.currentMedal].link_web
    ) {
      setMemberShipType(userSettings.currentMedal);
    } else {
      getUserClubMedal(auth);
    }
  }, [auth]);

  useEffect(() => {
    // Get logged in user current membership status - #33721
    // To Fix #34138 validate user trophy befor assigning to state.
    if (
      userSettings &&
      userSettings.currentMedal &&
      membershipLinks[userSettings.currentMedal] &&
      membershipLinks[userSettings.currentMedal].link_web
    ) {
      setMemberShipType(userSettings.currentMedal);
    }
  }, [userSettings]);

  // Add Membership in Mobile Device - #34091
  // Display trophy icon on base of current membership type
  const displayClubMembership = () => {
    const navigateToMembership = type => {
      // Memberships from locale file - #34087
      if (membershipLinks[type] && membershipLinks[type].link_web) {
        // Shop Redirection to new window instead of iframe - #34079
        let shopUrl = membershipLinks[type].link_web;
        let shopUrl2 = shopUrl.slice(shopUrl.indexOf('iframe') + 7);
        if (shopUrl2.includes('/?embedded=1')) {
          // (!) The link to the trophy shop should always have a "/" at the end! - #34079 - note-#12
          shopUrl2 = shopUrl2.replace('?embedded=1', '');
        }
        // Open link in new window
        window.open(shopUrl2, '_blank');
      }
    };

    let trophyIcon = <></>;
    switch (memberShipType) {
      case TROPHY_TYPE.gold:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.gold)}>
            <SVG.TrophyGold className={styles.icon} />
          </div>
        );
        break;

      case TROPHY_TYPE.silver:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.silver)}>
            <SVG.TrophySilver className={styles.icon} />
          </div>
        );
        break;

      case TROPHY_TYPE.bronze:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.bronze)}>
            <SVG.TrophyBronze className={styles.icon} />
          </div>
        );
        break;

      default:
        trophyIcon = <></>;
        break;
    }
    return trophyIcon;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.buttonsContainer}>
          <Link className={clsx([styles.button, styles.leftButton])} to={'/notifications'} onClick={() => onTrackScreen('/notifications')}>
            <SVG.NotificationBell className={styles.icon} />
            {renderUnseenCount()}
          </Link>

          <div className={styles.button} onClick={openShop}>
            <SVG.Shop className={styles.icon} />
          </div>
          {/* Add Membership in Mobile Device - #34091 */}
          {displayClubMembership()}
          <Link className={styles.button} to={'/about'} onClick={() => onTrackScreen('/about')}>
            {/* <img src={PNG.NewWiciAppLogo} /> */}
            <SVG.WiciLogo
              className={clsx([styles.icon, styles.appLogo])}
              hoverChange
              hoverIcon="white"
              defaultIcon="black"
              style={{
                width: '100px',
                height: '100%',
                // marginTop: '-10px'
              }}
            />
          </Link>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.button} onClick={openAppstor}>
            <SVG.Apple className={styles.appStoreIcon} />
          </div>

          <div className={clsx([styles.button, styles.rightButton])} onClick={openPlayStore}>
            {/* <SVG.PlayStore className={styles.playStoreIcon} /> */}
            <img src={playStore} className={styles.playStoreIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...userSettingsSelector(state),
  ...authSelector(state),
  unseenCount: getUnseenCount(state),
});

const mapDispatchToProps = dispatch => ({
  getUserClubMedal: auth => dispatch(UserSettingsActions.getUserClubMedal(auth)),
  getUserClubMedalSuccess: (auth, currentMedal) => dispatch(UserSettingsActions.getUserClubMedalSuccess(auth, currentMedal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAppLinks);
