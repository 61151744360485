import React from 'react';

import Cup from '../Cup';

import styles from './styles.module.scss';

const FirstPlace = ({ LastRankingPos }) => (
  <div className={styles.container}>
    <Cup counter={LastRankingPos} cupStyle={styles.cup} counterStyle={styles.counter} />
  </div>
);

export default FirstPlace;
