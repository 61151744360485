import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M18,25V18A13.79,13.79,0,0,1,31.58,4H32A13.79,13.79,0,0,1,46,17.58h0V25H43V18A10.76,10.76,0,0,0,
        32.49,7H32A10.76,10.76,0,0,0,21,17.51V25Zm34,5H12V57H52Zm1-3a2,2,0,0,1,2,2V58a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2V29a2,2,0,0,1,2-2Z"
      />
    </svg>
  </SvgIcon>
);
