import React from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

const Stepper = ({ stepCount = 0, currentStep, onClick }) => {
  const generateCircles = () => {
    let circles = [];

    for (let index = 0; index < stepCount; index++) {
      const style = clsx([styles.largeCircle, index === currentStep ? styles.active : null]);

      circles.push(<div className={style} key={`bigCircle-${index}`} onClick={() => onClick(index)} />);

      if (index !== stepCount - 1) {
        circles.push(
          <div className={styles.smallCircle} key={`miniCircle-${index}-1`} />,
          <div className={styles.smallCircle} key={`miniCircle-${index}-2`} />,
          <div className={styles.smallCircle} key={`miniCircle-${index}-3`} />,
        );
      }
    }

    return circles;
  };

  return <div className={styles.container}>{generateCircles()}</div>;
};

export default Stepper;
