import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon viewBox="0 0 160.16 11.84" {...props}>
    <svg width="192" height="15" viewBox="0 0 160.16 11.84">
      <g id="Slogan">
        <path d="M0,9.63V.21H6V1.94H2V4H5.67V5.6H2V7.91H6.09V9.63Z" />
        <path d="M7.71,9.63V.21h3.62a3.41,3.41,0,0,1,2.2.63,2.42,2.42,0,0,1,.89,2,2.63,2.63,0,0,1-.63,1.8,2.13,2.13,0,0,1-.72.54A1.54,1.54,0,0,1,14,5.9a2.54,2.54,0,0,1,.29,1c0,.15.07.62.13,1.41a4.69,4.69,0,0,0,.15,1,2.67,2.67,0,0,0,.17.34H12.62a2,2,0,0,1-.14-.53c0-.12,0-.52-.1-1.22a4,4,0,0,0-.24-1.3A1,1,0,0,0,11.5,6a2,2,0,0,0-.61-.07H9.73v3.7Zm2-5.22h1.11A1.64,1.64,0,0,0,12.1,4a1.36,1.36,0,0,0,.3-.94A1.2,1.2,0,0,0,11.9,2a2,2,0,0,0-1-.2H9.73Z" />
        <path d="M16.2,9.63V.21h2V7.88h3.69V9.63Z" />
        <path d="M23.13,9.63V.21h6V1.94h-4V4H28.8V5.6H25.16V7.91h4.07V9.63Z" />
        <path d="M30.84,9.63V.21H34.2A5.16,5.16,0,0,1,35.75.4a2.09,2.09,0,0,1,1.16.9,2.21,2.21,0,0,1,.34,1.21,2.1,2.1,0,0,1-1.35,2,2.28,2.28,0,0,1,1.72,2.33,2.58,2.58,0,0,1-1.16,2.3,3.57,3.57,0,0,1-1.94.47Zm2-5.61h.91a1.81,1.81,0,0,0,1.06-.23,1.07,1.07,0,0,0,.43-.9A1,1,0,0,0,34.74,2a2.24,2.24,0,0,0-1-.15h-.91Zm0,4H34a1.92,1.92,0,0,0,1.2-.29,1.2,1.2,0,0,0,.44-1A1.15,1.15,0,0,0,35,5.62a2.63,2.63,0,0,0-1.14-.18h-1Z" />
        <path d="M39.09,9.63V.21h6V1.94h-4V4h3.64V5.6H41.11V7.91h4.07V9.63Z" />
        <path d="M46.79,9.63V.21h2.05l2.95,6.06V.21h1.94V9.63h-2l-3-6.05V9.63Z" />
        <path d="M57.7,7.6V9.18a3.49,3.49,0,0,1-.26,1.47,2.07,2.07,0,0,1-.82.85,2.53,2.53,0,0,1-1,.34v-1a1.09,1.09,0,0,0,.67-.46,1.57,1.57,0,0,0,.21-.76h-.88v-2Z" />
        <path d="M60.61.21h2l1.12,6.46L65,.21H66.9l1.2,6.48L69.24.21h2L69.13,9.63h-2L65.92,3.24,64.75,9.63h-2Z" />
        <path d="M74.08.21h2.07l3,9.42H77l-.59-2.1H73.76l-.6,2.1H71.09ZM74.19,6H76l-.91-3.25Z" />
        <path d="M79.57,6.88h2a2.15,2.15,0,0,0,.25.82,1.21,1.21,0,1,0,1.8-1.54,2.3,2.3,0,0,0-.56-.33l-.7-.25a6.53,6.53,0,0,1-1.68-.82,2.6,2.6,0,0,1-1-2.13A2.47,2.47,0,0,1,80.41.79,3,3,0,0,1,82.63,0a3.07,3.07,0,0,1,2.65,1.21,3.36,3.36,0,0,1,.54,1.49h-2a1.24,1.24,0,0,0-.35-.8,1.3,1.3,0,0,0-.89-.28,1.11,1.11,0,0,0-.74.22.88.88,0,0,0-.31.73,1,1,0,0,0,.47.85A5.34,5.34,0,0,0,83.39,4a5.9,5.9,0,0,1,1.85.91,2.45,2.45,0,0,1,1,2A2.68,2.68,0,0,1,85.31,9a3.48,3.48,0,0,1-2.45.83,3.05,3.05,0,0,1-2.73-1.37A3.77,3.77,0,0,1,79.57,6.88Z" />
        <path d="M94.48,9.63H92.23L89.65.21h2.13L93.4,7,95,.21h2.06Z" />
        <path d="M98.1,9.63V.21h6V1.94h-4V4h3.64V5.6h-3.64V7.91h4.07V9.63Z" />
        <path d="M105.81,9.63V.21h3.62a3.41,3.41,0,0,1,2.2.63,2.42,2.42,0,0,1,.89,2,2.63,2.63,0,0,1-.63,1.8,2.13,2.13,0,0,1-.72.54,1.54,1.54,0,0,1,.89.72,2.52,2.52,0,0,1,.3,1q0,.23.12,1.41a4.69,4.69,0,0,0,.15,1,2.67,2.67,0,0,0,.17.34h-2.08a2,2,0,0,1-.14-.53c0-.12,0-.52-.1-1.22a3.66,3.66,0,0,0-.25-1.3A.93.93,0,0,0,109.6,6a2,2,0,0,0-.61-.07h-1.16v3.7Zm2-5.22h1.11A1.64,1.64,0,0,0,110.2,4a1.36,1.36,0,0,0,.3-.94A1.2,1.2,0,0,0,110,2a2,2,0,0,0-1-.2h-1.15Z" />
        <path d="M114.3,9.63V.21h3.36a5.11,5.11,0,0,1,1.55.19,2.15,2.15,0,0,1,1.17.9,2.29,2.29,0,0,1,.33,1.21,2.1,2.1,0,0,1-1.35,2,2.27,2.27,0,0,1,1.72,2.33,2.58,2.58,0,0,1-1.16,2.3,3.55,3.55,0,0,1-1.94.47Zm2-5.61h.9a1.79,1.79,0,0,0,1.06-.23,1,1,0,0,0,.43-.9A1,1,0,0,0,118.2,2a2.21,2.21,0,0,0-1-.15h-.9Zm0,4h1.09a2,2,0,0,0,1.2-.29,1.23,1.23,0,0,0,.44-1,1.16,1.16,0,0,0-.59-1.1,2.63,2.63,0,0,0-1.14-.18h-1Z" />
        <path d="M122.54,9.63V.21h2V9.63Z" />
        <path d="M126.4,9.63V.21h2l3,6.06V.21h1.94V9.63h-2l-3-6.05V9.63Z" />
        <path d="M135.18,9.63V.21h2.91a4.21,4.21,0,0,1,3,1,4.74,4.74,0,0,1,1.35,3.68,5,5,0,0,1-1.27,3.63,3.21,3.21,0,0,1-1.6.92,6,6,0,0,1-1.5.16Zm2-1.72h.91a2.08,2.08,0,0,0,1.56-.51,3.57,3.57,0,0,0,.75-2.51,3.54,3.54,0,0,0-.63-2.23,2.06,2.06,0,0,0-1.74-.72h-.85Z" />
        <path d="M143.89,9.63V.21h6V1.94h-4V4h3.64V5.6h-3.64V7.91H150V9.63Z" />
        <path d="M153.27,9.63V1.94H151V.21h6.6V1.94h-2.3V9.63Z" />
        <path d="M160.16,7.6v2h-2.07v-2h2.07Z" />
      </g>
    </svg>
  </SvgIcon>
);
