import React from 'react';
import i18n from 'i18n-js';

import ProgressCircle from './ProgressCircle';
import Info from './Info';
// import Background from './Background';

import styles from './styles.module.scss';

export default ({ charts, onPressMore }) => (
  <div id={'slide-1'} className={styles.container}>
    <div className={styles.title}>{i18n.t('monthlyRanking.name')}</div>

    <div className={styles.content}>
      <div className={styles.innerContent}>
        <ProgressCircle {...{ charts }} />
        <Info {...{ charts, onPressMore }} />
      </div>
    </div>

    {/* <Background /> */}
  </div>
);
