import { persistCombineReducers } from 'redux-persist';
import { routerReducer } from 'react-router-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthActions, { authReducer as auth } from './auth';
import SocialActions, { socialReducer as social } from './social';
import ChartActions, { chartReducer as chart } from './chart';
import NewsActions, { newsReducer as news } from './news';
import NodeActions, { nodeReducer as node } from './node';
import ELearningActions, { elearningReducer as elearning } from './elearning';
import FactSheetsActions, { factSheetsReducer as factSheets } from './factSheets';
import NotificationsActions, { notificationsReducer as notifications } from './notifications';
import QuizActions, { quizReducer as quiz } from './quiz';
import NotificationSettingsActions, { notificationSettingsReducer as notificationSettings } from './notificationSettings';
import TableActions, { tableReducer as table } from './table';
import SinglePageActions, { singlePageReducer as singlePage } from './singlePage';
import UserSettingsActions, { userSettingsReducer as userSettings } from './userSettings';

const config = {
  key: 'root',
  blacklist: ['table', 'userSettings', 'social', 'notifications', 'notificationSettings', 'quiz'],
  storage: AsyncStorage,
};
export const reducer = persistCombineReducers(config, {
  auth,
  social,
  chart,
  news,
  node,
  elearning,
  factSheets,
  notifications,
  notificationSettings,
  quiz,
  table,
  singlePage,
  userSettings,
  routing: routerReducer,
});

export {
  AuthActions,
  SocialActions,
  ChartActions,
  NewsActions,
  NodeActions,
  ELearningActions,
  FactSheetsActions,
  NotificationsActions,
  NotificationSettingsActions,
  QuizActions,
  TableActions,
  SinglePageActions,
  UserSettingsActions,
};
