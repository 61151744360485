import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.95 168.2">
      <defs>
        <style>
          {
            '.cls-1,.cls-2,.cls-3,.cls-4{fill-rule:evenodd;}.cls-1{fill:url(#Unbenannter_Verlauf_5);}.cls-2{fill:url(#Unbenannter_Verlauf_2);}.cls-3{fill:url(#Unbenannter_Verlauf_3);}.cls-4{fill:url(#Unbenannter_Verlauf_4);}'
          }
        </style>
        <linearGradient
          id="Unbenannter_Verlauf_5"
          x1="1598.11"
          y1="-547.19"
          x2="1588.68"
          y2="-542.33"
          gradientTransform="translate(-18524.72 12349.44) scale(11.64 22.55)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4696d2" />
          <stop offset="0.01" stopColor="#4598d3" />
          <stop offset="0.26" stopColor="#3fb9eb" />
          <stop offset="0.51" stopColor="#4ac1ed" />
          <stop offset="0.76" stopColor="#5cc4e6" />
          <stop offset="1" stopColor="#60c4e5" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          x1="1566.22"
          y1="-562.13"
          x2="1548.92"
          y2="-562.13"
          gradientTransform="translate(-14167.07 4412.49) scale(9.15 7.7)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ffe00a" />
          <stop offset="0.41" stopColor="#fbbc0f" />
          <stop offset="0.78" stopColor="#f7a418" />
          <stop offset="1" stopColor="#f59a1b" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          x1="1631.76"
          y1="-552.08"
          x2="1621.85"
          y2="-539.22"
          gradientTransform="translate(-24411.89 6490.77) scale(15.02 11.58)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e8404a" />
          <stop offset="1" stopColor="#c31262" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_4"
          x1="1624.12"
          y1="-563.58"
          x2="1628.55"
          y2="-557.84"
          gradientTransform="translate(-24411.89 6475.96) scale(15.02 11.57)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#32a071" />
          <stop offset="0.07" stopColor="#2da771" />
          <stop offset="0.48" stopColor="#4fb26f" />
          <stop offset="0.8" stopColor="#61b668" />
          <stop offset="1" stopColor="#64b664" />
        </linearGradient>
      </defs>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path className="cls-1" d="M3,2.55c-1.89,2-3,5.15-3,9.23V156.39c0,4.07,1.09,7.19,3.05,9.15l.51.44,81-81V83.14L3.49,2.11Z" />
          <path className="cls-2" d="M111.53,112l-27-27V83.13l27-27,.59.36,32,18.17c9.15,5.16,9.15,13.67,0,18.89l-32,18.16Z" />
          <path className="cls-3" d="M112.12,111.68,84.51,84.07,3,165.6c3,3.2,8,3.56,13.59.44l95.55-54.36" />
          <path className="cls-4" d="M112.12,56.46,16.57,2.19C11-1,6-.57,3,2.63L84.51,84.07Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
