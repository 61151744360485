import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Cup from '../Cup';
import Triangle from './Triangle';

import styles from './styles.module.scss';

export default ({ jumperRanking, pageIndex }) => {
  const [animated, setAnimated] = useState(false);
  const { PrevRankingPos, JumperPositions, LastRankingPos } = jumperRanking;

  useEffect(() => {
    // 2 - id of current clide
    if (pageIndex === 2 && !animated) {
      console.log('animete slide 3');
      setAnimated(true);
    }
  }, [pageIndex]);

  return (
    <div className={styles.container}>
      <div className={clsx([styles.block, animated ? styles.firstAnimation : null])}>
        <Cup counter={PrevRankingPos} cupStyle={styles.smallCup} counterStyle={styles.smallCounter} />
      </div>

      <div className={clsx([styles.block, animated ? styles.secondAnimation : null])}>
        <Triangle counter={JumperPositions} />
      </div>

      <div className={clsx([styles.block, animated ? styles.thirdAnimation : null])}>
        <Cup counter={LastRankingPos} cupStyle={styles.bigCup} counterStyle={styles.bigCounter} />
      </div>
    </div>
  );
};
