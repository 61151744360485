import { Colours } from '../../../resources';

export default {
  wrapper: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '20px',
  },
  checkedIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: Colours.white,
    paddingLeft: 33,
  },
  uncheckedIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: Colours.white,
    paddingRight: 35,
  },
  toogleIcon: {
    position: 'absolute',
    transition: 'left ease 225ms',
    pointerEvents: 'none',
  },
};
