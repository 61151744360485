import { createSelector } from 'reselect';

export const selectFactSheetsById = state => state.factSheets.data.byId;

export const selectFactSheetsByIdArray = state => Object.values(state.factSheets.data.byId);

export const selectFactSheetsAllIds = state => state.factSheets.data.allIds;

export const selectFactSheetsInOrder = createSelector([selectFactSheetsById, selectFactSheetsAllIds], (items, ids) =>
  ids.map(item => {
    return items[item];
  }),
);
