import React from 'react';
import { SVG, Colours, CommonStyles } from '../../resources';

const styles = {
  triangleContainer: {
    position: 'relative',
    display: 'flex',
    width: '16vmin',
    height: '16vmin',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  iconTriangle: {
    width: '13vmin',
    height: '13vmin',
    backgroundColor: 'transparent',
    fill: Colours.white,
  },
  labelTriangle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1,
    ...CommonStyles.h1,
    color: Colours.primary,
    textTransform: 'uppercase',
  },
};

function getContainer(direction) {
  switch (direction) {
    case 'up':
      return { transform: 'translateY(-5%)' };
    case 'right':
      return { transform: 'translateX(20%)' };
    case 'down':
      return { transform: 'translateY(5%)' };
    case 'left':
      return { transform: 'translateX(-20%)' };
    default:
      return {};
  }
}

function getDirection(direction) {
  switch (direction) {
    case 'right':
      return { transform: 'rotate(90deg)' };
    case 'down':
      return { transform: 'rotate(180deg)' };
    case 'left':
      return { transform: 'rotate(-90deg)' };
    default:
      return {};
  }
}

function getTransformStyles(direction) {
  switch (direction) {
    case 'up':
      return { transform: 'translate(-50%, 10%)' };
    case 'right':
      return { transform: 'translate(-85%, -50%)' };
    case 'down':
      return { transform: 'translate(-50%, -85%)' };
    case 'left':
      return { transform: 'translate(-25%, -50%)' };
    default:
      return {};
  }
}

export default ({ triangleStyle, labelStyle, direction, label }) => (
  <div style={{ ...styles.triangleContainer, ...getContainer(direction) }}>
    <SVG.Triangle
      style={{
        ...styles.iconTriangle,
        ...triangleStyle,
        ...getDirection(direction),
      }}
    />
    <div
      style={{
        ...styles.labelTriangle,
        ...getTransformStyles(direction),
        ...labelStyle,
      }}>
      {label}
    </div>
  </div>
);
