import React from 'react';
import i18n from 'i18n-js';
import { SVG, Colours } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';
import { BINARY_PERFORMANCE } from '../../../constants/common';

const styles = {
  container: {
    width: isLandscapeMode() ? '60vw' : '90vw',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '5vmin',
  },
  row: {
    display: 'flex',
    width: isLandscapeMode() ? '60vw' : '90vw',
    flexDirection: 'row',
    height: '5vmin',
  },
  itemHeader: {
    fontSize: isLandscapeMode() ? '2vmin' : '3vmin',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  menuIcon: {
    width: '4vmin',
    height: '4vmin',
  },
  list: {
    marginTop: '3vmin',
    width: isLandscapeMode() ? '60vw' : '90vw',
    height: isLandscapeMode() ? '60vmin' : '80vmin',
    overflow: 'auto',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    borderWidth: 0,
    // borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colours.whiteGray,
    borderStyle: 'solid',
  },
  dataItem: {
    fontSize: isLandscapeMode() ? '2vmin' : '3.5vmin',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
  },
  cupIcon: {
    width: '3vmin',
    height: '3vmin',
  },
  noDataTitle: {
    marginTop: '3vmin',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    fontSize: '2vmin',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colours.background,
    fontColor: Colours.darkBlack,
  },
};
export default ({ data, showPerformanceYear }) => {
  // console.log(countryCode);
  let hasData = data && data.rows && data.rows.length > 0;
  // console.log({ data });
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={{ ...styles.itemHeader, flex: 0.6 }}>
          <SVG.MenuBar style={styles.menuIcon} />
        </div>
        <div style={{ ...styles.itemHeader, flex: 1.5 }}>{i18n.t('nick.name')}</div>
        <div style={{ ...styles.itemHeader, flex: 0.5 }} />
        {showPerformanceYear === BINARY_PERFORMANCE.ENABLE && (
          <div style={{ ...styles.itemHeader, flex: 1.5 }}>{i18n.t('totalPerformance.name')}</div>
        )}
        <div style={{ ...styles.itemHeader, flex: 1.5 }}>{i18n.t('score.name')}</div>
      </div>
      {hasData ? (
        <div style={styles.list}>
          {data.rows.map((item, index) => {
            let backgroundColor = index % 2 === 0 ? Colours.background : Colours.white;
            let color = Colours.darkBlack;
            let component = <div style={{ ...styles.dataItem, flex: 0.5 }} />;
            if (data.me === item.nick) {
              color = Colours.white;
              backgroundColor = Colours.primary;
            }
            if (item.trophy === 'gold') {
              component = (
                <div style={{ ...styles.dataItem, flex: 0.5 }}>
                  <SVG.Cup style={{ ...styles.cupIcon, fill: Colours.yellow }} />
                </div>
              );
            } else if (item.trophy === 'silver') {
              component = (
                <div style={{ ...styles.dataItem, flex: 0.5 }}>
                  <SVG.Cup style={{ ...styles.cupIcon, fill: Colours.gray }} />
                </div>
              );
            } else if (item.trophy === 'bronce') {
              component = (
                <div style={{ ...styles.dataItem, flex: 0.5 }}>
                  <SVG.Cup style={{ ...styles.cupIcon, fill: Colours.bronce }} />
                </div>
              );
            }
            return (
              <div style={{ ...styles.dataRow, backgroundColor, color, borderTopWidth: index === 0 ? 1 : 0 }} key={`data-${index}`}>
                <div style={{ ...styles.dataItem, flex: 0.6 }}>{item.position}</div>
                <div style={{ ...styles.dataItem, flex: 1.5 }}>{item.nick}</div>
                {component}
                {showPerformanceYear === BINARY_PERFORMANCE.ENABLE && <div style={{ ...styles.dataItem, flex: 1.5 }}>{item.totalPerformance}</div>}
                <div style={{ ...styles.dataItem, flex: 1.5, fontWeight: 'bold' }}>{item.score}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={styles.noDataTitle}>No Data Yet</div>
      )}
    </div>
  );
};
