import React, { Component } from 'react';
import moment from 'moment';
import styles from './styles';
import UpdateIcon from '@material-ui/icons/Update';

moment.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: '1m',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1m',
    MM: '%dM',
    y: '1y',
    yy: '%dY',
  },
});

export default class CommentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { comments } = this.props;
    return (
      <div style={styles.container}>
        {comments.map((comment, index) => (
          <div key={index} style={styles.comment}>
            <div style={styles.column}>
              <div style={styles.username}>{comment.owner}</div>
              <div style={styles.postedOn}>
                <UpdateIcon style={styles.iconClock} />

                <div style={styles.date}>{moment(new Date(comment.createdAt)).fromNow(true)}</div>
              </div>
            </div>
            <div style={{ ...styles.column, paddingLeft: 0, paddingTop: 0 }}>
              <div style={styles.left}>
                <div style={styles.text}>{comment.body}</div>
              </div>
              {/* <div style={styles.right}>
                {comment.vote_count > 0 && <div style={styles.like}>{comment.vote_count}</div>}
                <IconButton style={styles.actionButton} aria-label="Like" onClick={() => onLikeComment(comment)}>
                  {comment.votes.length ? (
                    <SVG.HeartFilledNew
                      style={{
                        ...styles.icon,
                        color: Colours.primary,
                      }}
                    />
                  ) : (
                    <SVG.HeartNew
                      style={{
                        ...styles.icon,
                        color: Colours.modalGray,
                      }}
                    />
                  )}
                </IconButton>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
