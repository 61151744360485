import { Colours } from './index';
import { isLandscapeMode, isMobile } from '../lib/utils';

let width = 1600;
if (typeof window !== 'undefined') {
  if (window.innerWidth >= 2500) {
    width = 0.625;
  } else if (window.innerWidth >= 1920) {
    width = 0.68;
  } else if (window.innerWidth >= 1600) {
    width = 0.72;
  } else {
    width = 0.8;
  }
}

export default {
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainer: {
    // display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    maxWidth: '1300px',
    alignItems: 'center',
  },
  button: {
    width: '50vw',
    marginTop: '5vmin',
    paddingTop: '1.5vmin',
    paddingBottom: '1.5vmin',
    maxWidth: 600,
    background: Colours.primary,
    fontWeight: 'bold',
    fontSize: isMobile() ? '4vmin' : '2vmin',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: isMobile() ? '7vmin' : '5vmin',
    textTransform: 'uppercase',
  },
  h1: {
    color: Colours.darkBlack,
    fontWeight: 'bold',
    fontSize: isMobile() ? '5.5vmin' : '3.5vmin',
  },
  h2: {
    color: Colours.darkBlack,
    fontSize: isMobile() ? '5vmin' : '3vmin',
  },
  h3: {
    color: Colours.darkBlack,
    fontSize: isMobile() ? '4.5vmin' : '2.5vmin',
    lineHeight: isMobile() ? '8vmin' : '3.5vmin',
  },
  h4: {
    color: Colours.white,
    fontSize: isMobile() ? '4vmin' : '2vmin',
  },
  h5: {
    color: Colours.white,
    fontSize: isLandscapeMode() ? '1.3vmin' : '2.8vmin',
  },
  appStore: {
    fontSize: isLandscapeMode() ? '2.2vmin' : '3.5vmin',
    fontWeight: 'bold',
    color: Colours.white,
  },
  separator: {
    [isLandscapeMode() && 'height']: isMobile() ? '7vmin' : '5vmin',
    [!isLandscapeMode() && 'width']: '92vmin',
  },
  width: width,
};
