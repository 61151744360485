import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64.001 64">
      <rect style={{ fill: 'none' }} d="M0 0H64.001V64H0z" />
      <path d="M45.372,6.522c7.532,0,13.638,6.108,13.638,13.638C59.009,40.977,32,56.866,32,56.866 S4.992,40.977,4.992,20.159c0-7.53,6.104-13.638,13.637-13.638c6.611,0,12.124,4.712,13.372,10.96 C33.246,11.233,38.755,6.522,45.372,6.522z M45.372,3.81c-5.445,0-10.384,2.724-13.372,6.97c-2.99-4.245-7.928-6.96-13.371-6.96 c-9.007,0-16.338,7.33-16.338,16.339c0,22.107,27.183,38.353,28.339,39.034L32,60l1.371-0.807 c1.157-0.681,28.339-16.927,28.339-39.034C61.71,11.15,54.38,3.81,45.372,3.81z" />
    </svg>
  </SvgIcon>
);
