import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 48.8 42.3">
      <path
        className="st0"
        style={{ fill: '#6B6B6B' }}
        d="M48.8,10.6c-0.2-2.2-0.9-3.2-3.1-3.3c-2-0.1-4-0.1-6,0c-1.5,0.1-2.1-0.4-2.1-2c0-3.5-2-5.4-5.5-5.4H16.7
				c-3.5,0-5.5,2-5.5,5.4c0,1.7-0.6,2.1-2.1,2c-2-0.1-4-0.1-6,0C1,7.5,0.2,8.5,0.1,10.6c-0.4,5,2,8.5,6,11c2,1.3,4.2,2.1,6.5,2.4
				c2.9,0.2,4.6,1.8,6.3,3.5c1.6,1.5,2.1,3.7,1.4,5.7c-0.6,2-2.4,2.4-4.3,2.6c-3.1,0.3-5.1,2.3-4.8,4.9c0.2,0.7,0.8,1.3,1.5,1.5H36
				c0.7-0.2,1.3-0.8,1.5-1.5c0.3-2.6-1.7-4.6-4.8-4.9c-1.9-0.2-3.6-0.5-4.3-2.6c-0.7-2-0.1-4.3,1.4-5.7c1.8-1.7,3.5-3.3,6.3-3.5
				c2.3-0.3,4.6-1.2,6.5-2.4C46.8,19.2,49.2,15.7,48.8,10.6z M3.4,11.6c0.1-0.4,0.6-1,1-1c2.2-0.1,4.4,0,6.4,0
				c0.7,3.5,1.4,6.8,2,10.1C8.6,21.5,2.6,15.9,3.4,11.6z M35.9,20.6c0.7-3.3,1.3-6.6,2-10.1c2.1,0,4.2,0,6.4,0.1c0.4,0,0.9,0.6,1,1
				C46.2,15.9,40.2,21.5,35.9,20.6z"
      />
    </svg>
  </SvgIcon>
);
