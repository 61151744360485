import React from 'react';
import i18n from 'i18n-js';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Colours, StyleClass } from '../../../resources';
import styles from './styles';

const renderDescription = (description, style) => {
  if (description && description.length > 0) {
    return (
      <div style={styles.descriptionContainer}>
        <div style={style} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
  return <div style={styles.padding} />;
};

const renderMediaContent = (mediaUrl, mediaType) => {
  if (mediaUrl && mediaUrl.length > 0) {
    if (mediaType === 'video') {
      return (
        <video controls="controls" preload="metadata" style={styles.video}>
          <source src={mediaUrl} type="video/mp4" />
        </video>
      );
    } else {
      return <div style={{ ...styles.img, backgroundImage: `url(${mediaUrl})` }} />;
    }
  }
  return <div />;
};

export const CardWithImageButton = ({ onPress, info }) => {
  let styleContainer = styles.item;
  let buttonContainerStyle = styles.buttonContainer;
  let buttonStyle = styles.button;
  let titleStyle = styles.title;
  let subTitleStyle = styles.subTitle;
  let descriptionStyle = styles.description;
  const { summary } = info;
  const fontColorWhite = info['font-color-white'];
  const buttonType = info['call-to-action'];
  const buttonLabel = info.label_text;
  let mediaType = 'image';
  let mediaUrl = '';
  let mediaRatio = 1.6;
  const purifyLabel = labelText => {
    if (labelText) {
      let parser = new DOMParser(),
        dom = parser.parseFromString('<!doctype html><body>' + labelText, 'text/html');
      return dom.body.textContent;
    } else {
      return '';
    }
  };

  if (info.media_teaser && info.media_teaser.length > 0) {
    mediaType = info.media_teaser[0].type;
    mediaUrl = info.media_teaser[0].url;
    mediaRatio = info.media_teaser[0].width / info.media_teaser[0].height;
  }
  if (fontColorWhite === '1') {
    styleContainer = {
      ...styleContainer,
      backgroundColor: Colours.primary,
    };
    buttonStyle = {
      ...buttonStyle,
      background: Colours.white,
      color: Colours.primary,
      textTransform: 'none',
    };
    titleStyle = { ...titleStyle, color: Colours.white };
    subTitleStyle = { ...subTitleStyle, color: Colours.white };
    descriptionStyle = { ...descriptionStyle, color: Colours.white };
  }
  if (mediaType === 'image' && mediaRatio === 1 && mediaUrl.length > 0) {
    styleContainer = {
      ...styleContainer,
      backgroundImage: `url(${mediaUrl})`,
      height: 350,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'column',
    };
    descriptionStyle = {
      ...descriptionStyle,
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
    };
  }
  if (mediaType === 'image' && mediaUrl && mediaUrl.length > 0 && (!summary || !summary.value.length === 0)) {
    styleContainer = {
      ...styleContainer,
      backgroundImage: `url(${mediaUrl})`,
      height: 350,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
    buttonContainerStyle = {
      ...buttonContainerStyle,
      marginTop: 200,
    };
  }
  return (
    <Paper style={styleContainer}>
      <div style={styles.labelContainer}>
        {info.hide_headlines === '0' && <div style={titleStyle}>{info.title}</div>}
        <div style={subTitleStyle}>{info.subtitle}</div>
      </div>
      {mediaRatio !== 1 && renderMediaContent(mediaUrl, mediaType, summary.value)}
      {renderDescription(summary.value, descriptionStyle)}
      {buttonType === 'More' && (
        <div style={buttonContainerStyle} className={StyleClass.imageTouch}>
          <Button variant="contained" color="primary" style={buttonStyle} onClick={onPress}>
            {!!buttonLabel ? purifyLabel(buttonLabel) : i18n.t('moreInformation.name')}
          </Button>
        </div>
      )}
      {buttonType === 'Shop' && (
        <div style={buttonContainerStyle} className={StyleClass.imageTouch}>
          <Button variant="contained" style={{ ...buttonStyle, backgroundColor: Colours.white, color: Colours.primary }} onClick={onPress}>
            {i18n.t('shop.name')}
          </Button>
        </div>
      )}
      {buttonType === 'Generic' && (
        <div style={buttonContainerStyle} className={StyleClass.imageTouch}>
          <Button variant="contained" color="primary" style={buttonStyle} onClick={onPress}>
            {purifyLabel(buttonLabel)}
          </Button>
        </div>
      )}
    </Paper>
  );
};
