import React from 'react';

import { CONTENT_TYPE } from '../../../../../../constants/common';

import styles from './index.module.scss';

const Preview = ({ type, scr }) => {
  const renderContent = () => {
    switch (type) {
      case CONTENT_TYPE.image:
        return <img className={styles.image} src={scr} alt="" />;

      case CONTENT_TYPE.video:
        return <video className={styles.video} src={scr} controls />;

      default:
        return null;
    }
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

export default Preview;
