import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon viewBox="0 0 160.16 11.84" {...props}>
    <svg viewBox="0 0 128.67 9.87">
      <g id="INT">
        <path style={{ fill: '#fff' }} d="M0,9.64V.22H2V7.89h3.7V9.64Z" />
        <path style={{ fill: '#fff' }} d="M6.93,9.64V.22H9V9.64Z" />
        <path style={{ fill: '#fff' }} d="M10.79,9.64V.22h5.7V2H12.78V4.14H16V5.78H12.78V9.64Z" />
        <path style={{ fill: '#fff' }} d="M17.71,9.64V.22h6V2h-4V4h3.64V5.6H19.73V7.91H23.8V9.64Z" />
        <path style={{ fill: '#fff' }} d="M28.87,9.64V.22h2V9.64Z" />
        <path
          style={{ fill: '#fff' }}
          d="M32.29,6.89h2a2.19,2.19,0,0,0,.26.82,1.15,1.15,0,0,0,1,.55,1.19,1.19,0,0,0,.94-.37,1.19,1.19,0,0,0-.18-1.73,2,2,0,0,0-.56-.32l-.69-.26a6.09,6.09,0,0,1-1.68-.81,2.6,2.6,0,0,1-1-2.13A2.49,2.49,0,0,1,33.13.79,3,3,0,0,1,35.34,0,3.06,3.06,0,0,1,38,1.21a3.29,3.29,0,0,1,.55,1.5h-2a1.24,1.24,0,0,0-.35-.8,1.32,1.32,0,0,0-.89-.29,1.11,1.11,0,0,0-.74.23.87.87,0,0,0-.31.72,1,1,0,0,0,.47.86A5.34,5.34,0,0,0,36.11,4a6.13,6.13,0,0,1,1.85.9A2.5,2.5,0,0,1,38.91,7,2.67,2.67,0,0,1,38,9a3.53,3.53,0,0,1-2.45.83,3,3,0,0,1-2.73-1.38A3.73,3.73,0,0,1,32.29,6.89Z"
        />
        <path style={{ fill: '#fff' }} d="M43.74,9.64V.22h5.7V2H45.73V4.14h3.21V5.78H45.73V9.64Z" />
        <path
          style={{ fill: '#fff' }}
          d="M54.28,0A3.46,3.46,0,0,1,57,1.22a5.32,5.32,0,0,1,1.24,3.71,5.75,5.75,0,0,1-.93,3.35,3.62,3.62,0,0,1-1.4,1.21,3.69,3.69,0,0,1-1.67.38,3.59,3.59,0,0,1-3.12-1.62A5.66,5.66,0,0,1,50.2,5a5.33,5.33,0,0,1,1.18-3.67A3.66,3.66,0,0,1,54.28,0Zm-.09,1.72a1.56,1.56,0,0,0-1.42.88,4.48,4.48,0,0,0-.55,2.3,4.68,4.68,0,0,0,.54,2.36A1.9,1.9,0,0,0,53.5,8a1.59,1.59,0,0,0,.74.18,1.5,1.5,0,0,0,1-.4A2.57,2.57,0,0,0,56,6.47a5.23,5.23,0,0,0,.22-1.55,4.54,4.54,0,0,0-.54-2.31A1.61,1.61,0,0,0,54.19,1.73Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M59.7,9.64V.22h3.62a3.47,3.47,0,0,1,2.2.62,2.45,2.45,0,0,1,.89,2,2.67,2.67,0,0,1-.63,1.8,2.27,2.27,0,0,1-.72.54,1.54,1.54,0,0,1,.89.72,2.47,2.47,0,0,1,.3,1q0,.23.12,1.41a4.81,4.81,0,0,0,.15,1l.17.34H64.61a1.93,1.93,0,0,1-.14-.54c0-.11,0-.52-.1-1.21a4.06,4.06,0,0,0-.24-1.31A1,1,0,0,0,63.49,6a2.39,2.39,0,0,0-.61-.06H61.72v3.7Zm2-5.22h1.11A1.64,1.64,0,0,0,64.09,4a1.36,1.36,0,0,0,.3-.94A1.19,1.19,0,0,0,63.89,2a2,2,0,0,0-1-.2H61.72Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M71.31,6.89h2a2.19,2.19,0,0,0,.26.82,1.14,1.14,0,0,0,1,.55,1.17,1.17,0,0,0,.93-.37,1.11,1.11,0,0,0,.29-.8,1.15,1.15,0,0,0-.46-.93,2.11,2.11,0,0,0-.56-.32l-.7-.26a6,6,0,0,1-1.67-.81,2.57,2.57,0,0,1-1-2.13A2.49,2.49,0,0,1,72.15.79,3,3,0,0,1,74.36,0,3.08,3.08,0,0,1,77,1.21a3.29,3.29,0,0,1,.55,1.5h-2a1.24,1.24,0,0,0-.34-.8,1.32,1.32,0,0,0-.89-.29,1.14,1.14,0,0,0-.75.23.9.9,0,0,0-.31.72,1,1,0,0,0,.47.86A5.46,5.46,0,0,0,75.13,4a6.25,6.25,0,0,1,1.85.9,2.5,2.5,0,0,1,1,2.05A2.7,2.7,0,0,1,77.05,9a3.55,3.55,0,0,1-2.45.83,3,3,0,0,1-2.73-1.38A3.73,3.73,0,0,1,71.31,6.89Z"
        />
        <path style={{ fill: '#fff' }} d="M79.29,9.64V.22h2V3.8h3V.22h2V9.64h-2V5.53h-3V9.64Z" />
        <path style={{ fill: '#fff' }} d="M90.34.22h2.07l3,9.42H93.28l-.58-2.1H90l-.59,2.1H87.35ZM90.45,6h1.82l-.91-3.25Z" />
        <path
          style={{ fill: '#fff' }}
          d="M96.4,9.64V.22H100a3.47,3.47,0,0,1,2.2.62,2.45,2.45,0,0,1,.89,2,2.67,2.67,0,0,1-.63,1.8,2.27,2.27,0,0,1-.72.54,1.54,1.54,0,0,1,.89.72,2.47,2.47,0,0,1,.3,1q0,.23.12,1.41a4.81,4.81,0,0,0,.15,1l.17.34h-2.08a2.35,2.35,0,0,1-.14-.54c0-.11,0-.52-.09-1.21a4.28,4.28,0,0,0-.25-1.31,1,1,0,0,0-.64-.58,2.39,2.39,0,0,0-.61-.06H98.42v3.7Zm2-5.22h1.11A1.64,1.64,0,0,0,100.79,4a1.36,1.36,0,0,0,.3-.94,1.19,1.19,0,0,0-.5-1.06,2,2,0,0,0-1-.2H98.42Z"
        />
        <path style={{ fill: '#fff' }} d="M104.89,9.64V.22h2V9.64Z" />
        <path style={{ fill: '#fff' }} d="M108.75,9.64V.22h2l2.95,6.06V.22h1.94V9.64h-2l-3-6.05V9.64Z" />
        <path
          style={{ fill: '#fff' }}
          d="M124.76,9.64h-1.48V8.58a4.55,4.55,0,0,1-.68.73,2.55,2.55,0,0,1-1.65.56,3.26,3.26,0,0,1-2.8-1.43,5.71,5.71,0,0,1-1-3.46,5.68,5.68,0,0,1,1-3.48,3.54,3.54,0,0,1,3-1.5,3.3,3.3,0,0,1,2.6,1.17,3.61,3.61,0,0,1,.83,1.7c0,.1,0,.26.06.46h-2a2.53,2.53,0,0,0-.33-.92,1.36,1.36,0,0,0-1.22-.68,1.59,1.59,0,0,0-1.46.9A4.59,4.59,0,0,0,119.17,5a4.46,4.46,0,0,0,.57,2.43,1.63,1.63,0,0,0,1.45.79,1.54,1.54,0,0,0,1.34-.72,2.81,2.81,0,0,0,.4-1.37h-1.69V4.56h3.52Z"
        />
        <path style={{ fill: '#fff' }} d="M128.67,7.61v2H126.6v-2h2.07Z" />
      </g>
    </svg>
  </SvgIcon>
);
