import { Colours } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';

const stylesWeb = {
  alalysisToogleWrapper: {
    marginBottom: '30px',
    width: isLandscapeMode() ? '60vw' : '80vw',
  },
  alalysisToogleLabel: {
    color: Colours.darkBlack,
    fontSize: '3vmin',
    fontWeight: 'bold',
  },
  alalysisToogleDescription: {
    marginTop: '10px',
    marginBottom: '10px',
    color: Colours.darkBlack,
    fontSize: '2.5vmin',
    lineHeight: '3.5vmin',
  },
  alalysisToogleDescriptionBtn: {
    color: '#2b8baf',
    fontSize: '2.5vmin',
    lineHeight: '3.5vmin',
    cursor: 'pointer',
  },
  alalysisToogleContainer: {
    marginTop: '15px',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alalysisToogleImage: {
    height: '70px',
  },
};
const stylesMobile = {
  ...stylesWeb,
  alalysisToogleLabel: {
    color: Colours.darkBlack,
    fontSize: '5vmin',
    fontWeight: 'bold',
  },
  alalysisToogleDescription: {
    marginTop: '10px',
    marginBottom: '10px',
    color: Colours.darkBlack,
    fontSize: '4vmin',
    lineHeight: '8vmin',
  },
  alalysisToogleDescriptionBtn: {
    color: '#2b8baf',
    fontSize: '4vmin',
    lineHeight: '8vmin',
  },

  alalysisToogleWrapperMobile: {
    marginBottom: '15px',
    width: '80vw',
  },
  alalysisToogleLabelMobile: {
    fontSize: '5vmin',
    fontWeight: 'bold',
  },
  alalysisToogleContainerMobile: {
    marginTop: '15px',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alalysisToogleImageMobile: {
    height: '50px',
  },
};
export default isLandscapeMode() ? stylesWeb : stylesMobile;
