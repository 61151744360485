import React from 'react';
import { Link } from 'react-router-dom';

export default props => {
  if (props.to.indexOf('http') === 0) {
    return (
      <Link {...props} to={{}} onClick={() => (window.location.href = props.to)}>
        {props.children}
      </Link>
    );
  }
  return <Link {...props}>{props.children}</Link>;
};
