import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

import { SVG } from '../../../resources';

import styles from './index.module.scss';

const CloseButton = ({ containerStyle, onPress }) => {
  return !isMobile ? (
    <div className={clsx([styles.container, containerStyle])} onClick={onPress}>
      <SVG.Close className={styles.icon} />
    </div>
  ) : null;
};

export default CloseButton;
