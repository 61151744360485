import React from 'react';
import i18n from 'i18n-js';

import { Gauge } from '../../../../components';
import Cup from './Cup';

import styles from './styles.module.scss';

// `url(${PNG.bgLionLeft}), url(${PNG.bgStartPageBlue}),
const previousPoints = charts => charts.month_my_total_points - charts.month_my_last_points;

const currentPoints = charts => {
  if (charts.month_my_total_points === charts.month_my_last_points) return charts.month_my_total_points;
  return charts.month_my_last_points;
};

const ProgressCircle = ({ charts }) => (
  <div className={styles.container}>
    <div className={styles.gauge}>
      <Gauge
        maximumTextValue={charts.month_max_possible_points}
        maximumValue={charts.month_max_possible_points}
        values={[previousPoints(charts), currentPoints(charts)]}
        // maximumTextValue={400}
        // maximumValue={400}
        // values={[250, 100]}
        progressFontSize={26}
      />
    </div>
    <div className={styles.gaugeContent}>
      <Cup counter={charts.month_my_rank} />

      <div className={styles.scoreTitle}>{i18n.t('totalPointsMonthly.name')}</div>
      <div className={styles.scoreValue}>
        <b>{charts.month_my_total_points}</b> {i18n.t('outOf')} {charts.month_max_possible_points}
      </div>
    </div>
  </div>
);

export default ProgressCircle;
