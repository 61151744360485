import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M46.4,26.8,7.6,2.6,2.3,11.1A2,2,0,0,0,2,12.6a1.58,1.58,0,0,0,.9,1.2l21,13.1-4,6A19,19,0,1,0,55,43,18.6,18.6,0,0,0,
        46.4,26.8ZM36,58.9A16.06,16.06,0,0,1,22.4,34.4L24,31.9h0l4-6L5.4,11.8,8.6,6.7l22.5,14V24L41,30.4,42.5,28l.9.5,1.2.8a16,
        16,0,0,1,7.5,13.6A16.2,16.2,0,0,1,36,58.9ZM36,32A11,11,0,1,0,47,43,11,11,0,0,0,36,32Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,36,52Zm26-2.55a5.45,
        5.45,0,0,1-5.45,5.47,5.37,5.37,0,0,1-2.76-.74,21.73,21.73,0,0,0,1.42-2.65,2.46,2.46,0,1,0,2.65-4.15A2.53,2.53,0,0,0,56.67,47,18.86,18.86,
        0,0,0,57,44,5.47,5.47,0,0,1,62,49.45Z"
      />
    </svg>
  </SvgIcon>
);
