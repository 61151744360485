import React, { useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// Colors
// --magenta-color-e20074: #E20074;
// --gold-color-c9a256: #C9A256;
// --silver-color-a4a4a4: #A4A4A4;
// --bronze-color-ff9a1e: #FF9A1E;

const handleMouseEnter = setState => {
  setState(true);
};
const handleMouseLeave = setState => {
  setState(false);
};

export function trophyMagenta(props) {
  const [hoverState, setHoverState] = useState(false);
  return (
    <SvgIcon {...props} onMouseEnter={() => handleMouseEnter(setHoverState)} onMouseLeave={() => handleMouseLeave(setHoverState)}>
      <svg viewBox="0 0 69.186 69.185">
        <path className="a" d="M0,0H69.186V69.185H0Z" style={{ fill: 'none' }} />
        <path
          className="b"
          style={{ fill: hoverState ? '#ffffff' : '#E20074' }}
          d="M47.965,38.815c18.895-5.853,18.9-27.409,18.9-27.409H52.808v-5.4H16.053v5.4H2S2,32.962,20.9,38.815c3.212,4.871,7.484,8.164,11.113,9.238v.724c-.519,5.34-2.565,9.122-10.558,9.122v4.313H47.4V57.9c-7.94,0-10-3.725-10.547-9.013v-.832C40.479,46.982,44.751,43.688,47.965,38.815ZM63.342,14.649c-.728,5.145-3.376,15.006-12.967,19.72a29.208,29.208,0,0,0,2.432-11.687V14.649H63.342Zm-57.822,0H16.053v8.033A29.217,29.217,0,0,0,18.485,34.37C8.894,29.653,6.246,19.789,5.52,14.649Z"
          transform="translate(0.162 0.486)"
        />
      </svg>
    </SvgIcon>
  );
}

export function trophyGold(props) {
  const [hoverState, setHoverState] = useState(false);
  return (
    <SvgIcon {...props} onMouseEnter={() => handleMouseEnter(setHoverState)} onMouseLeave={() => handleMouseLeave(setHoverState)}>
      <svg viewBox="0 0 69.186 69.185">
        <path className="a" d="M0,0H69.186V69.185H0Z" style={{ fill: 'none' }} />
        <path
          className="b"
          style={{ fill: hoverState ? '#ffffff' : '#C9A256' }}
          d="M47.965,38.815c18.895-5.853,18.9-27.409,18.9-27.409H52.808v-5.4H16.053v5.4H2S2,32.962,20.9,38.815c3.212,4.871,7.484,8.164,11.113,9.238v.724c-.519,5.34-2.565,9.122-10.558,9.122v4.313H47.4V57.9c-7.94,0-10-3.725-10.547-9.013v-.832C40.479,46.982,44.751,43.688,47.965,38.815ZM63.342,14.649c-.728,5.145-3.376,15.006-12.967,19.72a29.208,29.208,0,0,0,2.432-11.687V14.649H63.342Zm-57.822,0H16.053v8.033A29.217,29.217,0,0,0,18.485,34.37C8.894,29.653,6.246,19.789,5.52,14.649Z"
          transform="translate(0.162 0.486)"
        />
      </svg>
    </SvgIcon>
  );
}

export function trophySilver(props) {
  const [hoverState, setHoverState] = useState(false);
  return (
    <SvgIcon {...props} onMouseEnter={() => handleMouseEnter(setHoverState)} onMouseLeave={() => handleMouseLeave(setHoverState)}>
      <svg viewBox="0 0 69.186 69.185">
        <path className="a" d="M0,0H69.186V69.185H0Z" style={{ fill: 'none' }} />
        <path
          className="b"
          style={{ fill: hoverState ? '#ffffff' : '#A4A4A4' }}
          d="M47.965,38.815c18.895-5.853,18.9-27.409,18.9-27.409H52.808v-5.4H16.053v5.4H2S2,32.962,20.9,38.815c3.212,4.871,7.484,8.164,11.113,9.238v.724c-.519,5.34-2.565,9.122-10.558,9.122v4.313H47.4V57.9c-7.94,0-10-3.725-10.547-9.013v-.832C40.479,46.982,44.751,43.688,47.965,38.815ZM63.342,14.649c-.728,5.145-3.376,15.006-12.967,19.72a29.208,29.208,0,0,0,2.432-11.687V14.649H63.342Zm-57.822,0H16.053v8.033A29.217,29.217,0,0,0,18.485,34.37C8.894,29.653,6.246,19.789,5.52,14.649Z"
          transform="translate(0.162 0.486)"
        />
      </svg>
    </SvgIcon>
  );
}

export function trophyBronze(props) {
  const [hoverState, setHoverState] = useState(false);
  return (
    <SvgIcon {...props} onMouseEnter={() => handleMouseEnter(setHoverState)} onMouseLeave={() => handleMouseLeave(setHoverState)}>
      <svg viewBox="0 0 69.186 69.185">
        <path className="a" d="M0,0H69.186V69.185H0Z" style={{ fill: 'none' }} />
        <path
          className="b"
          style={{ fill: hoverState ? '#ffffff' : '#FF9A1E' }}
          d="M47.965,38.815c18.895-5.853,18.9-27.409,18.9-27.409H52.808v-5.4H16.053v5.4H2S2,32.962,20.9,38.815c3.212,4.871,7.484,8.164,11.113,9.238v.724c-.519,5.34-2.565,9.122-10.558,9.122v4.313H47.4V57.9c-7.94,0-10-3.725-10.547-9.013v-.832C40.479,46.982,44.751,43.688,47.965,38.815ZM63.342,14.649c-.728,5.145-3.376,15.006-12.967,19.72a29.208,29.208,0,0,0,2.432-11.687V14.649H63.342Zm-57.822,0H16.053v8.033A29.217,29.217,0,0,0,18.485,34.37C8.894,29.653,6.246,19.789,5.52,14.649Z"
          transform="translate(0.162 0.486)"
        />
      </svg>
    </SvgIcon>
  );
}

const trophy = {
  trophyMagenta,
  trophyGold,
  trophySilver,
  trophyBronze,
};
export default trophy;
