import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <title>icon_back</title>
      <g id="Layer_2" data-name="Layer 2">
        <rect style={{ fill: 'none' }} width="64" height="64" />
        <path d="M43,15H18V5L4,19,18,33V23H43a9,9,0,0,1,0,18H35a4,4,0,0,0,0,8h8a17,17,0,0,0,0-34Z" />
      </g>
    </svg>
  </SvgIcon>
);
