import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getFactSheets: ['auth'],
  getFactSheetsSuccess: ['factSheets'],
  getElearningItems: ['auth'],
  getElearningItemsSuccess: ['eLearnings'],
  requestFailed: [],
  clear: [],
});

export const ELearningTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  factSheets: [],
  eLearnings: [],
  fetchContents: false,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state, fetchContents: true });
const getFactSheetsSuccess = (state, { factSheets }) => ({ ...state, fetchContents: false, factSheets });
const getElearningItemsSuccess = (state, { eLearnings }) => ({ ...state, fetchContents: false, eLearnings });
const failure = (state, { error }) => ({ ...state, fetchContents: false, error });
const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const elearningReducer = createReducer(defaultState, {
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_FACT_SHEETS]: request,
  [Types.GET_ELEARNING_ITEMS]: request,
  [Types.GET_FACT_SHEETS_SUCCESS]: getFactSheetsSuccess,
  [Types.GET_ELEARNING_ITEMS_SUCCESS]: getElearningItemsSuccess,
  [Types.CLEAR]: clear,
});
