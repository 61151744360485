import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M37.018,7c11.028,0,20,8.972,20,20s-8.972,20-20,20c-4.215,0-8.233-1.319-11.621-3.815l-0.057-0.042 l-0.059-0.039c-1.62-1.08-3.07-2.511-4.541-4.471c-2.401-3.291-3.723-7.422-3.723-11.633C17.018,15.972,25.99,7,37.018,7 M37.018,4 c-12.701,0-23,10.3-23,23c0,5,1.6,9.7,4.299,13.4c1.5,2,3.201,3.8,5.301,5.199c3.801,2.801,8.4,4.4,13.4,4.4 c12.699,0,23-10.3,23-23S49.717,4,37.018,4L37.018,4z M17.367,42.406L4.903,54.869c-1.172,1.171-1.172,3.07,0,4.242 c0.586,0.586,1.353,0.879,2.122,0.879c0.768,0,1.536-0.293,2.121-0.879L21.638,46.62C20.142,45.498,18.732,44.107,17.367,42.406z" />
    </svg>
  </SvgIcon>
);
