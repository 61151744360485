import React, { useState, useEffect } from 'react';
// import SvgIcon from '@material-ui/core/SvgIcon';

function BlackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 798.79 357.05"
      xmlSpace="preserve"
      style={{
        minWidth: '75px',
        height: '100%',
        // marginTop: '-10px'
      }}>
      <g>
        <path
          d="M80.3,240.2c0,25.8,11.6,41.5,31.1,41.5c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20
		c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8
		C91.9,198.7,80.3,214.4,80.3,240.2L80.3,240.2L80.3,240.2z M157.4,280.5h4.8v-80.6h-4.8V280.5L157.4,280.5z M229.9,280.5h5.8
		L212.5,246c11.8-0.6,22.5-7.5,22.5-23c0-16.2-11.6-23-24-23h-25.2v80.6h4.8V246h16.2L229.9,280.5L229.9,280.5z M190.7,204.5h19.9
		c9.8,0,19.4,4.9,19.4,18.5s-9.7,18.5-19.4,18.5h-19.9V204.5L190.7,204.5z M249.1,240.2c0,25.8,11.6,41.5,31.1,41.5
		c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7
		c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8C260.6,198.7,249.1,214.4,249.1,240.2L249.1,240.2L249.1,240.2z
		 M326.1,280.5h40v-4.6H331v-76h-4.8V280.5L326.1,280.5z M423.8,204.5v-4.6h-42.3v80.6h42.3v-4.6h-37.5v-34.5h36.3v-4.6h-36.3v-32.3
		H423.8L423.8,204.5z"
        />
        <path
          d="M168.6,79.3l-13,55.4h-0.7l-13-55.4h-18.3l-12.9,55.4h-0.7L96.7,79.3H79.9l21.3,80.6h17.6l13.4-54.6h0.7
		l13.4,54.6H164l21.3-80.6H168.6L168.6,79.3z M195,159.8h16.3V79.3H195V159.8L195,159.8z M273.9,79.3v50.7h-0.7l-29-50.7h-16.5v80.6
		h16.3v-50.4h0.7l28.8,50.4H290V79.3H273.9L273.9,79.3z M352.6,79.3v50.7h-0.7l-29-50.7h-16.5v80.6h16.3v-50.4h0.7l28.8,50.4h16.5
		V79.3H352.6L352.6,79.3z M432.6,94.3V79.3h-47.4v80.6h47.4v-15.1h-31.1v-18.4h29.9v-15.1h-29.9v-17H432.6L432.6,94.3z M486.4,159.8
		h18.1l-17-31.1c9.8-3.3,16.2-11.6,16.2-23.9c0-16.3-11.1-25.6-26.8-25.6h-29.8v80.6h16.3v-29.4h7.8L486.4,159.8L486.4,159.8z
		 M463.4,94.3H476c6.4,0,10.7,3.7,10.7,10.5c0,6.8-4.3,10.5-10.7,10.5h-12.7V94.3L463.4,94.3z M540.8,161.7c15.2,0,27.1-9,27.1-25.2
		c0-11.7-7.5-20.6-19.4-23.8l-9.3-2.5c-4.5-1.2-8.2-3.3-8.2-8.7s4.2-8.8,9.8-8.8c6.3,0,9.7,3,10.7,9h15.9
		c-1.6-14.8-10.4-24.2-26.7-24.2c-15,0-26.4,9.8-26.4,25.1c0,9.9,5.1,19.2,18,22.7l9.3,2.7c6.3,1.7,9.7,4.2,9.7,9.5
		c0,5.2-3.5,9.1-10.5,9.1c-5.9,0-10.4-2.7-11.8-9.4h-16.2C514.3,153,524.2,161.7,540.8,161.7L540.8,161.7L540.8,161.7z M578.3,79.3
		v18.6h8.9c-0.4,7.1-2,10.7-8.8,11.1v8.6c13.4-0.6,18.4-8.7,18.4-23V79.3H578.3L578.3,79.3z"
        />
      </g>
      <g>
        <path
          d="M476.9,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H476c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H476.9z"
        />
        <path
          d="M540.2,251.5v-23.4c0-19.9,11.7-30.2,26.6-30.2c14.9,0,26.6,10.4,26.6,30.2v23.4c0,19.9-11.7,30.2-26.6,30.2
		C551.9,281.7,540.2,271.3,540.2,251.5z M588.5,251.3v-23.2c0-16.9-9-25.7-21.7-25.7c-12.7,0-21.7,8.8-21.7,25.7v23.2
		c0,16.9,9,25.7,21.7,25.7C579.5,277.1,588.5,268.3,588.5,251.3z"
        />
        <path
          d="M609.8,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H609c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H609.8z"
        />
        <path
          d="M711.9,255.1h10v4.6h-10v20.7H707v-20.7h-40.8v-6.6l41.2-54.1h4.5V255.1z M671,255.1h36v-46.7h-0.7L671,254.5
		V255.1z"
        />
      </g>
    </svg>
  );
}

function WhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 798.79 357.05"
      xmlSpace="preserve"
      style={{
        minWidth: '75px',
        height: '100%',
        // marginTop: '-10px'
      }}>
      <g>
        <path
          fill="#FFF"
          d="M80.3,240.2c0,25.8,11.6,41.5,31.1,41.5c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20
		c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8
		C91.9,198.7,80.3,214.4,80.3,240.2L80.3,240.2L80.3,240.2z M157.4,280.5h4.8v-80.6h-4.8V280.5L157.4,280.5z M229.9,280.5h5.8
		L212.5,246c11.8-0.6,22.5-7.5,22.5-23c0-16.2-11.6-23-24-23h-25.2v80.6h4.8V246h16.2L229.9,280.5L229.9,280.5z M190.7,204.5h19.9
		c9.8,0,19.4,4.9,19.4,18.5s-9.7,18.5-19.4,18.5h-19.9V204.5L190.7,204.5z M249.1,240.2c0,25.8,11.6,41.5,31.1,41.5
		c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7
		c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8C260.6,198.7,249.1,214.4,249.1,240.2L249.1,240.2L249.1,240.2z
		 M326.1,280.5h40v-4.6H331v-76h-4.8V280.5L326.1,280.5z M423.8,204.5v-4.6h-42.3v80.6h42.3v-4.6h-37.5v-34.5h36.3v-4.6h-36.3v-32.3
		H423.8L423.8,204.5z"
        />
        <path
          fill="#FFF"
          d="M168.6,79.3l-13,55.4h-0.7l-13-55.4h-18.3l-12.9,55.4h-0.7L96.7,79.3H79.9l21.3,80.6h17.6l13.4-54.6h0.7
		l13.4,54.6H164l21.3-80.6H168.6L168.6,79.3z M195,159.8h16.3V79.3H195V159.8L195,159.8z M273.9,79.3v50.7h-0.7l-29-50.7h-16.5v80.6
		h16.3v-50.4h0.7l28.8,50.4H290V79.3H273.9L273.9,79.3z M352.6,79.3v50.7h-0.7l-29-50.7h-16.5v80.6h16.3v-50.4h0.7l28.8,50.4h16.5
		V79.3H352.6L352.6,79.3z M432.6,94.3V79.3h-47.4v80.6h47.4v-15.1h-31.1v-18.4h29.9v-15.1h-29.9v-17H432.6L432.6,94.3z M486.4,159.8
		h18.1l-17-31.1c9.8-3.3,16.2-11.6,16.2-23.9c0-16.3-11.1-25.6-26.8-25.6h-29.8v80.6h16.3v-29.4h7.8L486.4,159.8L486.4,159.8z
		 M463.4,94.3H476c6.4,0,10.7,3.7,10.7,10.5c0,6.8-4.3,10.5-10.7,10.5h-12.7V94.3L463.4,94.3z M540.8,161.7c15.2,0,27.1-9,27.1-25.2
		c0-11.7-7.5-20.6-19.4-23.8l-9.3-2.5c-4.5-1.2-8.2-3.3-8.2-8.7s4.2-8.8,9.8-8.8c6.3,0,9.7,3,10.7,9h15.9
		c-1.6-14.8-10.4-24.2-26.7-24.2c-15,0-26.4,9.8-26.4,25.1c0,9.9,5.1,19.2,18,22.7l9.3,2.7c6.3,1.7,9.7,4.2,9.7,9.5
		c0,5.2-3.5,9.1-10.5,9.1c-5.9,0-10.4-2.7-11.8-9.4h-16.2C514.3,153,524.2,161.7,540.8,161.7L540.8,161.7L540.8,161.7z M578.3,79.3
		v18.6h8.9c-0.4,7.1-2,10.7-8.8,11.1v8.6c13.4-0.6,18.4-8.7,18.4-23V79.3H578.3L578.3,79.3z"
        />
      </g>
      <g>
        <path
          fill="#FFF"
          d="M476.9,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H476c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H476.9z"
        />
        <path
          fill="#FFF"
          d="M540.2,251.5v-23.4c0-19.9,11.7-30.2,26.6-30.2c14.9,0,26.6,10.4,26.6,30.2v23.4c0,19.9-11.7,30.2-26.6,30.2
		C551.9,281.7,540.2,271.3,540.2,251.5z M588.5,251.3v-23.2c0-16.9-9-25.7-21.7-25.7c-12.7,0-21.7,8.8-21.7,25.7v23.2
		c0,16.9,9,25.7,21.7,25.7C579.5,277.1,588.5,268.3,588.5,251.3z"
        />
        <path
          fill="#FFF"
          d="M609.8,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H609c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H609.8z"
        />
        <path
          fill="#FFF"
          d="M711.9,255.1h10v4.6h-10v20.7H707v-20.7h-40.8v-6.6l41.2-54.1h4.5V255.1z M671,255.1h36v-46.7h-0.7L671,254.5
		V255.1z"
        />
      </g>
    </svg>
  );
}

function MagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 530 236.1"
      xmlSpace="preserve"
      style={{
        minWidth: '75px',
        height: '100%',
        // marginTop: '-10px'
      }}>
      <g fill="#E20074">
        <path d="M123.8 52.1L112.5 95.2 112 95.2 100.3 52.1 94.2 52.1 82.5 95.2 82 95.2 70.7 52.1 65.2 52.1 79.3 104.4 85.1 104.4 97 61.7 97.4 61.7 109.3 104.4 115.2 104.4 129.3 52.1z" />
        <path d="M137.6 52.1H142.9V104.4H137.6z" />
        <path d="M192.7 52.1L192.7 104.4 187.2 104.4 161.9 62.4 161.4 62.4 161.4 104.4 156.1 104.4 156.1 52.1 161.6 52.1 186.9 94.2 187.4 94.2 187.4 52.1z" />
        <path d="M242.4 52.1L242.4 104.4 236.9 104.4 211.6 62.4 211.2 62.4 211.2 104.4 205.8 104.4 205.8 52.1 211.3 52.1 236.6 94.2 237.1 94.2 237.1 52.1z" />
        <path d="M260.9 57.1L260.9 75.1 283.3 75.1 283.3 80.1 260.9 80.1 260.9 99.4 284.1 99.4 284.1 104.4 255.6 104.4 255.6 52.1 284.1 52.1 284.1 57.1z" />
        <path d="M300.1 78h11.6c5.6 0 10.9-3 10.9-10.4s-5.2-10.4-10.9-10.4h-11.6V78zm9 5h-9v21.4h-5.3V52.1H312c8.7 0 16.1 4.9 16.1 15.4 0 9.3-5.8 14.3-13.2 15.3l13.6 21.6h-6.2L309.1 83zM335.1 90.3h5.4c.9 7.1 5.5 9.9 11.4 9.9 6.4 0 10.4-3.6 10.4-9.2 0-5.2-3.1-7.8-8.5-9.6l-6.3-2c-7.4-2.3-11.4-7.3-11.4-13.8 0-9.1 7.1-14.4 15.4-14.4 9.5 0 15 5.7 16.1 14.3h-5.4c-.9-6-4.5-9.2-10.7-9.2-5.1 0-9.9 3-9.9 9 0 4.4 2.7 7.2 8 9l6.3 2c6.9 2.1 11.7 6.7 11.7 14.4 0 8.9-6.5 14.6-15.9 14.6-9.4.1-15.6-5.3-16.6-15M384.1 52.1v6.7c0 9.8-2.4 12.9-7.6 13.4v-3.7c2.7-.4 3.5-2.4 3.7-8.5h-3.9v-7.8h7.8zM88.3 129.5c9.8 0 17.1 6.3 18.8 16.7h-5.7c-1.6-7.4-6.6-11.4-13.1-11.4-9.7 0-15.2 8-15.2 21.9 0 13.9 5.5 21.9 15.2 21.9 6.5 0 11.5-4.1 13.1-11.4h5.7c-1.7 10.3-9 16.7-18.8 16.7-13.2 0-20.8-10.3-20.8-27.1s7.6-27.3 20.8-27.3" />
        <path d="M116.6 130.4H121.89999999999999V182.7H116.6z" />
        <path d="M140.4 156.3H152c5.6 0 10.9-3 10.9-10.4s-5.2-10.4-10.9-10.4h-11.6v20.8zm9 5.1h-9v21.4h-5.3v-52.3h17.2c8.7 0 16.1 4.9 16.1 15.4 0 9.3-5.8 14.3-13.2 15.3l13.6 21.6h-6.2l-13.2-21.4zM196.7 129.5c9.8 0 17.1 6.3 18.8 16.7h-5.7c-1.6-7.4-6.6-11.4-13.1-11.4-9.7 0-15.2 8-15.2 21.9 0 13.9 5.5 21.9 15.2 21.9 6.5 0 11.5-4.1 13.1-11.4h5.7c-1.7 10.3-9 16.7-18.8 16.7-13.2 0-20.8-10.3-20.8-27.1s7.6-27.3 20.8-27.3" />
        <path d="M225 130.4L225 182.8 252 182.8 252 177.7 230.3 177.7 230.3 130.4z" />
        <path d="M260.3 130.4L260.3 182.8 288.8 182.8 288.8 177.7 265.6 177.7 265.6 158.5 288 158.5 288 153.5 265.6 153.5 265.6 135.4 288.8 135.4 288.8 130.4z" />
        <g>
          <path d="M312.7 176.4l19-18.2c3.2-3.1 6.4-6.8 6.4-13 0-6.3-4.2-10.7-10.2-10.7-6.3 0-9.9 3.4-10.7 9.3H312c.8-8 6-14.3 16-14.3 9 0 15.5 6.4 15.5 15.7 0 7.4-3.4 12.1-8.2 16.8l-16.4 15.4v.4h24.8v4.9h-30.9v-6.3zM381.9 164.1v-15c0-9.7-5.1-14.6-12.2-14.6-7 0-12.2 4.9-12.2 14.6v15c0 9.7 5.2 14.6 12.2 14.6 7.1 0 12.2-4.9 12.2-14.6m-29.6.1V149c0-12.8 7.7-19.5 17.4-19.5 9.8 0 17.5 6.7 17.5 19.5v15.2c0 12.8-7.7 19.5-17.5 19.5-9.7 0-17.4-6.7-17.4-19.5M395.9 176.4l19-18.2c3.2-3.1 6.4-6.8 6.4-13 0-6.3-4.2-10.7-10.2-10.7-6.3 0-9.9 3.4-10.7 9.3h-5.3c.8-8 6-14.3 16-14.3 9 0 15.5 6.4 15.5 15.7 0 7.4-3.4 12.1-8.2 16.8L402 177.4v.4h24.8v4.9h-30.9v-6.3zM434 176.4l19-18.2c3.2-3.1 6.4-6.8 6.4-13 0-6.3-4.2-10.7-10.2-10.7-6.3 0-9.9 3.4-10.7 9.3h-5.3c.8-8 6-14.3 16-14.3 9 0 15.5 6.4 15.5 15.7 0 7.4-3.4 12.1-8.2 16.8L440 177.4v.4h24.8v4.9H434v-6.3z" />
        </g>
      </g>
    </svg>
  );
}

export default props => {
  const [hover, setOver] = useState(false);
  const [defaultIconOnLoad, setDefaultIconOnLoad] = useState(<></>);
  const [defaultHoverIcon, setDefaultHoverIcon] = useState(<></>);

  const changeOver = newOver => {
    if (props.hoverChange) {
      setOver(newOver);
    }
  };

  const setIcon = () => {
    switch (props.defaultIcon) {
      case 'white':
        setDefaultIconOnLoad(<WhiteIcon {...{ props }} />);
        break;
      case 'magenta':
        setDefaultIconOnLoad(<MagIcon {...{ props }} />);
        break;
      case 'black':
        setDefaultIconOnLoad(<BlackIcon {...{ props }} />);
        break;
      default:
        // Do not Fall
        break;
    }

    switch (props.hoverIcon) {
      case 'white':
        setDefaultHoverIcon(<WhiteIcon {...{ props }} />);
        break;
      case 'magenta':
        setDefaultHoverIcon(<MagIcon {...{ props }} />);
        break;
      case 'black':
        setDefaultHoverIcon(<BlackIcon {...{ props }} />);
        break;
      default:
        // Do not Fall
        break;
    }
  };

  useEffect(() => {
    setIcon();
  }, []);

  return (
    <div
      onMouseOver={() => changeOver(true)}
      onMouseOut={() => changeOver(false)}
      style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {hover ? defaultHoverIcon : defaultIconOnLoad}
      {/* {props.hoverChangeWhite && hover
        ? <WhiteIcon {...{ props }} />
        : props.hoverChangeMagenta && hover
          ? <MagIcon {...{ props }} />
          : props.hoverChangeBlack && hover
            ? <BlackIcon {...{ props }} /> : defaultIconOnLoad} */}
      {/* {props.defaultIconWhite
        ? (over ? <MagIcon {...{ props }} /> : <WhiteIcon {...{ props }} />)
        : (over ? <MagIcon {...{ props }} /> : <BlackIcon {...{ props }} />)} */}
    </div>
  );
};
