import React, { Component } from 'react';
import i18n from 'i18n-js';
import Modal from 'react-modal';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import { postComment, getSocialComment, likeComment } from '../../../../lib/api';
import { CommentsList } from '../../../../components';
import styles from './styles';
import SocialDetailDialogTitle from './SocialDetailDialogTitle';
import { isLandscapeMode } from '../../../../lib/utils';
import SocialDetailDialogAddComment from './SocialDetailDialogAddComment';

Modal.setAppElement('#root');

export default class SocialDetailDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      comments: [],
    };
  }

  async fetchComments(auth, socialItem) {
    const res = await getSocialComment(auth, socialItem.uuid);
    if (!res.error) {
      this.setState({ comments: res });
    } else {
      this.setState({ comments: [] });
    }
  }
  async componentWillReceiveProps(nextProps) {
    const { isOpen, auth, socials, activeItem } = nextProps;
    if (!isOpen || activeItem !== this.props.activeItem) this.setState({ comments: [] });
    if (isOpen) {
      let socialItem = socials[activeItem];
      await this.fetchComments(auth, socialItem);
    }
  }

  componentWillUnmount() {
    console.log('Unmounting: ', this.state.comments);
    this.setState({ comments: [] });
  }

  _handlePostComment = async socialItem => {
    const { auth } = this.props;
    const { comment } = this.state;
    if (comment.trim() !== '') {
      await postComment(auth, socialItem.uuid, comment);
      this.props.updateMedia({ uuid: socialItem.uuid });
      this.setState({ comment: '' });
      await this.fetchComments(auth, socialItem);
    }
  };

  _handleLikeComment = async comment => {
    const { socials, activeItem, auth } = this.props;
    let socialItem = socials[activeItem];
    await likeComment(this.props.auth, comment.id);
    this.fetchComments(auth, socialItem);
  };

  render() {
    const {
      // auth,
      socials,
      activeItem,
      onLikePressed,
      onNextPressed,
      onPrevPressed,
      isOpen,
      onClose,
      loadingMoreAvailable,
    } = this.props;
    const { comment, comments } = this.state;
    let socialItem = socials[activeItem];
    if (socialItem) {
      const formattedDate = moment(socialItem.created).format('DD MMM YYYY');
      return (
        <Modal
          isOpen={isOpen}
          style={{
            overlay: {
              display: 'flex',
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 9999,
              justifyContent: 'center',
              alignItems: 'center',
            },
            content: {
              display: 'flex',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: 'center',
              backgroundColor: 'transparent',
              borderWidth: 0,
              padding: 0,
            },
          }}>
          <div style={styles.container}>
            <div
              onClick={onPrevPressed}
              style={{
                ...styles.arrow,
                left: 5,
                visibility: activeItem !== 0 ? 'visible' : 'hidden',
              }}>
              <Icon className="fa fa-chevron-left" style={{ ...styles.iconArrow, marginRight: isLandscapeMode() ? 10 : 0 }} />
            </div>
            {isLandscapeMode() ? (
              <div style={styles.content}>
                {socialItem.type === 'video' && (
                  <video controls="controls" preload="metadata" style={styles.video} poster={socialItem.thumb.trim()}>
                    <source src={socialItem.video_url.trim()} type="video/mp4" />
                  </video>
                )}
                {socialItem.type === 'image' && (
                  <div
                    key={socialItem.title}
                    style={{
                      ...styles.img,
                      backgroundImage: `url(${socialItem.image_url.trim()})`,
                    }}
                  />
                )}
                <div style={styles.detailContainer}>
                  <SocialDetailDialogTitle
                    socialItem={socialItem}
                    formattedDate={formattedDate}
                    onLikePressed={() => onLikePressed(socialItem)}
                    onLikedPressed={() => onLikePressed(socialItem)}
                  />
                  <div style={styles.comments}>
                    <CommentsList comments={comments} onLikeComment={this._handleLikeComment} />
                  </div>
                  <SocialDetailDialogAddComment
                    comment={comment}
                    // title={socialItem.title}
                    placeholder={i18n.t('addComment.name')}
                    onChange={e => this.setState({ comment: e })}
                    postCommet={() => this._handlePostComment(socialItem)}
                  />
                </div>
              </div>
            ) : (
              <div style={styles.modalContent}>
                <SocialDetailDialogTitle
                  socialItem={socialItem}
                  formattedDate={formattedDate}
                  onLikePressed={() => onLikePressed(socialItem)}
                  onLikedPressed={() => onLikePressed(socialItem)}
                  onClose={() => onClose(socialItem)}
                />
                <div style={styles.content}>
                  <div
                    onClick={onPrevPressed}
                    style={{
                      ...styles.innerArrow,
                      left: 0,
                      marginLeft: '5px',
                      visibility: activeItem !== 0 ? 'visible' : 'hidden',
                    }}>
                    <Icon className="fa fa-chevron-left" style={{ ...styles.iconArrow, marginRight: isLandscapeMode() ? 10 : 0 }} />
                  </div>
                  {socialItem.type === 'video' && (
                    <video controls="controls" preload="metadata" style={styles.video} poster={socialItem.thumb.trim()}>
                      <source src={socialItem.video_url.trim()} type="video/mp4" />
                    </video>
                  )}
                  {socialItem.type === 'image' && (
                    <div
                      key={socialItem.title}
                      style={{
                        ...styles.img,
                        backgroundImage: `url(${socialItem.image_url.trim()})`,
                      }}
                    />
                  )}
                  <div
                    onClick={onNextPressed}
                    style={{
                      ...styles.innerArrow,
                      right: 0,
                      marginRight: '5px',
                      visibility: socials.length > activeItem + 1 || loadingMoreAvailable ? 'visible' : 'hidden',
                    }}>
                    <Icon className="fa fa-chevron-right" style={{ ...styles.iconArrow, marginLeft: isLandscapeMode() ? 10 : 0 }} />
                  </div>
                </div>
                <div style={styles.detailContainer}>
                  <SocialDetailDialogAddComment
                    comment={comment}
                    title={socialItem.title}
                    placeholder={i18n.t('addComment.name')}
                    onChange={e => this.setState({ comment: e })}
                    postCommet={() => this._handlePostComment(socialItem)}
                  />
                  <div style={styles.comments}>
                    <CommentsList comments={comments} onLikeComment={this._handleLikeComment} />
                  </div>
                </div>
              </div>
            )}
            <div
              onClick={onNextPressed}
              style={{
                ...styles.arrow,
                right: 0,
                visibility: loadingMoreAvailable ? 'visible' : 'hidden',
              }}>
              <Icon className="fa fa-chevron-right" style={{ ...styles.iconArrow, marginLeft: isLandscapeMode() ? 10 : 0 }} />
            </div>
          </div>

          <div style={styles.closeContainer} onClick={onClose}>
            <Icon className="fa fa-times" style={styles.iconClose} />
          </div>
        </Modal>
      );
    } else {
      return <Modal />;
    }
  }
}
