import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg id="Ebene_1" data-name="Ebene 1" viewBox="0 0 64 64">
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          x1="32"
          y1="48.09"
          x2="32"
          y2="-7.91"
          gradientTransform="translate(0 11.91)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ffd329" />
          <stop offset="1" stopColor="#ffde5f" />
        </linearGradient>
      </defs>
      <title>QuizFailed</title>
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path style={{ fill: 'url(#Unbenannter_Verlauf)' }} d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4Z" />
      <circle style={{ fill: '#706259', opacity: 0.85, isolation: 'isolate' }} cx="19.1" cy="28.19" r="3.81" />
      <circle style={{ fill: '#706259', opacity: 0.85, isolation: 'isolate' }} cx="44.58" cy="28.19" r="3.81" />
      <path style={{ fill: '#706259', opacity: 0.85, isolation: 'isolate' }} d="M44.58,43.07H19.1a1,1,0,0,1,0-2H44.58a1,1,0,0,1,0,2Z" />
    </svg>
  </SvgIcon>
);
