import { call, put, select } from 'redux-saga/effects';
// import { setToken } from '../../lib/api';
import qs from 'qs';

import {
  AuthActions,
  ELearningActions,
  NewsActions,
  ChartActions,
  NodeActions,
  SocialActions,
  TableActions,
  SinglePageActions,
  UserSettingsActions,
} from '../../redux';

import { sendTokenInformation, getContactUrl, revokeToken } from '../../lib/api';

import { getNavigation, localStorage } from '../../containers/BaseComponent';

import { getLocalData, getLocalInformation } from '../../resources/locale';

import WinnerSocket from '../../lib/socket';

import config from '../../config';

export async function getDeveloperToken(username, password) {
  try {
    const body = {
      grant_type: 'password',
      scope: 'edenred',
      client_id: '19401c1d-b653-4fae-9ab0-3c97497f072a',
      username,
      password,
    };
    const formBody = [];
    for (const property in body) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(body[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    const requestBody = formBody.join('&');

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Origin: config.API_URL,
    };
    const res = await fetch(config.oauth.tokenUrl, {
      method: 'post',
      headers,
      body: requestBody,
    });
    console.log('getDeveloperToken', config.oauth.tokenUrl, body);
    const json = await res.json();
    const token = json.access_token;
    const refreshToken = json.refresh_token;
    const type = '';
    const expiresIn = json.expires_in;
    const countryCode = 'EN';
    const languageCode = getLocalInformation().languageCode;

    const loginAuth = {
      token,
      refreshToken,
      expiresIn,
      id: username,
      countryCode,
      languageCode,
      type,
      csrfToken: '',
    };
    // const loginInfo = await sendTokenInformation(loginAuth);
    const contactUrl = await getContactUrl(loginAuth);
    // WinnerSocket.initSocket(loginAuth);
    // const csrfToken = loginInfo.csrf_token;

    // const auth = { ...loginAuth, csrfToken, contactUrl: contactUrl.link };
    const auth = { ...loginAuth, contactUrl: contactUrl.link };
    await getLocalData(null, auth);
    await put(UserSettingsActions.getUserSettings(auth));
    return auth;
  } catch (err) {
    console.log('err', err);
  }
}

export async function getToken(query) {
  try {
    const queryResult = qs.parse(query, { ignoreQueryPrefix: true });
    const { code } = queryResult;
    const body = {
      grant_type: 'authorization_code',
      code,
      client_id: config.oauth.clientId,
      client_secret: config.oauth.clientSecret,
      redirect_uri: config.oauth.redirectUri,
    };
    const formBody = [];
    for (const property in body) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(body[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    const requestBody = formBody.join('&');

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Origin: config.API_URL,
    };
    const res = await fetch(config.oauth.tokenUrl, {
      method: 'post',
      headers,
      body: requestBody,
    });
    const response = await res.json();
    if (response) {
      const token = response.access_token;
      const refreshToken = response.refresh_token;
      const expiresIn = response.expires_in;
      const countryCode = response.country_code === 'GB' ? 'UK' : response.country_code;
      const languageCode = response.language_code || getLocalInformation().languageCode;
      const nickname = response.nickname || '';
      const type = response.type;
      const channelId = response.channel_id;
      // const Id = response.participant_id;
      const uuid = response.id;
      // const loginInfo = await login({ token, refreshToken, expiresIn, id: Id, countryCode });
      const loginAuth = {
        token,
        refreshToken,
        expiresIn,
        id: uuid,
        countryCode,
        languageCode,
        type,
        uuid: uuid,
        nickname: nickname,
        channelId: channelId,
      };
      const loginInfo = await sendTokenInformation(loginAuth);
      const contactUrl = await getContactUrl(loginAuth);
      WinnerSocket.initSocket(loginAuth);
      const csrfToken = loginInfo.csrf_token;

      const auth = { ...loginAuth, csrfToken, contactUrl: contactUrl.link };
      await getLocalData(null, auth);
      await put(UserSettingsActions.getUserSettings(auth));
      return auth;
    }
    return {};
  } catch (err) {
    console.log('_handleOauth err', err);
  }
}

export async function getRefreshToken(auth) {
  let ret = null;
  const body = {
    grant_type: 'refresh_token',
    client_id: config.oauth.clientId,
    refresh_token: auth.refreshToken,
    client_secret: config.oauth.clientSecret,
    redirect_uri: config.oauth.redirectUri,
  };
  const formBody = [];
  for (const property in body) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(body[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }
  const requestBody = formBody.join('&');

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Origin: config.API_URL,
  };
  const res = await fetch(config.oauth.refreshTokenUrl, {
    method: 'post',
    headers,
    body: requestBody,
  });
  const response = await res.json();
  if (response) {
    const token = response.access_token;
    const refreshToken = response.refresh_token;
    const expiresIn = response.expires_in;
    const type = response.type;
    // const loginInfo = await login({ token, refreshToken, expiresIn, countryCode });
    ret = {
      ...auth,
      token,
      refreshToken,
      expiresIn,
      refreshed: true,
      type,
    };
  }
  return ret;
}

export function* refreshOauth(auth) {
  let ret = null;
  if (auth.fromParameter || auth.token === config.unpublishedToken) {
    return auth;
  }
  const now = new Date().getTime();
  const diff = now - (auth.lastFetchedTime || 0);
  // let diff = 600000000;
  try {
    if (auth.refreshToken && diff > auth.expiresIn * 1000) {
      ret = yield call(getRefreshToken, auth);
      if (ret) {
        const loginInfo = yield call(sendTokenInformation, ret);
        yield put(
          AuthActions.authTokenRefreshed({
            ...ret,
            csrfToken: loginInfo.csrf_token,
          }),
        );
      } else {
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        getNavigation().replace('/login');
      }
    } else if (auth.refreshToken && diff < auth.expiresIn * 1000) {
      ret = { ...auth };
      WinnerSocket.initSocket(ret);
      let csrfToken = ret.csrfToken;
      if (!csrfToken) {
        const loginInfo = yield call(sendTokenInformation, ret);
        yield put(
          AuthActions.authTokenRefreshed({
            ...ret,
            csrfToken: loginInfo.csrf_token,
          }),
        );
      }
    } else {
      if (getNavigation().location.pathname !== '/') {
        localStorage.setItem('lastPath', getNavigation().location.pathname);
      }
      getNavigation().replace('/login');
    }
  } catch (err) {
    if (getNavigation().location.pathname !== '/') {
      localStorage.setItem('lastPath', getNavigation().location.pathname);
    }
    getNavigation().replace('/login');
  }
  return ret;
}

export function* signOut() {
  try {
    const { auth } = yield select();

    const body = {
      token: auth.token,
      client_secret: config.oauth.clientSecret,
      client_id: config.oauth.clientId,
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    yield fetch(config.oauth.revokeTokenUrl, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    });

    yield revokeToken(auth);

    yield put(AuthActions.clear());

    WinnerSocket.unInitialize();

    localStorage.removeItem('userLanguageCode');

    yield put(ELearningActions.clear());
    yield put(NewsActions.clear());
    yield put(NodeActions.clear());
    yield put(SocialActions.clear());
    yield put(ChartActions.clear());
    yield put(TableActions.clear());
    yield put(SinglePageActions.clear());

    // getNavigation().replace('/login');
    window.location.replace('/login');
  } catch (err) {
    console.log('signOut err', err);
  }
}
