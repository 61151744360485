import React from 'react';

import styles from './styles';

const Video = ({ url, poster, openDetails }) => {
  // setTimeout(() => {
  //   const clickListener = document.querySelector('.video-tag');
  //   clickListener.addEventListener('click', e => {
  //     console.log(e);
  //   });
  // }, 200);
  return (
    <video
      className="video-tag"
      width="350"
      height={'100%'}
      controls="controls"
      preload="metadata"
      style={styles.video}
      poster={poster}
      onClick={openDetails}>
      <source src={url} type="video/mp4" />
    </video>
  );
};

export default Video;
