import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M60,11c0-1.1-0.9-2-2-2H6c-1.1,0-2,0.9-2,2v42c0,1.1,0.9,2,2,2h39l15-15l0,0V11z M45,50.76V41c0-0.55,0.45-1,1-1h9.76
        L45,50.76z M57,38H46c-1.66,0-3,1.34-3,3v11H7V12h50V38z M13.68,20.21l4.45,8.85h2v-12h-1.79V26l-4.47-8.9h-2v12h1.84L13.68,20.21z
         M29.87,27.36h-5.34v-3.65h4.86V22h-4.86v-3.24h5.26v-1.7h-7.24v12h7.32L29.87,27.36z M35.57,29.06l1.92-9.27l1.92,9.27h2l2.71-12
        h-2l-1.72,9.29l-1.89-9.29h-2l-1.92,9.27l-1.72-9.27h-2l2.65,12L35.57,29.06z M49,27.73c-0.67,0.03-1.31-0.32-1.65-0.9
        c-0.24-0.44-0.36-0.94-0.35-1.44h-2.06c0.01,0.92,0.31,1.81,0.85,2.55c0.78,0.96,1.98,1.48,3.21,1.41c1.06,0.06,2.1-0.3,2.9-1
        c0.67-0.62,1.03-1.5,1-2.41c0-1.33-0.79-2.54-2-3.08c-0.51-0.26-1.04-0.5-1.58-0.7c-0.58-0.21-1.14-0.5-1.64-0.86
        c-0.38-0.32-0.59-0.8-0.57-1.3c-0.02-0.44,0.17-0.86,0.5-1.15c0.33-0.25,0.73-0.37,1.14-0.35c0.57-0.05,1.12,0.18,1.49,0.61
        c0.23,0.34,0.36,0.73,0.37,1.14h1.92c-0.02-0.75-0.26-1.47-0.69-2.08c-0.74-0.94-1.9-1.45-3.1-1.37c-0.89-0.04-1.76,0.25-2.46,0.81
        c-0.71,0.59-1.11,1.47-1.08,2.39c-0.03,0.85,0.29,1.67,0.9,2.26c0.55,0.52,1.2,0.92,1.9,1.19c0.69,0.26,1.35,0.56,2,0.9
        c0.88,0.47,1.21,1.56,0.74,2.44C50.4,27.42,49.71,27.79,49,27.73z"
      />
    </svg>
  </SvgIcon>
);
