import React from 'react';

import Bar from '../../../../components/Common/Bar';

import styles from './styles.module.scss';

const BestPerformance = ({ charts }) => {
  return (
    <div className={styles.container}>
      <div className={styles.darkBackground} />
      <Bar
        maxValue={charts.year_top_points}
        name={charts.year_top_nick}
        score={charts.year_top_points}
        rank="1"
        trophy={charts.year_top_trophy}
        lineColor={charts.year_top_trophy}
        main={true}
      />
      <Bar
        maxValue={charts.year_top_points}
        name={charts.year_runnerup_nick}
        score={charts.year_runnerup_points}
        rank="2"
        trophy={charts.year_runnerup_trophy}
        lineColor={charts.year_runnerup_trophy}
        main={true}
      />
      <Bar
        maxValue={charts.year_top_points}
        name={charts.year_third_nick}
        score={charts.year_third_points}
        rank="3"
        trophy={charts.year_third_trophy}
        transparentBar={!(charts.year_third_trophy === 'gold' || charts.year_third_trophy === 'silver')}
        lineColor={charts.year_third_trophy === 'gold' || charts.year_third_trophy === 'silver' ? charts.year_third_trophy : false}
        main={true}
      />

      {/* <Bar
      maxValue={400} name={'Doctor Strange'} score={390} rank={1} trophy={'gold'}
      lineColor={'gold'} main />
    <Bar
      maxValue={400} name={'Misster Ora'} score={320} rank={2} trophy={'silver'}
      main />
    <Bar
      maxValue={400} name={'Mssis Smitt'} score={200} rank={3} trophy={'silver'}
      main /> */}
    </div>
  );
};

export default BestPerformance;
