export const convertNotification = data => {
  const { id, uuid, channel_id, message, link, created, notify, is_read, is_seen } = data;

  return {
    id,
    uuid,
    channelId: channel_id,
    message,
    link,
    created,
    notify,
    isRead: is_read === '1' ? true : false,
    isSeen: is_seen === '1' ? true : false,
  };
};

export const convertNotifications = data => {
  let byId = {};
  let allIds = [];

  data.forEach(item => {
    const { uuid } = item;

    allIds.push(uuid);

    byId = {
      ...byId,
      [uuid]: convertNotification(item),
    };
  });

  return { byId, allIds };
};
