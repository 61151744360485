import React from 'react';
import i18n from 'i18n-js';
import { Colours, CommonStyles, PNG, SVG } from '../../resources';
import { isLandscapeMode } from '../../lib/utils';

const styles = {
  bottomContainer: {
    width: '100vw',
    backgroundColor: Colours.whiteGray,
    paddingTop: '5vmin',
    paddingBottom: '5vmin',
  },
  bottomTitle: {
    marginLeft: '20vw',
    ...CommonStyles.h1,
    marginBottom: '3vmin',
  },
  imgPrize: {
    width: '40vw',
    marginRight: '2vmin',
    marginBottom: '1vmin',
  },
  prizeAboutContainer: {
    maxWidth: isLandscapeMode() ? '80vw' : '90vw',
    paddingLeft: isLandscapeMode() ? '20vw' : '10vw',
  },
  rowImage: {
    marginLeft: isLandscapeMode() ? '15vw' : '10vw',
    marginTop: '3vmin',
    width: isLandscapeMode() ? '80vw' : '80vw',
    display: 'flex',
    flexDirection: 'row',
  },
  imgPrize1: {
    width: '39vw',
    marginRight: '1vw',
  },
  moreContainer: {
    ...CommonStyles.h3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: isLandscapeMode() ? '60vw' : '80vw',
    marginLeft: isLandscapeMode() ? '20vw' : '10vw',
    marginTop: '3vmin',
    marginBottom: '3vmin',
    borderBottomWidth: 1,
    paddingBottom: '3vmin',
    borderBottomColor: Colours.darkBlack,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  iconArrow: {
    width: '3vmin',
    height: '3vmin',
    marginLeft: '3vmin',
    transform: 'rotate(270deg)',
  },
};

export default () => (
  <div style={styles.bottomContainer}>
    <img src={PNG.Temp3} align="right" style={styles.imgPrize} alt="" />
    <div style={styles.prizeAboutContainer}>
      <div style={{ ...CommonStyles.h2, fontWeight: 'bold', marginBottom: '2vmin', paddingTop: '1vmin' }}>
        Headline Text Gold
        <br />
      </div>
      <div style={CommonStyles.h3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
        massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
        justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
        Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper
        ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem
        neque sed ipsum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
        consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
        venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean
        vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
        feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue.
        Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero,
        sit amet adipiscing sem neque sed ipsum.
      </div>
    </div>
    <div style={styles.rowImage}>
      <img src={PNG.Temp3} style={styles.imgPrize1} alt="" />
      <img src={PNG.Temp4} style={styles.imgPrize1} alt="" />
    </div>
    <div
      style={{
        ...CommonStyles.h3,
        width: isLandscapeMode() ? '60vw' : '80vw',
        marginLeft: isLandscapeMode() ? '20vw' : '10vw',
        marginTop: '3vmin',
      }}>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
      dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
      enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
      dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean
      leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra
      nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget
      dui. Etiam rhoncus.
    </div>
    <div style={styles.moreContainer}>
      {i18n.t('moreInformation.name')}
      <SVG.LeftArrow style={styles.iconArrow} />
    </div>
  </div>
);
