import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getUserSettings: ['auth', 'withoutNavigation'],
  getUserSettingsSuccess: ['data'],
  getUserClubMedal: ['auth'],
  getUserClubMedalSuccess: ['currentMedal'],
  updateUserSettings: ['auth', 'data'],
});

export const UserSettingsType = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {};

/* ------------- Reducers ------------- */
const getUserSettingsSuccess = (state, { data }) => ({ ...state, ...data });
const getUserClubMedalSuccess = (state, { currentMedal }) => {
  return { ...state, currentMedal };
};

/* ------------- Hookup Reducers To Types ------------- */
export const userSettingsReducer = createReducer(defaultState, {
  [Types.GET_USER_SETTINGS_SUCCESS]: getUserSettingsSuccess,
  [Types.GET_USER_CLUB_MEDAL_SUCCESS]: getUserClubMedalSuccess,
});
