import React from 'react';
import clsx from 'clsx';

import { SVG } from '../../../../resources';

import styles from './index.module.scss';

const Cup = ({ counter, gold, color }) => {
  // console.log({ counter, gold, color });
  return (
    <div className={styles.container}>
      <SVG.Cup className={clsx([styles.cup, gold ? styles.gold : null])} style={{ ...(color ? { fill: color } : {}) }} />
      <div className={styles.counter}>{counter}</div>
    </div>
  );
};

export default Cup;
