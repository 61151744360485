import { call, put } from 'redux-saga/effects';
import { getNews as apiGetNews } from '../../lib/api';
import NewsActions from '../news';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getNews({ auth, offset, limit, showPublished }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetNews, authRefreshed, offset, limit, showPublished);
      if (!ret.error) {
        yield put(NewsActions.getNewsSuccess(ret, ret.length !== 0, offset));
      } else {
        yield put(NewsActions.requestFailed('getNews'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(NewsActions.requestFailed('getNews'));
  }
}
