import React, { Component } from 'react';
import _ from 'lodash';
import ImageSlider from '../Common/ImageSlider';
import ComboBox from '../Common/ComboBox';
import { isLandscapeMode } from '../../lib/utils';
import { Colours, CommonStyles, PNG } from '../../resources';

const styles = {
  container: {
    width: '100vw',
    backgroundColor: Colours.white,
    paddingTop: '5vmin',
    paddingBottom: '5vmin',
    overflow: 'hidden',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: isLandscapeMode() ? '50vw' : '80vw',
    marginLeft: isLandscapeMode() ? '20vw' : '10vw',
    justifyContent: 'space-between',
    marginBottom: '5vmin',
  },
  title: {
    ...CommonStyles.h1,
    marginBottom: '3vmin',
  },
  subtitle: {
    ...CommonStyles.h2,
  },
  formContainer: {
    marginLeft: '10vmin',
    width: isLandscapeMode() ? '10vw' : '100px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  img: {
    width: '25vw',
    height: '17vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
  },
};
const images = [PNG.Temp1, PNG.Temp2, PNG.Temp3, PNG.Temp4, PNG.Temp1, PNG.Temp2];
export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearValue: 2018,
    };
  }
  _handleChangeYear = event => this.setState({ yearValue: event.target.value });
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.rowContainer}>
          <div style={styles.titleContainer}>
            <div style={styles.title}>2. Gallery</div>
            <div style={styles.subtitle}>Winner Circle Fotos </div>
          </div>
          <div style={styles.formContainer}>
            <ComboBox values={_.range(2010, 2018)} onChange={this._handleChangeYear} value={this.state.yearValue} />
          </div>
        </div>
        <ImageSlider images={images} isInfinite visibleItems={4}>
          {images.map((image, key) => (
            <div key={key} style={{ ...styles.img, backgroundImage: `url(${image})` }} />
          ))}
        </ImageSlider>
      </div>
    );
  }
}
