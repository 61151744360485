import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M45.37,6.52A13.64,13.64,0,0,1,59,20.16C59,41,32,56.87,32,56.87S5,41,5,20.16a13.64,13.64,0,0,1,27-2.68A13.64,13.64,0,0,1,45.37,6.52Zm0-2.71a16.38,16.38,0,0,0-13.37,7A16.33,16.33,0,0,0,2.29,20.16c0,22.11,27.18,38.35,28.34,39L32,60l1.37-.81c1.16-.68,28.34-16.93,28.34-39A16.37,16.37,0,0,0,45.37,3.81Z" />
    </svg>
  </SvgIcon>
);
