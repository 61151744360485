import { createReducer, createActions } from 'reduxsauce';

import { DATA_STATUSES } from '../constants/common';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getQuiz: ['quizId'],
  saveQuiz: ['payload'],
  postQuizAnswer: ['payload'],
  setStatus: ['status'],
  setMessage: ['message'],
  clear: [],
});

export const QuizTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  data: {},
  message: '',
  status: DATA_STATUSES.initial,
};

/* ------------- Reducers ------------- */
const saveQuiz = (state, { payload }) => ({
  ...state,
  data: payload,
});

const setStatus = (state, { status }) => ({
  ...state,
  status,
});

const setMessage = (state, { message }) => ({
  ...state,
  message,
});

const clear = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const quizReducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_QUIZ]: saveQuiz,
  [Types.SET_STATUS]: setStatus,
  [Types.SET_MESSAGE]: setMessage,
  [Types.CLEAR]: clear,
});
