import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64.001 64">
      <rect style={{ fill: 'none' }} d="M0 0H64.001V64H0z" />
      <path d="M58.003,4.004h-41c-1.103,0-2,0.898-2,2v30c0,1.103,0.897,2,2,2h10v8.001h-20v-28h6v-3h-7 c-1.103,0-2,0.897-2,2v30c0,1.102,0.897,2,2,2h21.003L38.001,60V49.005h9.002c1.103,0,2-0.898,2-2v-7.001h-3v6.001H35.001v6.753 l-5.878-5.878l8.875-8.876h20.005c1.103,0,2-0.897,2-2v-30C60.003,4.901,59.106,4.004,58.003,4.004z M57.003,35.004H36.756 l-6.753,6.753v-6.753h-12v-28h39V35.004z" />
    </svg>
  </SvgIcon>
);
