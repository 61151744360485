import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props} viewBox={'0 0 221.54 33.91'}>
    <svg x="0px" y="0px" viewBox="0 0 221.54 33.91">
      <polygon points="183.38 25.86 182.94 1.01 190.78 1.01 190.78 32.34 179.51 32.34 171.69 6.64 171.52 6.64 171.96 32.34 164.17 32.34 164.17 1.01 175.93 1.01 183.2 25.86 183.38 25.86" />
      <polygon points="40.92 3.94 36.59 32.67 28.03 32.67 33.9 1.01 47.99 1.01 53.82 32.67 45.31 32.67 41.09 3.94 40.92 3.94" />
      <polygon points="77.83 25.22 81.74 1.01 94.65 1.01 95.35 32.67 87.43 32.67 87.23 4.21 87.06 4.21 81.77 32.67 73.73 32.67 68.44 4.21 68.27 4.21 68.06 32.67 60.14 32.67 60.84 1.01 73.75 1.01 77.66 25.22 77.83 25.22" />
      <path d="M14.71,23.74a4.17,4.17,0,0,1,.06,2.34c-.26,1.06-1,2.13-3.08,2.13S8.5,27.07,8.5,25.34V22.27H0v2.44c0,7.07,5.56,9.2,11.51,9.2,5.73,0,10.44-2,11.19-7.24a15.51,15.51,0,0,0,0-5.19C21.33,14.84,9.31,12.87,8.42,9.16a4.06,4.06,0,0,1,0-1.66,2.61,2.61,0,0,1,2.89-2.12,2.7,2.7,0,0,1,2.94,2.86v2h7.9V8c0-6.89-6.19-8-10.67-8C5.82,0,1.22,1.86.38,7A11,11,0,0,0,.45,11.3C1.82,17.77,13.07,19.64,14.71,23.74Z" />
      <path d="M117.64,23.68a4.11,4.11,0,0,1,.07,2.32c-.26,1-1,2.1-3.06,2.1S111.5,27,111.5,25.26v-3h-8.4v2.42c0,7,5.51,9.1,11.4,9.1,5.67,0,10.33-1.93,11.08-7.17a15,15,0,0,0,0-5.13c-1.32-6.57-13.21-8.52-14.1-12.2a4,4,0,0,1,0-1.64,2.58,2.58,0,0,1,2.86-2.11,2.67,2.67,0,0,1,2.91,2.85v1.93H125V8.07c0-6.83-6.14-7.89-10.58-7.89-5.56,0-10.12,1.84-11,7a10.8,10.8,0,0,0,.07,4.23C104.89,17.77,116,19.62,117.64,23.68Z" />
      <path d="M144.22,27.93a2.84,2.84,0,0,0,3-2.3,5.74,5.74,0,0,0,.07-1.2V1h8V23.71c0,.58,0,1.77-.08,2.08-.56,5.91-5.23,7.82-11.05,7.82s-10.49-1.91-11-7.82c0-.31-.09-1.5-.08-2.08V1h8V24.43a6.47,6.47,0,0,0,.08,1.2A2.83,2.83,0,0,0,144.22,27.93Z" />
      <path d="M210.26,27.6a3,3,0,0,0,3.24-2.3,6.68,6.68,0,0,0,.07-1.2V19.51h-3.24V14.89h11.21v8.49a18.62,18.62,0,0,1-.11,2.08c-.52,5.77-5.52,7.82-11.13,7.82s-10.62-2.05-11.14-7.82a18.62,18.62,0,0,1-.11-2.08V10.05c0-.56.07-1.55.13-2.08.71-5.92,5.5-7.82,11.12-7.82S220.83,2,221.41,8a19,19,0,0,1,.07,2.08v1.06h-8V9.33a8.71,8.71,0,0,0-.1-1.2,2.9,2.9,0,0,0-3.17-2.3,2.93,2.93,0,0,0-3.15,2.3,7.62,7.62,0,0,0-.13,1.5V24.1a7.72,7.72,0,0,0,.08,1.2A3,3,0,0,0,210.26,27.6Z" />
    </svg>
  </SvgIcon>
);
