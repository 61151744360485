import React from 'react';
// import Icon from '@material-ui/core/Icon';
import { Colours } from '../../../resources';
// import { isMobile } from '../../../lib/utils';

const FakeToggle = ({ title, mobile }) => {
  const renderYesIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height={mobile ? '22' : '27'} viewBox="0 0 24 24" width={mobile ? '22' : '27'} fill={Colours.primary}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </svg>
    );
  };

  const styles = {
    container: {
      backgroundColor: Colours.primary,
      width: mobile ? '115px' : '140px',
      height: mobile ? '34px' : '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: mobile ? '4px' : '3px',
      cursor: 'pointer',
    },
    text: {
      width: '100px',
      textAlign: 'center',
      color: '#fff',
      fontSize: mobile ? '17px' : '22px',
    },
    switchToggle: {
      backgroundColor: '#fff',
      height: mobile ? '24px' : '34px',
      width: mobile ? '27px' : '34px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.text}>{title}</div>
      <div style={styles.switchToggle}>{renderYesIcon()}</div>
    </div>
  );
};

export default FakeToggle;
