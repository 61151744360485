import React from 'react';
import i18n from 'i18n-js';
import { Colours } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';
const styles = {
  container: {
    width: isLandscapeMode() ? '60vw' : '90vw',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '5vmin',
    marginBottom: '5vmin',
  },
  row: {
    display: 'flex',
    width: isLandscapeMode() ? '60vw' : '90vw',
    flexDirection: 'row',
    height: '5vmin',
  },
  itemHeader: {
    marginLeft: '1.5vmin',
    marginRight: '1.5vmin',
    fontSize: isLandscapeMode() ? '2.5vmin' : '3vmin',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  menuIcon: {
    width: '4vmin',
    height: '4vmin',
  },
  userIcon: {
    width: '4vmin',
    height: '4vmin',
  },
  list: {
    marginTop: '3vmin',
    width: isLandscapeMode() ? '60vw' : '90vw',
    height: isLandscapeMode() ? '60vmin' : '80vmin',
    overflow: 'auto',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    borderWidth: 0,
    // borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colours.whiteGray,
    borderStyle: 'solid',
  },
  dataItem: {
    marginLeft: '1.5vmin',
    marginRight: '1.5vmin',
    fontSize: isLandscapeMode() ? '2vmin' : '3.5vmin',
    display: 'flex',
    alignItems: 'center',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
  },
  noDataTitle: {
    marginTop: '3vmin',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    fontSize: '2vmin',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colours.background,
  },
};

export default ({ data }) => {
  let hasData = data && data.transactions && data.transactions.length > 0;
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={{ ...styles.itemHeader, flex: 0.3 }} />
        <div style={{ ...styles.itemHeader, flex: 1 }}>{i18n.t('date.name')}</div>
        <div style={{ ...styles.itemHeader, flex: 1.5 }}>{i18n.t('time.name')}</div>
        <div style={{ ...styles.itemHeader, flex: 2 }}>{i18n.t('description.name')}</div>
        <div style={{ ...styles.itemHeader, flex: 1 }}>{i18n.t('asAmount.name')}</div>
      </div>
      {hasData ? (
        <div style={styles.list}>
          {data.transactions.map((item, index) => {
            let backgroundColor = index % 2 === 0 ? Colours.background : Colours.white;
            return (
              <div style={{ ...styles.dataRow, backgroundColor, borderTopWidth: index === 0 ? 1 : 0 }} key={`data-${index}`}>
                <div style={{ ...styles.itemHeader, flex: 0.3 }} />
                <div style={{ ...styles.dataItem, flex: 1 }}>{new Date(item.date).toLocaleDateString()}</div>
                <div style={{ ...styles.dataItem, flex: 1.5 }}>{new Date(item.date).toLocaleTimeString()}</div>
                <div style={{ ...styles.dataItem, flex: 2 }}>{item.description}</div>
                <div style={{ ...styles.dataItem, flex: 1, color: item.asAmount < 0 ? Colours.primary : Colours.green }}>{item.asAmount}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={styles.noDataTitle}>No Data Yet</div>
      )}
    </div>
  );
};
