import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { FactSheetsActions } from '../../../../redux';
import { Loading } from '../../../index';
import { selectFactSheetsInOrder } from '../../../../redux/selector/factSheets';
import Header from './Header';

import styles from './index.module.scss';

const WebFactSheetsList = ({ factSheets, getFactSheets, history, location, checkItemId }) => {
  useEffect(() => {
    getFactSheets();
  }, []);

  const _handleDetailButtonClick = data => {
    const params = new URLSearchParams(location.search);
    const urlId = params.get('id');
    const urlToken = params.get('token');
    console.log(urlId && urlToken);
    if (urlId && urlToken) {
      history.push({ ...data, pathname: '/e-training/web-fact-sheets/detail', search: location.search });
    } else {
      history.push('/e-training/web-fact-sheets/detail', { ...data });
    }
  };

  return (
    <div className={styles.container} styles={{ padding: `${checkItemId() ? '0 20px' : ''}` }}>
      <Header history={history} />
      {!factSheets ? (
        <Loading />
      ) : (
        <div className={styles.list}>
          {factSheets.map((item, index) => (
            <div key={`${item.title}-${index}`} className={styles.item} onClick={() => _handleDetailButtonClick(item)}>
              <div style={{ backgroundImage: `url(${item.image})` }} className={styles.image} />
              <div className={styles.label}>{item.title}</div>
            </div>
          ))}
        </div>
      )}
      <div className={{ height: '10vh' }} />
    </div>
  );
};

const mapStateToProps = state => ({
  factSheets: selectFactSheetsInOrder(state),
});

const mapDispatchToProps = dispatch => ({
  getFactSheets: () => dispatch(FactSheetsActions.getFactSheets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebFactSheetsList);
