import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import '../App.css';

const styles = {
  selectContainer: {
    marginRight: '3vmin',
    width: '30vmin',
  },
  outlineInput: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
};

export default ({ values, month = false, onChange, value, style }) => {
  return (
    <FormControl variant="outlined" style={{ ...styles.selectContainer, ...style }}>
      <Select
        value={value}
        onChange={onChange}
        classes={{ select: 'select-container' }}
        input={<OutlinedInput classes={{ input: 'text-outline' }} />}>
        {values.map((item, index) => (
          <MenuItem key={item} value={month ? (index + 1 < 10 ? '0' + (index + 1) : index + 1) : item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
