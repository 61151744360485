import { call, put, select, delay } from 'redux-saga/effects';
import {
  getSocial as apiGetSocial,
  getSocialCount as apiGetSocialCount,
  getSocialByID,
  likeMedia as apiLikeMedia,
  updateMedia as apiUpdateMedia,
  deleteMedia as apiDeleteMedia,
  сreatePost as apiCreatePost,
} from '../../lib/api';

import SocialActions from '../social';
import AuthActions from '../auth';
import { refreshOauth } from './auth';

import { getAuth } from '../selector/auth';
import { getFilterSearch, getFilterCountry, getFilterOwner } from '../selector/social';

import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getSocial({ payload }) {
  try {
    const { auth, offset, limit } = payload;

    const search = yield select(getFilterSearch);
    const country = yield select(getFilterCountry);
    const owner = yield select(getFilterOwner);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const ret = yield call(apiGetSocial, {
        auth: authRefreshed,
        offset,
        limit,
        search,
        country,
        owner,
      });
      if (!ret.error) {
        yield put(SocialActions.getSocialSuccess(ret, ret.length !== 0, offset));
      } else {
        yield put(SocialActions.requestFailed('getSocial'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('getSocial'));
  }
}

export function* getSocialCount({ payload }) {
  try {
    const { auth } = payload;

    const search = yield select(getFilterSearch);
    const country = yield select(getFilterCountry);
    const owner = yield select(getFilterOwner);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const ret = yield call(apiGetSocialCount, {
        auth: authRefreshed,
        search,
        country,
        owner,
      });
      if (!ret.error) {
        // console.log({ ret }, ret.total_count);
        yield put(SocialActions.getSocialCount(ret.total_count));
      } else {
        yield put(SocialActions.requestFailed('getSocialCount'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('getSocialCount'));
  }
}

export function* getSinglePost({ payload }) {
  try {
    const { uuid } = payload;

    const auth = yield select(getAuth);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const ret = yield call(apiGetSocial, {
        auth: authRefreshed,
        uuid,
        single: true,
      });
      if (!ret.error) {
        yield put(SocialActions.getSocialSuccess(ret, true, 0));
      } else {
        yield put(SocialActions.requestFailed('getSocial'));
        if (ret.status === 403) {
          yield put(AuthActions.signOut());
        }
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('getSocial'));
  }
}

export function* likeMedia({ auth, media }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiLikeMedia, authRefreshed, media.uuid);
      if (!ret.error) {
        const newMedia = yield call(getSocialByID, authRefreshed, media.uuid);
        yield put(SocialActions.updateMediaSuccess({ media: newMedia[0] }));
      } else {
        yield put(SocialActions.requestFailed('likeMedia'));
        if (ret.status === 403) {
          yield put(AuthActions.signOut());
        }
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('likeMedia'));
  }
}

export function* editMedia({ payload: { auth, uuid, values } }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiUpdateMedia, {
        auth: authRefreshed,
        uuid,
        values,
      });

      if (ret.status === 403) {
        yield put(AuthActions.signOut());
      }

      if (!ret.error) {
        const newMedia = yield call(getSocialByID, authRefreshed, uuid);
        if (newMedia && newMedia.length > 0) {
          yield put(SocialActions.updateMediaSuccess({ media: newMedia[0] }));
        }
      } else {
        yield put(SocialActions.requestFailed('editMedia'));
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('editMedia'));
  }
}

export function* deleteMedia({ payload: { auth, uuid } }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiDeleteMedia, {
        auth: authRefreshed,
        uuid,
      });

      if (ret.status === 403) {
        yield put(AuthActions.signOut());
      }

      if (!ret.error) {
        yield put(SocialActions.deleteMediaSuccess({ uuid }));
      } else {
        yield put(SocialActions.requestFailed('deleteMedia'));
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('deleteMedia'));
  }
}

export function* updateMedia({ payload }) {
  try {
    const { uuid } = payload;
    const auth = yield select(getAuth);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const ret = yield call(apiGetSocial, {
        auth: authRefreshed,
        uuid,
      });

      if (ret.status === 403) {
        yield put(AuthActions.signOut());
      }

      if (!ret.error) {
        yield put(SocialActions.updateMediaSuccess({ media: ret[0] }));
      } else {
        yield put(SocialActions.requestFailed('getLastPost'));
      }
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('getLastPost'));
  }
}

export function* createPost({ payload }) {
  try {
    const auth = yield select(getAuth);
    const country = yield select(getFilterCountry);
    const owner = yield select(getFilterOwner);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const response = yield call(apiCreatePost, {
        auth: authRefreshed,
        data: payload,
      });

      if (response.status === 403) {
        yield put(AuthActions.signOut());
      }

      if (!response.error) {
        yield delay(1000);

        if (country) {
          if (payload.local) {
            yield put(SocialActions.getLastPost({ uuid: response.media }));
          }
        } else {
          if (payload.local) {
            if (owner) {
              yield put(SocialActions.getLastPost({ uuid: response.media }));
            }
          } else {
            yield put(SocialActions.getLastPost({ uuid: response.media }));
          }
        }
      } else {
        console.log('Unable to create post');
      }
    }
  } catch (err) {
    console.log('Unable to create post');
  }
}

// REMOVE
export function* getLastPost({ payload }) {
  try {
    const { uuid } = payload;
    const auth = yield select(getAuth);
    const country = yield select(getFilterCountry);
    const owner = yield select(getFilterOwner);

    const authRefreshed = yield call(refreshOauth, auth);

    if (authRefreshed) {
      const ret = yield call(apiGetSocial, {
        auth: authRefreshed,
        uuid,
        country,
        owner,
      });

      if (ret.status === 403) {
        yield put(AuthActions.signOut());
      }

      yield put(SocialActions.saveLastPost({ post: ret[0] }));
    }
  } catch (err) {
    yield put(SocialActions.requestFailed('getLastPost'));
  }
}
