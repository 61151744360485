import { Colours } from '../../../../resources';

export default {
  container: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    height: '70vmin',
    width: '40vmin',
  },
  header: {
    display: 'flex',
    fontSize: 19,
    fontWeight: '900',
    textAlign: 'center',
    justifyContent: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#efefef',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  name: {
    color: Colours.darkBlack,
    fontSize: 16,
    fontWeight: 'bold',
  },
  username: {
    marginTop: 3,
    color: Colours.gray,
    fontSize: 15,
    fontWeight: 'bold',
  },
  like: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    color: '#999',
    fontSize: 14,
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    marginTop: -35,
    marginLeft: '37vmin',
    cursor: 'pointer',
  },
  iconClose: {
    fontSize: '3vmin',
    fontWeight: 'regular',
  },
};
