import React from 'react';
import { Colours } from '../../../resources';
import styles from './styles';
import Switch from 'react-switch';
import i18n from 'i18n-js';

const PrivacyToggle = ({ onChange, value, mobile, offLabel, onLabel }) => {
  const renderYesIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={mobile ? '22' : '27'}
        viewBox="0 0 24 24"
        width={mobile ? '22' : '27'}
        fill={value ? Colours.primary : Colours.gray}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </svg>
    );
  };
  const renderNoIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={mobile ? '22' : '27'}
        viewBox="0 0 24 24"
        width={mobile ? '22' : '27'}
        fill={value ? Colours.primary : Colours.gray}>
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  };
  return (
    <div
      style={{
        ...styles.wrapper,
        borderColor: value ? Colours.primary : Colours.gray,
        fontSize: mobile ? 16 : 22,
        position: 'relative',
      }}>
      <Switch
        onChange={onChange}
        checked={value}
        width={mobile ? 115 : 140}
        height={mobile ? 32 : 38}
        handleDiameter={mobile ? 24 : 34}
        offColor={Colours.gray}
        onColor={Colours.primary} // eslint-disable-line
        offHandleColor={Colours.middleGray}
        onHandleColor={Colours.white} // eslint-disable-line
        uncheckedIcon={
          <div style={{ ...styles.uncheckedIcon, transform: `translateX(${mobile ? (offLabel ? -6 : -10) : -22}px)` }}>
            {offLabel || i18n.t('privacyToggleNo.name')}
          </div>
        }
        checkedIcon={
          <div style={{ ...styles.checkedIcon, transform: `translateX(${mobile ? (onLabel ? 6 : 10) : onLabel ? 8 : 22}px)` }}>
            {onLabel || i18n.t('privacyToggleYes.name')}
          </div>
        }></Switch>
      {mobile ? (
        <React.Fragment>
          <div style={{ ...styles.toogleIcon, top: 5, left: 5, display: value ? 'none' : 'block' }}>{renderNoIcon()}</div>
          <div style={{ ...styles.toogleIcon, top: 5, left: 88, display: value ? 'block' : 'none' }}>{renderYesIcon()}</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ ...styles.toogleIcon, top: 5, left: 5, display: value ? 'none' : 'block' }}>{renderNoIcon()}</div>
          <div style={{ ...styles.toogleIcon, top: 5, left: 108, display: value ? 'block' : 'none' }}>{renderYesIcon()}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PrivacyToggle;
