import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path d="M45.008,27.027v-4.003c0-3.311,2.688-6,6-6c3.314,0,6,2.689,6,6v4.003c0,3.313-2.686,5.999-6,5.999 C47.695,33.026,45.008,30.341,45.008,27.027z M13.008,33.026c3.314,0,6-2.686,6-5.999v-4.003c0-3.311-2.686-6-6-6 c-3.312,0-6,2.689-6,6v4.003C7.008,30.341,9.695,33.026,13.008,33.026z M21.602,36.631C20.408,35.616,18.862,35,17.17,35H8.848 c-3.778,0-6.84,3.051-6.84,6.817V45h11.528C14.688,41.053,17.726,37.913,21.602,36.631z M55.17,35h-8.322 c-1.688,0-3.23,0.612-4.423,1.622c3.889,1.275,6.938,4.422,8.093,8.378h11.491v-3.183C62.008,38.051,58.946,35,55.17,35z M32.008,35.951c4.42,0,8-3.579,8-8v-6.668c0-4.416-3.58-8-8-8c-4.418,0-8,3.584-8,8v6.668 C24.008,32.372,27.59,35.951,32.008,35.951z M49.026,48.515V53h-34v-4.485C15.026,42.706,19.71,38,25.487,38h13.078 C44.343,38,49.026,42.706,49.026,48.515z" />
    </svg>
  </SvgIcon>
);
