import { Colours, CommonStyles } from '../../../resources';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colours.white,
    minHeight: '100vh',
  },
  separator: {
    backgroundColor: Colours.darkBlack,
    width: '90%',
    height: '2px',
    marginTop: '50px',
  },
  titleContainer: {
    width: '90vw',
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    ...CommonStyles.h1,
    width: '90vw',
    textTransform: 'uppercase',
    marginTop: '5vmin',
    fontSize: '7vmin',
  },
  subtitle: {
    ...CommonStyles.h2,
    width: '90vw',
    marginTop: '1.5vmin',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '5vmin',
  },
  description: {
    width: '90vw',
    marginTop: 0,
    color: Colours.darkBlack,
    fontSize: '4vmin',
    lineHeight: '8vmin',
  },
  imageContainer: {
    ...CommonStyles.flexCenter,
  },
  img: {
    width: '90vw',
    height: '45vmin',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    marginTop: '3vmin',
  },
  videoWrapper: {
    marginTop: '3vmin',
    maxHeight: '320px',
  },
  video: {
    width: '100%',
    height: 'auto',
  },
  modalVideoContainer: {
    ...CommonStyles.flexCenter,
    // width: isLandscapeMode() ? '60vw' : '80vw',
    height: '50vh',
    // marginTop: '20vh',
    // marginLeft: isLandscapeMode() ? '20vw' : '10vw',
  },
  modalVideo: {
    // width: isLandscapeMode() ? '40vw' : '60vw',
    // height: '40vh',
    width: '100%',
    height: 'auto',
    maxHeight: '85vh',
  },
  bottomContainer: {
    ...CommonStyles.h3,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5vmin',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'fixed',
    right: '100px',
    top: '15vh',
    cursor: 'pointer',
  },
  iconClose: {
    fontSize: '5vmin',
    fontWeight: 'regular',
  },
  button: CommonStyles.button,
  galleryContainer: {
    width: '100%',
  },
  galleryImage: {
    cursor: 'pointer',
    margin: '0 auto',
    maxHeight: 201,
    width: 'auto',
    height: 'auto',
  },
  galleryImageWrapper: {
    backgroundColor: 'transparent',
    maxHeight: 201,
  },
  arrow: {
    position: 'absolute',
    marginTop: -150,
  },
  iconArrow: {
    color: '#c6c6c6',
    fontSize: '4vmin',
    cursor: 'pointer',
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  dotWrapper: {
    display: 'inline-block',
  },
  iconDot: {
    color: '#c6c6c6',
    fontSize: 15,
    cursor: 'pointer',
  },
  activeDot: {
    color: Colours.primary,
  },
  arrowLeft: {
    left: '8vmin',
  },
  arrowRight: {
    right: '8vmin',
  },

  modalContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalIconArrow: {
    color: 'white',
    fontSize: '3vmax',
    cursor: 'pointer',
  },
  modalImg: {
    flex: 1,
    width: '80vw',
    height: '60vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: Colours.modalGray,
  },
  modalVid: {
    flex: 1,
    width: 'auto',
    height: 'auto',
    maxWidth: '80vw',
    maxHeight: '80vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: Colours.modalGray,
  },
  modalCloseContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  modalIconClose: {
    fontSize: '3vmax',
    fontWeight: 'regular',
    color: 'white',
  },
};
