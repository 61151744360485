import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64.001 64">
      <rect style={{ fill: 'none' }} d="M0 0H64.001V64H0z" />
      <path d="M58.001,15h-38v-1c0-1.1-0.9-2-2-2h-7c-1.1,0-2,0.9-2,2v1h-3c-1.1,0-2,0.9-2,2v32c0,1.1,0.9,2,2,2h52 c1.1,0,2-0.9,2-2V17C60.001,15.9,59.101,15,58.001,15 M57.001,48h-50V18h50V48z M31.999,46.054c7.182,0,13.002-5.82,13.002-12.997 c0-7.185-5.82-13.003-13.002-13.003c-7.18,0-12.998,5.817-12.998,13.003C19.001,40.233,24.819,46.054,31.999,46.054 M31.999,23.054 c5.516,0,10.002,4.487,10.002,10.003c0,5.512-4.486,9.997-10.002,9.997c-5.514,0-9.998-4.485-9.998-9.997 C22.001,27.541,26.485,23.054,31.999,23.054 M37.001,33c0-2.758-2.243-5.002-5-5.002v-2c3.859,0,7,3.141,7,7.002H37.001 M11.301,23 c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h6c0.275,0,0.5,0.225,0.5,0.5v2c0,0.275-0.225,0.5-0.5,0.5H11.301" />
    </svg>
  </SvgIcon>
);
