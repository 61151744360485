import React from 'react';
import i18n from 'i18n-js';
import { SVG, Colours } from '../../../resources';
import { isLandscapeMode, valueIsDefined } from '../../../lib/utils';

const styles = {
  container: {
    width: isLandscapeMode() ? '60vw' : '90vw',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '5vmin',
  },
  row: {
    display: 'flex',
    width: isLandscapeMode() ? '60vw' : '90vw',
    flexDirection: 'row',
    height: '5vmin',
  },
  itemHeader: {
    fontSize: isLandscapeMode() ? '2vmin' : '3vmin',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  menuIcon: {
    width: '4vmin',
    height: '4vmin',
  },
  userIcon: {
    width: '4vmin',
    height: '4vmin',
  },
  list: {
    marginTop: '3vmin',
    width: isLandscapeMode() ? '60vw' : '90vw',
    height: isLandscapeMode() ? '60vmin' : '80vmin',
    overflow: 'auto',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: Colours.whiteGray,
    borderStyle: 'solid',
  },
  dataItem: {
    fontSize: isLandscapeMode() ? '2vmin' : '3.5vmin',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
  },
  noDataTitle: {
    marginTop: '3vmin',
    height: isLandscapeMode() ? '6vmin' : '11vmin',
    fontSize: '2vmin',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colours.background,
    color: Colours.darkBlack,
  },
};
export default ({ data }) => {
  // console.log({ data });
  let hasData = data && data.rows && data.rows.length > 0;

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={{ ...styles.itemHeader, flex: 0.6 }}>
          <SVG.MenuBar style={styles.menuIcon} />
        </div>
        <div style={{ ...styles.itemHeader, flex: 1 }}>{i18n.t('nick.name')}</div>
        {hasData && valueIsDefined(data, 'JumpValue') && <div style={{ ...styles.itemHeader, flex: 1.2 }}>Jump</div>}
      </div>
      {hasData ? (
        <div style={styles.list}>
          {data.rows.map((item, index) => {
            let backgroundColor = index % 2 === 0 ? Colours.background : Colours.white;
            let color = Colours.darkBlack;
            if (data.me === item.Nickname) {
              color = Colours.white;
              backgroundColor = Colours.primary;
            }
            return (
              <div style={{ ...styles.dataRow, backgroundColor, color, borderTopWidth: index === 0 ? 1 : 0 }} key={`data-${index}`}>
                <div style={{ ...styles.dataItem, flex: 0.6 }}>{item.Position}</div>
                <div style={{ ...styles.dataItem, flex: 1 }}>{item.Nickname}</div>
                {valueIsDefined(data, 'JumpValue') && <div style={{ ...styles.dataItem, flex: 1.2 }}>{item.JumpValue}</div>}
              </div>
            );
          })}
        </div>
      ) : (
        <div style={styles.noDataTitle}>No Data Yet</div>
      )}
    </div>
  );
};
