import { call, put } from 'redux-saga/effects';
import { getChart as apiGetChart, getJumperRankingInfo, getJumperRanking } from '../../lib/api';
import { refreshOauth } from './auth';
import ChartActions from '../chart';
import AuthActions from '../auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getChart({ auth }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetChart, authRefreshed);
      if (!ret.error) {
        yield put(ChartActions.getChartSuccess(ret));
      } else {
        yield put(ChartActions.requestFailed('getChart'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
      const retJumper = yield call(getJumperRankingInfo, authRefreshed);
      const jumperData = yield call(getJumperRanking, authRefreshed, retJumper.Year, retJumper.Month);
      const myValues = jumperData.rows.find(element => {
        return parseInt(element.self) === 1 && jumperData.me === element.Nickname;
      });
      if (!retJumper.error) {
        yield put(ChartActions.getJumperRankingSuccess(retJumper, myValues.Points));
      } else {
        yield put(ChartActions.requestFailed('getJumperRanking'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(ChartActions.requestFailed('getChart'));
  }
}
