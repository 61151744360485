import React from 'react';
import clsx from 'clsx';

import { SVG } from '../../../../../../resources';

import styles from './index.module.scss';

const Heart = ({ onLike, item, liked, likesCount }) => (
  <div className={styles.container} onClick={() => onLike(item)}>
    {liked ? <SVG.HeartFilledNew className={clsx([styles.icon, styles.active])} /> : <SVG.HeartNew className={styles.icon} />}
    <div className={styles.counter}>{likesCount}</div>
  </div>
);

export default Heart;
