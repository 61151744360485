import { Colours } from '../../../resources';

export default {
  container: {
    position: 'relative',
  },
  slider: {
    display: 'flex',
    justifyContent: 'flex-start',
    transition: 'all 0.2s ease-in-out',
  },
  imgContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 5,
  },
  labelContainer: {
    marginTop: '3vmin',
    marginBottom: '3vmin',
    width: '40vw',
    marginLeft: '30vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '3vmin',
    color: Colours.darkBlack,
  },
  iconArrow: {
    width: '5vmin',
    height: '5vmin',
    marginLeft: '3vmin',
    marginRight: '3vmin',
    fill: Colours.darkBlack,
  },
};
