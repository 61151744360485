import { call, put, select } from 'redux-saga/effects';
import { getQuiz as apiGetQuiz, postQuizAnswer as apiPostQuizAnswer } from '../../lib/api';
import QuizActions from '../quiz';
import { refreshOauth } from './auth';

import { convertQuiz } from '../../converters/quiz';

import { getAuth } from '../selector/auth';

import { DATA_STATUSES } from '../../constants/common';

export function* getQuiz(quizId) {
  try {
    yield put(QuizActions.setStatus(DATA_STATUSES.loading));
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const data = yield call(apiGetQuiz, authRefreshed);
      if (!data.error) {
        if (data && data.length > 0) {
          if (data.length === 1) {
            yield put(QuizActions.saveQuiz(convertQuiz(data[0])));
            yield put(QuizActions.setStatus(DATA_STATUSES.loaded));
          } else {
            let selectedQuizId = quizId.quizId;
            // console.log('selected quizId: ' + selectedQuizId);
            let selectedQuiz = data.find(quiz => quiz.id === selectedQuizId);
            yield put(QuizActions.saveQuiz(convertQuiz(selectedQuiz)));
            yield put(QuizActions.setStatus(DATA_STATUSES.loaded));
          }
        }
      } else {
        if (data.error && data.error.response && data.error.response.status >= 400) {
          yield put(QuizActions.setStatus(DATA_STATUSES.loadingError));
          yield put(QuizActions.setMessage(data.error.response.data.inner_error_code));
        }
      }
    }
  } catch (err) {
    console.log(err);
    yield put(QuizActions.setStatus(DATA_STATUSES.loadingError));
  }
}

export function* postQuizAnswer({ payload }) {
  try {
    const { currentQuiz, currentAnswerCorrect } = payload;
    // console.log(currentQuiz, currentAnswerCorrect);
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      let answer = {
        id: authRefreshed.id,
        quiz_id: currentQuiz.quiz_id,
        correct: currentAnswerCorrect ? 1 : 0,
      };
      yield call(apiPostQuizAnswer, { auth: authRefreshed, answer });
    }
  } catch (err) {
    console.log(err);
  }
}
