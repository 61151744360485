export default {
  black: '#000',
  background: '#F4F4F4',
  darkPrimary: '#ab1555',
  primary: '#cb0069',
  lightPrimary: '#E20074',
  secondary: '#c8a353',
  tertiary: '#52b9f1',
  whiteCyan: 'rgba(255, 255, 255, 0.3)',
  darkCyan: '#4a80aa',
  green: '#6bb324',
  slideBackground: '#74758e',
  slideBar: '#c1bbbb',
  silverRank: '#BFBABA',
  gold: '#C9A256',
  bronce: '#FF9A1E',
  yellow: '#f1d019',
  coinBackground: '#FFE056',
  coinBorder: '#ffd92d',
  coinBar: '#FFBA49',
  darkBlack: '#393939',
  offBlack: '#141414',
  gray: '#888',
  silver: '#A4A4A4',
  wetAsphalt: '#4B4B4B',
  lightGray: '#a49faf',
  middleGray: '#c6c6c6',
  lightTelegray: '#D0D0D0',
  brownGray: '#383838',
  whiteGray: '#DDD',
  darkGrayText: '#38393a',
  gainsboro: '#E1E1E1',
  whiteBackground: '#ededed',
  white: '#FFF',
  gradientColor: ['#d8d8d8', 'rgba(200, 200, 200, 0.05)'],
  red: '#F00',
};
