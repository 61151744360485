import { Colours } from '../../../../resources';

export default {
  modalContent: {
    width: '80vw',
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    display: 'flex',
    // width: '100vw',
    height: '30vh',
    backgroundColor: 'white',
    flexDirection: 'column',
    position: 'relative',
  },
  img: {
    flex: 1,
    width: '100%',
    height: '50vh',
    minHeight: '30vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: Colours.black,
  },
  video: {
    flex: 1,
    width: '100%',
    height: '50vh',
    minHeight: '30vh',
    backgroundColor: Colours.black,
  },
  detailContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colours.white,
    position: 'relative',
    height: '50vh',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#efefef',
    padding: '5px 15px',
    right: 0,
    backgroundColor: Colours.whiteBackground,
  },
  title: {
    color: Colours.modalGray,
    fontSize: 19,
    fontWeight: 600,
  },
  date: {
    marginTop: 3,
    color: '#6b6b6b',
    fontSize: 15,
  },
  comments: {
    flex: 1,
    overflowX: 'hidden',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
  },
  likeText: {
    color: Colours.modalGray,
    fontSize: 16,
  },
  comment: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 6,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
    color: '#999',
    fontSize: 14,
    lineHeight: 18,
    minHeight: 56,
    alignItems: 'center',
    backgroundColor: Colours.whiteBackground,
  },
  commentInput: {
    display: 'flex',
    flex: 1,
    color: '#262626',
    fontSize: 'inherit',
    height: 40,
    maxHeight: 80,
    outline: 0,
    border: '1px solid #c1bbbb',
    borderRadius: 2,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 40,
    marginLeft: 20,
    color: 'white',
    backgroundColor: Colours.primary,
    borderRadius: 3,
    cursor: 'pointer',
  },
  iconArrow: {
    color: 'white',
    fontSize: '3vmax',
    cursor: 'pointer',
  },
  arrow: {
    display: 'none',
  },
  innerArrow: {
    position: 'absolute',
    top: 'calc(50% - 2rem)',
    zIndex: 100,
    padding: '8px',
    display: 'flex',
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    display: 'none',
  },
  iconClose: {
    fontSize: '3vmax',
    fontWeight: 'regular',
    color: 'white',
  },
  icon: {
    width: 25,
    height: 25,
    color: Colours.modalGray,
  },
};
