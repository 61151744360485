import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <path d="M44,9.46A1.5,1.5,0,0,1,45,12L25.08,32,45,51.9a1.5,1.5,0,0,1-2,2.2L42.9,54l-21-21a1.51,1.51,0,0,1,0-2.12l21-21A1.5,1.5,0,0,1,44,9.46Z" />
      </g>
    </svg>
  </SvgIcon>
);
