import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path d="M56.002,55h-47V8l3,0L8.01,4l-4,3.999l3,0v49h48.992l0,3l4-4l-4-4L56.002,55z M57.002,32 c-2.206,0-4,1.794-4,4c0,0.322,0.048,0.631,0.121,0.932l-7.983,5.7C44.52,42.237,43.79,42,43.002,42 c-1.189,0-2.246,0.531-2.98,1.357l-10.039-3.545c-0.1-2.117-1.84-3.812-3.981-3.812c-2.119,0-3.841,1.66-3.974,3.746l-11.026,3.676 v3.162l11.978-3.993C23.714,43.446,24.789,44,26.002,44c1.19,0,2.248-0.532,2.981-1.359l10.038,3.544 C39.119,48.303,40.86,50,43.002,50c2.205,0,4-1.794,4-4c0-0.321-0.048-0.628-0.119-0.927l7.986-5.702 C55.487,39.764,56.216,40,57.002,40c2.205,0,4-1.794,4-4S59.207,32,57.002,32z M26.002,42c-1.103,0-2-0.897-2-2s0.897-2,2-2 c1.103,0,2,0.897,2,2S27.105,42,26.002,42z M43.002,48c-1.103,0-2-0.897-2-2s0.897-2,2-2c1.103,0,2,0.897,2,2S44.105,48,43.002,48z M57.002,38c-1.103,0-2-0.897-2-2s0.897-2,2-2c1.103,0,2,0.897,2,2S58.105,38,57.002,38z M11.002,30.823V34.6l11.982-9.167 C23.578,25.785,24.263,26,25.002,26c0.913,0,1.746-0.32,2.42-0.837l10.639,6.258c-0.028,0.191-0.059,0.381-0.059,0.579 c0,2.206,1.794,4,4,4c2.205,0,4-1.794,4-4c0-0.553-0.113-1.08-0.317-1.56l9.76-9.756C55.923,20.887,56.45,21,57.002,21 c2.205,0,4-1.794,4-4s-1.795-4-4-4c-2.206,0-4,1.794-4,4c0,0.555,0.114,1.084,0.32,1.565l-9.757,9.754 C43.084,28.114,42.556,28,42.002,28c-0.913,0-1.746,0.319-2.42,0.836l-10.639-6.258c0.028-0.19,0.059-0.38,0.059-0.578 c0-2.206-1.795-4-4-4c-2.206,0-4,1.794-4,4c0,0.366,0.065,0.714,0.158,1.051L11.002,30.823z M57.002,15c1.103,0,2,0.898,2,2 s-0.897,2-2,2c-1.103,0-2-0.897-2-2S55.899,15,57.002,15z M42.002,30c1.103,0,2,0.897,2,2s-0.897,2-2,2c-1.103,0-2-0.897-2-2 S40.899,30,42.002,30z M25.002,20c1.103,0,2,0.897,2,2s-0.897,2-2,2c-1.103,0-2-0.897-2-2S23.899,20,25.002,20z" />
    </svg>
  </SvgIcon>
);
