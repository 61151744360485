import React from 'react';
import styles from './styles';
import Toggle from '../Toogle';

const GDPRToggle = ({
  onChange,
  value,
  title,
  fullText,
  shortText,
  showFullText,
  iconOn,
  iconOff,
  fullBtnText,
  shortBtnText,
  onClick,
  disabled,
  onLabel,
  offLabel,
  divider,
}) => {
  return (
    <React.Fragment>
      <div style={{ ...styles.contentWrapper, pointerEvents: disabled ? 'none' : 'auto' }}>
        <div style={styles.leftSide}>
          <div style={styles.imageContairer}>
            <img src={value ? iconOn : iconOff} style={styles.image} alt="" />
          </div>
          <div style={styles.textContainer}>
            <span style={styles.label}>{title}</span>
            <div style={styles.description}>{showFullText ? fullText : shortText}</div>
            <div style={styles.moreBtn} onClick={onClick}>
              {showFullText ? fullBtnText : shortBtnText}
            </div>
          </div>
        </div>
        <div style={styles.toggleWrapper}>
          <Toggle onChange={onChange} value={value} disabled={disabled} onLabel={onLabel} offLabel={offLabel} />
        </div>
      </div>
      {divider && <div style={styles.divider} />}
    </React.Fragment>
  );
};

export default GDPRToggle;
