import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon viewBox="0 0 75.71 37.21" {...props}>
    <svg width="80" height="40" viewBox="0 0 80 40">
      <g id="Marke">
        <path d="M0,24.65V17.11H7.53v7.54Z" />
        <path d="M23,24.65V17.11h7.54v7.54Z" />
        <path d="M30.14,0H.37L0,13.13l2,.34Q2.53,7.66,5,4.86a9.58,9.58,0,0,1,7.26-3.07V29.25c0,2.4-.34,4-1,4.67a4.73,4.73,0,0,1-3,1.13c-.42,0-1.13.05-2.15.05v2.11H24.44V35.1c-1,0-1.73,0-2.16-.05a4.72,4.72,0,0,1-3-1.13c-.7-.71-1-2.27-1-4.67V1.79a9.59,9.59,0,0,1,7.26,3.07c1.66,1.87,2.68,4.74,3,8.61l2-.34Z" />
      </g>
    </svg>
  </SvgIcon>
);
