import { createSelector } from 'reselect';

const auth = state => state.auth;

export const getAuth = createSelector(auth, auth => auth);

export const getUserId = createSelector(auth, auth => auth.id);

export const getUserCountryCode = createSelector(auth, auth => auth.countryCode);

export const getUserLanguageCode = createSelector(auth, auth => auth.languageCode);
