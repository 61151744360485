import { createSelector } from 'reselect';

const auth$ = state => state.auth;
export const authSelector = createSelector(auth$, auth => ({
  auth,
}));

const social$ = state => state.social;
export const socialSelector = createSelector(social$, social => ({
  ...social,
}));

const totalCount$ = state => state.total_count;
export const totalCountSelector = createSelector(totalCount$, total_count => ({
  ...total_count,
}));

const news$ = state => state.news;
export const newsSelector = createSelector(news$, news => ({
  ...news,
}));

const chart$ = state => state.chart;
export const chartSelector = createSelector(chart$, chart => ({
  ...chart,
}));

const node$ = state => state.node;
export const nodeSelector = createSelector(node$, node => ({
  ...node,
}));

const elearning$ = state => state.elearning;
export const elearningSelector = createSelector(elearning$, elearning => ({
  ...elearning,
}));

const table$ = state => state.table;
export const tableSelector = createSelector(table$, (monthRanking, yearRanking, accountStatement, jumperRanking) => ({
  ...monthRanking,
  ...yearRanking,
  ...jumperRanking,
  ...accountStatement,
}));

const singlePage$ = state => state.singlePage;
export const singlePageSelector = createSelector(singlePage$, singlePage => ({
  ...singlePage,
}));

const userSettings$ = state => state.userSettings;
export const userSettingsSelector = createSelector(userSettings$, userSettings => ({
  userSettings,
}));
