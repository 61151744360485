import { createReducer, createActions } from 'reduxsauce';

import { DATA_STATUSES } from '../constants/common';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getNotifications: [],
  saveNotifications: ['payload'],
  markNotification: ['payload'],
  seeNotifications: [],
  updateNotificationsById: ['payload'],
  updateStatus: ['payload'],
  requestFailed: [],
  clear: [],
});

export const NotificationsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  status: DATA_STATUSES.initial,
};

/* ------------- Reducers ------------- */
const saveNotifications = (state, { payload }) => ({
  ...state,
  data: {
    byId: payload.byId,
    allIds: payload.allIds,
  },
  status: DATA_STATUSES.loaded,
});

const markNotification = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    byId: {
      ...state.data.byId,
      [payload.uuid]: {
        ...state.data.byId[payload.uuid],
        isRead: true,
      },
    },
  },
});

const updateNotificationsById = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    byId: payload,
  },
});

const updateStatus = (state, { payload }) => ({ ...state, status: payload });

const failure = (state, { error }) => ({ ...state, error, status: DATA_STATUSES.error });
const clear = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const notificationsReducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_FAILED]: failure,
  [Types.SAVE_NOTIFICATIONS]: saveNotifications,
  [Types.MARK_NOTIFICATION]: markNotification,
  [Types.UPDATE_NOTIFICATIONS_BY_ID]: updateNotificationsById,
  [Types.UPDATE_STATUS]: updateStatus,
  [Types.CLEAR]: clear,
});
