import React from 'react';

import HashTagText from './HashTagText';
import DotButton from './DotButton';
import PersonalEditButtons from './PersonalEditButtons';

import styles from './styles';

const Header = ({ item, isOwner, openEditDialog, openDeleteDialog, openReportDialog }) => {
  const renderRightButton = () => {
    switch (isOwner) {
      case false:
        return <DotButton onPress={openReportDialog} />;

      case true:
        return <PersonalEditButtons onDelete={openDeleteDialog} onEdit={openEditDialog} />;

      default:
        return <DotButton onPress={openReportDialog} />;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftBlock}>
        <div style={styles.title}>
          <HashTagText text={item.title} hashTagStyle={styles.hashTag} />
        </div>
      </div>
      {renderRightButton()}
    </div>
  );
};

export default Header;
