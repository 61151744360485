import React from 'react';

export const createQueryParams = (data = {}) => {
  const keyValue = Object.entries(data);

  const onlyValid = keyValue.filter(element => element[1]);

  const keyValueObj = Object.fromEntries(onlyValid);

  const query = new URLSearchParams(keyValueObj);

  return query;
};

/**
 * Mark label with italic text.
 * @param {string} title
 * @returns Label with emphasized text.
 */
export const tagsMarker = title => {
  const styles = {
    titleTags: {
      fontWeight: 'normal',
    },
  };

  let splitTitle = `${title}`.split(' ');
  if (splitTitle.filter(t => `${t}`.charAt(0) === '#').length > 0) {
    return (
      <label>
        {splitTitle.map((words, index) => {
          return `${words}`.charAt(0) === '#' ? (
            <em style={styles.titleTags} key={index}>
              {words} &nbsp;
            </em>
          ) : (
            words + ' '
          );
        })}
      </label>
    );
  } else {
    return <label>{title}</label>;
  }
};

export const findLength = obj => {
  if (!obj) {
    return false;
  }
  return Object.keys(obj).length > 0;
};
