import i18n from 'i18n-js';
import { getLocale } from '../../lib/api';
import { localStorage } from '../../containers/BaseComponent';

export const getLocalInformation = () => {
  const codes = navigator.language.split('-');
  let languageCode = '';
  let countryCode = '';
  if (codes.length === 1) {
    languageCode = countryCode = codes[0];
  } else if (codes.length === 2) {
    languageCode = codes[0];
    countryCode = codes[1];
  }
  return { languageCode, countryCode };
};
export const getLocalData = async (callback, auth) => {
  let languageCode = '';
  const userLocalInfo = getLocalInformation();

  if (auth && auth.languageCode) {
    languageCode = auth.languageCode;
    localStorage.setItem('userLanguageCode', languageCode);
  } else {
    languageCode = localStorage.getItem('userLanguageCode');
    if (languageCode === null || languageCode === '') {
      languageCode = userLocalInfo.languageCode;
    }
  }
  const data = await getLocale(languageCode, auth && auth.countryCode ? auth.countryCode : userLocalInfo.countryCode);
  if (!data.error) {
    i18n.locale = languageCode;
    i18n.translations[languageCode] = { ...data };
  }
  callback && callback();
};
