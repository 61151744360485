import React from 'react';
import StackGrid from 'react-stack-grid';
import { CardWithImageButton, ReloadContainer } from '../../../components';
import { isMobile } from '../../../lib/utils';
import { CommonStyles } from '../../../resources';

export default ({ data, onPress, loading, listRef }) => {
  if (Array.isArray(data)) {
    return (
      <ReloadContainer loading={loading} listRef={listRef}>
        <div style={CommonStyles.gridContainer}>
          <StackGrid columnWidth={isMobile() ? '80%' : 380}>
            {data.map((value, index) => {
              return (
                <div key={index} style={CommonStyles.flexCenter}>
                  <CardWithImageButton onPress={() => onPress(value)} info={value} />
                </div>
              );
            })}
          </StackGrid>
        </div>
      </ReloadContainer>
    );
  }
  return <div />;
};
