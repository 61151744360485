import { Colours } from '../../../resources';

export default {
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  leftSide: {
    display: 'flex',
    paddingRight: '50px',
  },
  imageContairer: {
    marginRight: '25px',
  },
  image: {
    width: '70px',
  },
  textContainer: {
    textAlign: 'left',
  },
  label: {
    fontSize: '21px',
    textDecoration: 'none',
    cursor: 'text',
  },
  description: {
    padding: '5px 0',
  },
  moreBtn: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  toggleWrapper: {
    paddingTop: '25px',
  },
  divider: {
    backgroundColor: Colours.whiteGray,
    height: 1,
    marginTop: 30,
  },
};
