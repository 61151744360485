import { Colours } from '../../../resources';

export default {
  container: {
    overflow: 'auto',
  },
  comment: {
    flex: 1,
    display: 'flex',
    margin: '0 10px',
    flexDirection: 'column',
    borderBottomColor: '#cfcfcf',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
  },
  left: {
    flex: 1,
    padding: 10,
    paddingTop: 5,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    color: Colours.modalGray,
    fontSize: 18,
    fontWeight: 600,
  },
  date: {
    color: '#6b6b6b',
    fontSize: 20,
    padding: 3,
  },
  text: {
    flex: 1,
    alignSelf: 'center',
    color: '#6b6b6b',
    fontSize: 15,
    wordBreak: 'break-all',
  },
  like: {
    color: Colours.modalGray,
    fontSize: 15,
    fontWeight: 'bold',
  },
  icon: {
    width: 20,
    height: 20,
    color: Colours.modalGray,
  },
  postedOn: {
    position: 'absolute',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
  },
  iconClock: {
    color: '#c6c6c6',
    fontSize: '20px',
  },
};
