import React, { useEffect, useState } from 'react';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

import styles from './index.module.scss';
import { socialSelector } from '../../../../redux/selector';
import { connect } from 'react-redux';

const MediaCard = ({ item, openDetails, closeEdit, onReport, onLike, onEdit, onDelete, auth, filter }) => {
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [reportDialog, setReportDialog] = useState(false);
  // SHOULD BE CALCULATED IN CONVERTERS
  const liked = item.votes;
  const likesCount = item.vote_count;
  const commented = item.comments;
  const commentCount = item.comment_count;
  const isOwner = item.owner_uuid === auth.id;
  const externalLink = item.external_link;

  useEffect(() => {
    setEditDialog(closeEdit);
  }, [closeEdit]);

  useEffect(() => {
    setEditDialog(false);
  }, []);

  useEffect(() => {
    setEditDialog(false);
    setReportDialog(false);
    setDeleteDialog(false);
  }, [filter]);

  const openEditDialog = () => {
    console.log('openEditDialog');
    setDeleteDialog(false);
    setEditDialog(true);
  };

  const openDeleteDialog = () => {
    console.log('openDeleteDialog');
    setEditDialog(false);
    setDeleteDialog(true);
  };

  const openReportDialog = () => {
    console.log('openReportDialog');
    setReportDialog(true);
  };

  const closeEditDialog = () => {
    console.log('closeEditDialog');
    setEditDialog(false);
  };

  const closeDeleteDialog = () => {
    console.log('closeDeleteDialog');
    setDeleteDialog(false);
  };

  const closeReportDialog = () => {
    console.log('closeReportDialog');
    setReportDialog(false);
  };

  const onReportPost = () => {
    console.log('onReportPost');
    onReport(item);
    setReportDialog(false);
  };

  const onEditPost = values => {
    onEdit({ item, values });
    closeEditDialog();
  };

  const onDeletePost = () => {
    console.log('onDeletePost');
    onDelete(item);
    setDeleteDialog(false);
  };

  return (
    <div className={styles.container} key={item.uuid}>
      <Header
        {...{
          item,
          isOwner,
          openEditDialog,
          openDeleteDialog,
          openReportDialog,
        }}
      />

      <Content
        {...{
          item,
          externalLink,
          openDetails,
          editDialog,
          deleteDialog,
          reportDialog,
          onReportPost,
          onEditPost,
          onDeletePost,
          closeEditDialog,
          closeDeleteDialog,
          closeReportDialog,
        }}
      />

      <Footer
        {...{
          item,
          liked,
          likesCount,
          commented,
          commentCount,
          externalLink,
          onComment: openDetails,
          onLike,
        }}
      />
    </div>
  );
};
const mapStateToProps = state => ({
  ...socialSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MediaCard);
