import React from 'react';

import Bar from '../../../../components/Common/Bar';

import styles from './styles.module.scss';

// const year_my_preys_points = 400;
// const year_my_points = 350;
// const year_my_chaser_points = 100;

// const year_my_preys_nick = 'Mandalorian boy';
// const year_my_nick = 'Nick Pavlovich';
// const year_my_chaser_nick = 'Jhon Smit';

// const year_my_preys_rank = 7;

const AnnualRanking = ({ charts }) => (
  <div className={styles.container}>
    <div className={styles.darkBackground} />
    {charts.year_my_preys_nick && charts.year_my_preys_points && (
      <Bar
        maxValue={charts.year_top_points}
        name={charts.year_my_preys_nick}
        score={charts.year_my_preys_points}
        rank={charts.year_my_preys_rank}
        transparent
      />
    )}
    <Bar
      maxValue={charts.year_top_points}
      name={`${charts.year_my_nick}`}
      score={charts.year_my_points}
      rank={charts.year_my_preys_rank + 1}
      lineColor={'pink'}
      main
    />
    <Bar
      maxValue={charts.year_top_points}
      name={charts.year_my_chaser_nick}
      score={charts.year_my_chaser_points}
      rank={charts.year_my_preys_rank + 2}
      transparent
    />

    {/* <Bar maxValue={year_my_preys_points} name={year_my_preys_nick} score={year_my_preys_points} rank={year_my_preys_rank} transparent />
    <Bar
      maxValue={year_my_preys_rank > 0 ? year_my_preys_points : year_my_points}
      name={year_my_nick}
      score={year_my_points}
      rank={year_my_preys_rank + 1}
      lineColor={'pink'}
      main
    />
    <Bar
      maxValue={year_my_preys_rank > 0 ? year_my_preys_points : year_my_points}
      name={year_my_chaser_nick}
      score={year_my_chaser_points}
      rank={year_my_preys_rank + 2}
      transparent
    /> */}
  </div>
);

export default AnnualRanking;
