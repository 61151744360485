/* eslint react/style-prop-object: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 798.79 357.05"
      style="enable-background:new 0 0 798.79 357.05;"
      xmlSpace="preserve"
      {...props}>
      <g>
        <path
          fill="#FFF"
          d="M80.3,240.2c0,25.8,11.6,41.5,31.1,41.5c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20
		c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8
		C91.9,198.7,80.3,214.4,80.3,240.2L80.3,240.2L80.3,240.2z M157.4,280.5h4.8v-80.6h-4.8V280.5L157.4,280.5z M229.9,280.5h5.8
		L212.5,246c11.8-0.6,22.5-7.5,22.5-23c0-16.2-11.6-23-24-23h-25.2v80.6h4.8V246h16.2L229.9,280.5L229.9,280.5z M190.7,204.5h19.9
		c9.8,0,19.4,4.9,19.4,18.5s-9.7,18.5-19.4,18.5h-19.9V204.5L190.7,204.5z M249.1,240.2c0,25.8,11.6,41.5,31.1,41.5
		c14.7,0,25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20c-16.5,0-26.1-13.6-26.1-36.7s9.5-36.7,26.1-36.7
		c11.7,0,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8C260.6,198.7,249.1,214.4,249.1,240.2L249.1,240.2L249.1,240.2z
		 M326.1,280.5h40v-4.6H331v-76h-4.8V280.5L326.1,280.5z M423.8,204.5v-4.6h-42.3v80.6h42.3v-4.6h-37.5v-34.5h36.3v-4.6h-36.3v-32.3
		H423.8L423.8,204.5z"
        />
        <path
          fill="#FFF"
          d="M168.6,79.3l-13,55.4h-0.7l-13-55.4h-18.3l-12.9,55.4h-0.7L96.7,79.3H79.9l21.3,80.6h17.6l13.4-54.6h0.7
		l13.4,54.6H164l21.3-80.6H168.6L168.6,79.3z M195,159.8h16.3V79.3H195V159.8L195,159.8z M273.9,79.3v50.7h-0.7l-29-50.7h-16.5v80.6
		h16.3v-50.4h0.7l28.8,50.4H290V79.3H273.9L273.9,79.3z M352.6,79.3v50.7h-0.7l-29-50.7h-16.5v80.6h16.3v-50.4h0.7l28.8,50.4h16.5
		V79.3H352.6L352.6,79.3z M432.6,94.3V79.3h-47.4v80.6h47.4v-15.1h-31.1v-18.4h29.9v-15.1h-29.9v-17H432.6L432.6,94.3z M486.4,159.8
		h18.1l-17-31.1c9.8-3.3,16.2-11.6,16.2-23.9c0-16.3-11.1-25.6-26.8-25.6h-29.8v80.6h16.3v-29.4h7.8L486.4,159.8L486.4,159.8z
		 M463.4,94.3H476c6.4,0,10.7,3.7,10.7,10.5c0,6.8-4.3,10.5-10.7,10.5h-12.7V94.3L463.4,94.3z M540.8,161.7c15.2,0,27.1-9,27.1-25.2
		c0-11.7-7.5-20.6-19.4-23.8l-9.3-2.5c-4.5-1.2-8.2-3.3-8.2-8.7s4.2-8.8,9.8-8.8c6.3,0,9.7,3,10.7,9h15.9
		c-1.6-14.8-10.4-24.2-26.7-24.2c-15,0-26.4,9.8-26.4,25.1c0,9.9,5.1,19.2,18,22.7l9.3,2.7c6.3,1.7,9.7,4.2,9.7,9.5
		c0,5.2-3.5,9.1-10.5,9.1c-5.9,0-10.4-2.7-11.8-9.4h-16.2C514.3,153,524.2,161.7,540.8,161.7L540.8,161.7L540.8,161.7z M578.3,79.3
		v18.6h8.9c-0.4,7.1-2,10.7-8.8,11.1v8.6c13.4-0.6,18.4-8.7,18.4-23V79.3H578.3L578.3,79.3z"
        />
      </g>
      <g>
        <path
          fill="#FFF"
          d="M476.9,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H476c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H476.9z"
        />
        <path
          fill="#FFF"
          d="M540.2,251.5v-23.4c0-19.9,11.7-30.2,26.6-30.2c14.9,0,26.6,10.4,26.6,30.2v23.4c0,19.9-11.7,30.2-26.6,30.2
		C551.9,281.7,540.2,271.3,540.2,251.5z M588.5,251.3v-23.2c0-16.9-9-25.7-21.7-25.7c-12.7,0-21.7,8.8-21.7,25.7v23.2
		c0,16.9,9,25.7,21.7,25.7C579.5,277.1,588.5,268.3,588.5,251.3z"
        />
        <path
          fill="#FFF"
          d="M609.8,280.5v-6.3l30.5-29.4c5.5-5.4,11.2-11.8,11.2-22.9c0-11.8-7.7-19.4-18.4-19.4
		c-11.6,0-17.9,6.2-19.3,16.6H609c1.5-11.7,9.1-21.2,24.1-21.2c13.3,0,23.3,9.6,23.3,24c0,11.5-5.1,19-13,26.6l-28.2,26.8v0.6h41.6
		v4.6H609.8z"
        />
        <path
          fill="#FFF"
          d="M711.9,255.1h10v4.6h-10v20.7H707v-20.7h-40.8v-6.6l41.2-54.1h4.5V255.1z M671,255.1h36v-46.7h-0.7L671,254.5
		V255.1z"
        />
      </g>
    </svg>
  </SvgIcon>
);
