import React from 'react';
import i18n from 'i18n-js';

import styles from './styles.module.scss';

const Header = () => (
  <div className={styles.container}>
    <div color="inherit" className={styles.title}>
      {i18n.t('annualRanking.name')}
    </div>

    <div className={styles.description}>{i18n.t('annualRankingCopy.name')}</div>
  </div>
);

export default Header;
