import { createSelector } from 'reselect';

const social = state => state.social;

const filter = state => state.social.filter;

export const getPosts = createSelector(social, social => social.socials);

export const getFilterSearch = createSelector(filter, filter => filter.search);

export const getFilterCountry = createSelector(filter, filter => filter.country);

export const getFilterOwner = createSelector(filter, filter => filter.owner);
