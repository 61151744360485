export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 60,
  },

  leftBlock: {
    display: 'flex',
    flex: 1,
    marginLeft: 20,
    marginTop: 8,
    marginBottom: 10,
    marginRight: 40,
  },

  title: {
    height: 42,
    color: '#38393a',
    fontSize: 17,
    fontFamily: 'TeleNeoWeb',
    fontWeight: '700',
    overflow: 'hidden',
  },

  hashTag: {
    color: '#38393a',
    fontSize: 17,
    fontFamily: 'TeleNeoWeb',
    fontWeight: '700',
  },
};
