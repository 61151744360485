import { createSelector } from 'reselect';

export const selectNotificationsById = state => state.notifications.data.byId;

export const selectNotificationsByIdArray = state => Object.values(state.notifications.data.byId);

export const selectNotificationsAllIds = state => state.notifications.data.allIds;

export const selectNotificationsStatus = state => state.notifications.status;

export const selectNotificationsInOrder = createSelector([selectNotificationsById, selectNotificationsAllIds], (items, ids) =>
  ids.map(item => items[item]),
);

export const getUnseenCount = createSelector([selectNotificationsByIdArray], items =>
  items.reduce((result, item) => (!item.isSeen ? result + 1 : result), 0),
);
