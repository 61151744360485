import { Colours } from '../../../../../resources';

export default {
  comment: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem 1rem 1rem',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
    color: '#999',
    fontSize: 14,
    lineHeight: '18px',
    alignItems: 'center',
    backgroundColor: Colours.whiteGray,
  },
  commentTop: {
    width: '100%',
    margin: '10px 0',
    fontWeight: 'bold',
    color: Colours.darkBlack,
  },
  commentBtm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentInput: {
    color: '#262626',
    fontSize: 'inherit',
    height: 38,
    maxHeight: 80,
    border: `1px solid ${Colours.darkGrayText}`,
    borderRadius: '5px',
    paddingLeft: '5px',
    width: '80%',
    // gridColumn: 'span 3 / span 3'
  },
  buttonText: {
    fontWeight: 'bold',
    padding: '5px 10px',
    backgroundColor: Colours.primary,
    color: Colours.white,
    minWidth: 'auto',
    width: '15%',
  },
};
