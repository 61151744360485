const hostName = typeof window !== 'undefined' ? window.location.hostname : '';

const oauthServer = 'https://backend.winners-circle.net';
const clientSecret = 'dfd39cb47a33b58839975f1812df2e640f1ee912c21858c545a7f8d5f367af1d';
const socketServer = 'https://wcg.dom.de';
const apiServer = `https://${hostName}`;
const mediaApiServer = 'https://wcb.dom.de';
const redirectServer = 'https://www.winners-circle.net';
const unpublishedHash = 'soiughe9gfwq09mn2e8gfm2h9vn';
const unpublishedToken = 'asgfih98zt92hguwfgiuz7t2gf';

export default {
  oauthServer,
  socketServer,
  API_URL: apiServer,
  MEDIA_API_URL: mediaApiServer,
  productionMode: true,
  unpublishedHash,
  unpublishedToken,
  oauth: {
    authorizeUrl: `${oauthServer}/oauth/authorize`,
    tokenUrl: `${oauthServer}/templates/DT_HandlerOAuth2/GetAccessTokenHandler.ashx`,
    refreshTokenUrl: `${oauthServer}/templates/DT_HandlerOAuth2/GetAccessTokenHandler.ashx`,
    clientId: '580aacf31637f79639ccf89f85461e9782d26248f6b6fb85b49065fa88da9ca8',
    clientSecret,
    redirectUri: `${redirectServer}/callback`,
    revokeTokenUrl: `${oauthServer}/templates/DT_HandlerOAuth2/RevokeAccessTokenHandler.ashx`,
  },
};
