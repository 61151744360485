import { call, put } from 'redux-saga/effects';
import {
  getMonthRanking as apiGetMonthRanking,
  getJumperRanking as apiGetJumperRanking,
  getYearRanking as apiGetYearRanking,
  getAccountStatement as apiGetAccountStatement,
  getSinglePage as apiGetSinglePage,
} from '../../lib/api';
import TableActions from '../table';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getMonthRanking({ auth, month, year }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetMonthRanking, authRefreshed, month, year);
      const singlePage = yield call(apiGetSinglePage, authRefreshed, '/monthlyRankingInfo');
      if (!singlePage.error) {
        yield put(TableActions.getMonthlySinglePageSuccess(singlePage[0].body));
      }
      if (!ret.error) {
        yield put(TableActions.getMonthRankingSuccess(ret));
      } else {
        if (ret.error.response && ret.error.response.data.message === 'Data not found.') {
          yield put(TableActions.getMonthRankingSuccess({ month, year }));
        } else {
          yield put(TableActions.requestMonthRankingFailed());
          if (ret.error.response.status === 403) {
            if (getNavigation().location.pathname !== '/') {
              localStorage.setItem('lastPath', getNavigation().location.pathname);
            }
            yield put(AuthActions.signOut());
          }
        }
      }
    }
  } catch (err) {
    yield put(TableActions.requestMonthRankingFailed());
  }
}

export function* getJumperRanking({ auth, month, year }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetJumperRanking, authRefreshed, year, month);
      const singlePage = yield call(apiGetSinglePage, authRefreshed, '/jumperRankingInfo');
      if (!singlePage.error) {
        yield put(TableActions.getJumperSinglePageSuccess(singlePage[0].body));
      }
      if (!ret.error) {
        yield put(TableActions.getJumperRankingSuccess(ret));
      } else {
        if (ret.message === 'Data not found.') {
          yield put(TableActions.getJumperRankingSuccess({ month, year }));
        } else {
          yield put(TableActions.requestJumperRankingFailed());
          if (ret.status === 403) {
            if (getNavigation().location.pathname !== '/') {
              localStorage.setItem('lastPath', getNavigation().location.pathname);
            }
            yield put(AuthActions.signOut());
          }
        }
      }
    }
  } catch (err) {
    yield put(TableActions.requestJumperRankingFailed());
  }
}

export function* getYearRanking({ auth, year }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetYearRanking, authRefreshed, year);
      const singlePage = yield call(apiGetSinglePage, authRefreshed, '/yearlyRankingInfo');
      if (!singlePage.error) {
        yield put(TableActions.getYearlySinglePageSuccess(singlePage[0].body));
      }
      if (!ret.error) {
        yield put(TableActions.getYearRankingSuccess(ret));
      } else {
        if (ret.message === 'Data not found.') {
          yield put(TableActions.getYearRankingSuccess({ year }));
        } else {
          yield put(TableActions.requestYearRankingFailed());
          if (ret.status === 403) {
            if (getNavigation().location.pathname !== '/') {
              localStorage.setItem('lastPath', getNavigation().location.pathname);
            }
            yield put(AuthActions.signOut());
          }
        }
      }
    }
  } catch (err) {
    yield put(TableActions.requestYearRankingFailed());
  }
}

export function* getAccountStatement({ auth }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetAccountStatement, authRefreshed);
      const singlePage = yield call(apiGetSinglePage, authRefreshed, '/favoriteProductInfo');
      if (!singlePage.error) {
        yield put(TableActions.getFavoriteProductSinglePageSuccess(singlePage[0].body));
      }
      if (!ret.error) {
        yield put(TableActions.getAccountStatementSuccess(ret));
      } else {
        yield put(TableActions.requestAccountStatementFailed());
        if (ret.status === 403) {
          if (getNavigation().location.pathname !== '/') {
            localStorage.setItem('lastPath', getNavigation().location.pathname);
          }
          yield put(AuthActions.signOut());
        }
      }
    }
  } catch (err) {
    yield put(TableActions.requestAccountStatementFailed());
  }
}
