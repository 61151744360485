import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M60,38V11a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V53a2,2,0,0,0,2,2H43V41a3,3,0,0,1,3-3ZM20.28,29.06h-2l-4.45-8.85v8.85H11.94v-12h2L18.44,26v-8.9h1.84Zm9.7,0H22.65v-12h7.23v1.7H24.63V22h4.86v1.7H24.63v3.66H30Zm11.55,0h-2L37.6,19.79l-1.92,9.27h-2L31,17.06h2l1.72,9.27,1.92-9.27h2l1.89,9.29,1.76-9.29h2ZM45,25.39H47.1a2.92,2.92,0,0,0,.37,1.44,1.82,1.82,0,0,0,1.65.9A1.8,1.8,0,0,0,50,24.35a16.71,16.71,0,0,0-2-.9,5.84,5.84,0,0,1-1.89-1.19A3,3,0,0,1,45.3,20a3,3,0,0,1,1.09-2.41,3.69,3.69,0,0,1,2.46-.81A3.64,3.64,0,0,1,52,18.16a3.8,3.8,0,0,1,.69,2.08H50.71a2.16,2.16,0,0,0-.37-1.14,1.76,1.76,0,0,0-1.49-.61,1.72,1.72,0,0,0-1.14.35,1.43,1.43,0,0,0-.5,1.15,1.6,1.6,0,0,0,.62,1.28,6.49,6.49,0,0,0,1.64.86,15.29,15.29,0,0,1,1.58.7,3.39,3.39,0,0,1,2,3.08,3.15,3.15,0,0,1-1,2.41,4.05,4.05,0,0,1-2.9,1,3.84,3.84,0,0,1-3.21-1.41A4.4,4.4,0,0,1,45,25.39ZM60,40H46a1,1,0,0,0-1,1V55h0Z" />
    </svg>
  </SvgIcon>
);
