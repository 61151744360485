import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getNodeDetail: ['auth', 'location', 'showPublished', 'query'],
  getNodeDetailSuccess: ['nodeDetail'],
  requestFailed: [],
  clear: [],
});

export const NodeType = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  nodeDetail: null,
  fetchNode: false,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state, fetchNode: true, nodeDetail: null });
const getNodeDetailSuccess = (state, { nodeDetail }) => ({ ...state, fetchNode: false, nodeDetail });
const failure = (state, { error }) => ({ ...state, fetchNode: false, error, nodeDetail: null });
const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const nodeReducer = createReducer(defaultState, {
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_NODE_DETAIL]: request,
  [Types.GET_NODE_DETAIL_SUCCESS]: getNodeDetailSuccess,
  [Types.CLEAR]: clear,
});
