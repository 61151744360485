import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M38,53v1.1a6,6,0,0,1-12,0V53ZM32,7a1.813,1.813,0,0,1,2,2V12.15l2.281.563c6.135,1.515,11.174,7.534,12.253,14.639l.011.073.015.073L52.72,48H11.282l4.159-20.5.015-.073.011-.073c1.077-7.093,6-12.9,12.253-14.438L30,12.351V9.2A1.969,1.969,0,0,1,32,7m0-3a4.957,4.957,0,0,0-5,5.2V10c-7.7,1.9-13.3,9-14.5,16.9L8.2,48.1l-.2.8A1.867,1.867,0,0,0,10,51H54a1.867,1.867,0,0,0,2-2.1l-.2-.8L51.5,26.9C50.3,19,44.7,11.7,37,9.8V9a4.79,4.79,0,0,0-5-5Z" />
    </svg>
  </SvgIcon>
);
