import axios from 'axios';
import Config from '../../config';
import { buildURL } from '../utils';

const { API_URL, MEDIA_API_URL, unpublishedHash } = Config;

// REFACTOR
export async function request(url, method, parameters, auth, usingWebAPI = true, customHeaders = {}, disableAuth = false, usingBackendAPI = false) {
  let response = {},
    queryDomain = usingBackendAPI ? MEDIA_API_URL : API_URL,
    queryURL = usingWebAPI ? `${queryDomain}/api/web/${url}` : `${queryDomain}/${url}`,
    params = parameters,
    headers = {
      Accept: 'application/json',
      'Content-Security-Policy': 'default-src https',
      'Strict-Transport-Security': 'max-age=63072000',
      'X-Content-Type-Options': 'nosniff',
      'X-XSS-Protection': '1; mode=block',
      ...customHeaders,
    };

  queryURL = queryURL.replace('&language=en', '');
  try {
    if (auth && auth.token && !disableAuth) {
      headers.authorization = auth.token;
      headers.access_token = auth.token;
      headers.id = auth.id;
      if (auth.csrfToken) {
        headers['X-CSRF-Token'] = auth.csrfToken;
      }
    }

    switch (method.toUpperCase()) {
      case 'POST':
        response = await axios.post(queryURL, params, { headers: headers });
        break;
      case 'PUT':
        response = await axios.put(queryURL, params, { headers: headers });
        break;
      case 'PATCH':
        response = await axios.patch(queryURL, params, { headers: headers });
        break;
      case 'DELETE':
        response = await axios.delete(queryURL, { headers: headers, data: params });
        break;
      case 'GET':
        response = await axios.get(queryURL, { headers: headers });
        break;
      default:
        response = await axios.get(queryURL, { headers: headers });
        break;
    }

    if (response.status >= 400) {
      response.error = true;
    }
    return response.data;
  } catch (err) {
    console.error(`api err:${queryURL}, param: ${JSON.stringify(params)}`, err);
    return { error: err };
  }
}

export async function requestData(url, method, parameters, auth, usingWebAPI, extParams = {}, customHeaders = {}) {
  let queryParams = {
    _format: 'json',
    uid: auth.id,
    id: auth.id,
    language: auth.languageCode,
    user_country: auth.countryCode,
    LanguageCode: auth.languageCode,
    CountryCode: auth.countryCode,
    ...extParams,
  };
  return request(buildURL(url, queryParams), method, parameters, auth, usingWebAPI, customHeaders);
}

export async function getSocial({ auth, offset: _offset, limit: _limit, search, country, owner: owner_uuid, uuid, single = false }) {
  let data = null;
  if (single) {
    data = { uuid };
  } else {
    data = {
      _offset,
      _limit,
      search,
      country,
      owner_uuid,
      uuid,
      country_op: !owner_uuid && !country ? 'empty' : null,
    };
  }

  return request('api/wici-media', 'PUT', data, auth, false, {}, false);
}

export async function getSocialCount({ auth, search, country, owner: owner_uuid, uuid }) {
  let countParams = {
    search,
    country,
    owner_uuid,
    uuid,
    country_op: !owner_uuid && !country ? 'empty' : null,
  };
  return await request('api/wici-media-count', 'POST', countParams, auth, false, {}, false);
}

export async function getNews(auth, _offset, _limit, showPublished) {
  const data = {
    _offset,
    _limit,
    language: auth.languageCode,
    alias: '',
  };
  if (!showPublished) {
    let headers = {
      hash: unpublishedHash,
    };
    return request('api/unpublished/articles', 'POST', data, auth, false, headers);
  }
  return request('articles', 'POST', data, auth, true);
}

export async function getChart(auth) {
  const data = {
    uid: auth.id,
  };
  return request('api/column1', 'POST', data, auth, false);
}

export async function getJumperRanking(auth, year, month) {
  const data = {
    id: auth.id,
    year,
    month,
  };
  return request('api/tables/jumper-ranking', 'POST', data, auth, false);
}

export async function getJumperRankingInfo(auth) {
  const data = {
    id: auth.id,
  };
  return request('api/tables/jumper-info-ranking', 'POST', data, auth, false);
}

export async function getFactSheets(auth) {
  const data = {
    language: auth.languageCode,
  };
  return request('api/app/fact-sheets', 'POST', data, auth, false);
}

export async function getSpecificFactSheet({ auth, uuid }) {
  const data = {
    language: auth.languageCode,
  };
  return request(`api/web/node/fact_sheets/${uuid}`, 'POST', data, auth, false, { id: auth.uuid });
}

export async function getFactSheetSets({ auth }) {
  return request('api/web/fact-sheet-sets', 'GET', false, auth, false);
}

export async function getSpecificFactSheetSets({ auth, id }) {
  return request(`api/web/fact-sheet-sets?id=${id}`, 'GET', false, auth, false);
}

export async function getNotifications({ auth, id, uuid, channelId }) {
  return request('api/dom-notifications', 'POST', { id, uuid, channel_id: channelId }, auth, false);
}

export async function markNotification({ auth, id, uuid, channelId }) {
  return request('api/dom-notifications-read', 'POST', { id, uuid, channel_id: channelId }, auth, false);
}

export async function seeNotifications({ auth, id, uuid, channelId }) {
  return request('api/dom-notifications-seen', 'POST', { id, uuid, channel_id: channelId }, auth, false);
}

export async function getAllChannels({ auth }) {
  return request('api/dom-notifications/channels', 'POST', {}, auth, false);
}

export async function muteChannel({ auth, channelId, status }) {
  return request('api/dom-notifications/channel-mute', 'POST', { channel: channelId, mute: status }, auth, false);
}

export async function getQuiz(auth) {
  const data = {
    language: auth.languageCode,
  };
  return request('quiz', 'POST', data, auth, true);
}

export async function postQuizAnswer({ auth, answer }) {
  return requestData('quiz/answer', 'POST', answer, auth);
}

export async function getButtonsContent(auth) {
  const data = {
    language: auth.languageCode,
    id: auth.id,
  };
  return request('api/buttons', 'POST', data, auth, false);
}

export async function getNodeDetail(auth, location, showPublished, query) {
  let data = {
    language: auth.languageCode,
    ...query,
  };
  if (location.substr(0, 4) === 'news') {
    if (!showPublished) {
      const headers = { hash: unpublishedHash };
      return request('api/unpublished/articles?', 'POST', data, auth, false, headers);
    } else {
      data = {
        ...data,
        alias: location.substring(4),
      };
      return request('api/web/articles', 'POST', data, auth, false);
    }
  } else {
    if (!showPublished) {
      const headers = { hash: unpublishedHash };
      return request(`api/${location}`, 'POST', data, auth, false, headers);
    } else {
      return request(`api/${location}`, 'POST', data, auth, false);
    }
  }
}

export async function getMonthRanking(auth, month, year) {
  const data = {
    id: auth.id,
    year,
    month: month ? month : '',
  };
  return request('api/tables/month-ranking', 'POST', data, auth, false);
}

export async function getYearRanking(auth, year) {
  const data = {
    id: auth.id,
    year,
  };
  return request('api/tables/year-ranking', 'POST', data, auth, false);
}

export async function getAccountStatement(auth) {
  const data = {
    id: auth.id,
  };
  return request('api/tables/account-statement', 'POST', data, auth, false);
}

export function getMenuItems(auth) {
  const body = {
    menu_id: 'column-5',
    language: auth.languageCode,
  };
  return request('api/get-menu-attributes', 'POST', body, auth, false);
}

export async function getContactUrl(auth) {
  const data = {
    user_country: auth.countryCode,
  };
  return request('api/get-contact-link', 'POST', data, auth, false);
}

export async function revokeToken(auth) {
  return request('api/user/revoke', 'POST', {}, auth, false);
}

export async function getSinglePage(auth, alias) {
  const data = {
    language: auth.languageCode,
    alias,
  };
  return request('api/web/simple_pages', 'POST', data, auth, false);
}

export async function login(auth) {
  await logout(auth);
  const data = {
    LanguageCode: auth.languageCode,
    CountryCode: auth.countryCode,
    name: 'apiconsumer',
    pass: 'apiconsumer',
  };
  return request('user/login?_format=json', 'POST', data, auth, false);
}

export async function logout(auth) {
  const data = {
    name: 'apiconsumer',
    pass: 'apiconsumer',
  };
  return request('user/logout?token=random_string', 'POST', data, auth, false);
}

export async function sendTokenInformation(auth) {
  const data = {
    id: auth.id,
    LanguageCode: auth.languageCode,
    CountryCode: auth.countryCode,
    Type: auth.type,
    uuid: auth.uuid,
    nickname: auth.nickname,
    channelId: auth.channelId,
  };
  return request('api/edenred/user?_format=json', 'POST', data, auth, false);
}

export async function reportItem(auth, content) {
  const data = {
    id: auth.id,
    uuid: content.uuid,
  };
  return request('api/report/content?_format=json', 'POST', data, auth, false);
}

export async function getLocale(languageCode, countryCode) {
  const data = {
    language: languageCode,
    user_country: countryCode,
  };
  return request('api/locale-file', 'POST', data, null, false);
}

export function getWinnerPageAuthUrl(url, auth) {
  return `${url}&token_type=bearer&id=${auth.id}&access_token=${auth.token}`;
}

export async function getSocialComment(auth, mediaId) {
  const data = {
    id: auth.id,
    uuid: mediaId,
  };
  return request('api/get/media/comment', 'POST', data, auth, false);
}

export async function likeMedia(auth, mediaId) {
  const data = {
    id: auth.id,
    uuid: mediaId,
  };
  return request('api/add/media/like', 'POST', data, auth, false);
}

export async function updateMedia({ auth, uuid, values }) {
  let formdata = new FormData();

  formdata.append('uuid', uuid);
  formdata.append('title', values.title);
  formdata.append('external_link', values.url);

  return request('api/wici-media', 'POST', formdata, auth, false, {}, false, true);
}

export async function сreatePost({ auth, data }) {
  let formdata = new FormData();

  formdata.append('id', auth.id);
  formdata.append('token', auth.token);
  formdata.append('file', data.file);
  formdata.append('type', data.type);
  formdata.append('country', data.local ? auth.countryCode : '');
  formdata.append('external_link', data.externalLink);
  formdata.append('title', data.title);

  return request('api/wici-media', 'POST', formdata, auth, false, {}, false, true);
}

export async function deleteMedia({ auth, uuid }) {
  const data = {
    uuid,
  };

  return request(
    'api/wici-media',
    'DELETE',
    data,
    auth,
    false,
    {
      'Content-Type': 'application/json',
    },
    false,
  );
}

export async function postComment(auth, mediaId, body) {
  const data = {
    id: auth.id,
    uuid: mediaId,
    body,
    subject: '',
  };
  return request('api/add/media/comment', 'POST', data, auth, false);
}

export async function likeComment(auth, commentId) {
  const data = {
    id: auth.id,
    uuid: commentId,
  };
  return request('api/add/comment/like', 'POST', data, auth, false);
}

export async function getSocialByID(auth, uuid) {
  return request('api/wici-media', 'PUT', { uuid }, auth, false, {}, false);
}

export async function getElearningItems(auth) {
  const data = {
    language: auth.languageCode,
    alias: '',
  };
  return request('api/web/etraining-items', 'POST', data, auth, false);
}

export async function getUserSettings(auth) {
  return request('api/get/client', 'GET', {}, auth, false);
}

export async function postUserSettings(auth, data) {
  return request('api/set/client', 'POST', data, auth, false);
}

export async function getUserMembershipType(auth) {
  return request(`api/users/${auth.id}`, 'GET', false, auth, false);
}
