import React from 'react';
import ImageSliderHoc from './ImageSliderHoc';
// import { SVG } from '../../../resources';
import styles from './styles';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      currentPosition: 0,
      interval: null,
      visibleItems: this.props.visibleItems,
    };
  }

  _handleLeft = () => {
    const currentPosition = this.updatePosition(this.state.currentPosition - 1);
    this.setState({ currentPosition });
  };

  _handleRight = () => {
    const currentPosition = this.updatePosition(this.state.currentPosition + 1);
    this.setState({ currentPosition });
  };

  sliderStyle() {
    const itemWidth = window.innerWidth / this.props.visibleItems + 10;
    const shift = itemWidth * this.state.currentPosition;
    return { transform: `translateX(-${shift}px)` };
  }

  updatePosition = nextPosition => {
    const { visibleItems, currentPosition } = this.state;
    const skipScrollIfEnd = this.props.calculator.skipScrollIfEnd(visibleItems, currentPosition, nextPosition);
    const skipScrollIfNonInfinite = this.props.calculator.skipScrollIfNonInfinite(visibleItems, currentPosition, nextPosition);
    const scrollIfInfinite = this.props.calculator.scrollIfInfinite(visibleItems, currentPosition, nextPosition);
    const scrollToBeginningIfEnd = this.props.calculator.scrollToBeginningIfEnd(visibleItems, currentPosition, nextPosition);
    if (skipScrollIfEnd !== undefined) {
      return skipScrollIfEnd;
    }
    if (skipScrollIfNonInfinite !== undefined) {
      return skipScrollIfNonInfinite;
    }
    if (scrollIfInfinite !== undefined) {
      return scrollIfInfinite;
    }
    if (scrollToBeginningIfEnd !== undefined) {
      return scrollToBeginningIfEnd;
    }
    return nextPosition;
  };

  render() {
    const sliderStyle = this.sliderStyle();
    return (
      <div style={styles.container}>
        <div style={{ ...styles.slider, ...sliderStyle }}>
          {this.props.children.map((item, key) => (
            <div className={item} key={key}>
              <div style={styles.imgContainer}>{item}</div>
            </div>
          ))}
        </div>
        <div style={styles.labelContainer}>
          {/* <div onClick={this._handleLeft}>*/}
          {/* <SVG.LeftArrow style={styles.iconArrow} />*/}
          {/* </div>*/}
          Bild {this.state.currentPosition + this.state.visibleItems} von {this.props.children.length}
          {/* <div onClick={this._handleRight}>*/}
          {/* <SVG.RightArrow style={styles.iconArrow} />*/}
          {/* </div>*/}
        </div>
      </div>
    );
  }
}

export default ImageSliderHoc(Slider);
