import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M61.771,11.208l-8.5-8.5c-0.8-0.8-2.1-0.8-2.8,0l-40,40l11.3,11.3l40-40 C62.571,13.208,62.571,11.908,61.771,11.208z M7.472,45.708l11.3,11.3L4.748,61.884c-0.204,0.082-0.42,0.124-0.637,0.124 c-0.216,0-0.433-0.042-0.637-0.124c-0.203-0.083-0.394-0.207-0.559-0.372c-0.166-0.165-0.29-0.356-0.373-0.561 c-0.082-0.203-0.123-0.42-0.123-0.637c0-0.216,0.041-0.433,0.123-0.636L7.472,45.708z" />
    </svg>
  </SvgIcon>
);
