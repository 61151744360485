import React from 'react';
import i18n from 'i18n-js';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';

import styles from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required(),
  url: Yup.string(),
});

const EditDialog = ({ initialValues, onEditPost, closeEditDialog }) => (
  <Formik initialValues={initialValues} validationSchema={VALIDATION_SCHEMA} validateOnMount onSubmit={onEditPost}>
    {({ values, isValid, handleChange, setFieldTouched, handleSubmit }) => (
      <div className={styles.container}>
        <div className={styles.title}>{i18n.t('EditOwnPostHeadline.name')}</div>

        <div className={styles.inputs}>
          <div className={clsx([styles.inputContainer, styles.inputContainerGap])}>
            <div className={styles.label}>{i18n.t('EditOwnPostFieldNameTitle.name')}</div>
            <input
              className={styles.input}
              placeholder={i18n.t('EditOwnPostFieldNameTitle.name')}
              value={values.title}
              onChange={handleChange('title')}
              onBlur={() => {
                setFieldTouched('title');
              }}
            />
          </div>

          <div className={styles.inputContainer}>
            <div className={styles.label}>{i18n.t('EditOwnPostFieldNameURL.name')}</div>
            <textarea
              rows={3}
              className={clsx([styles.input, styles.textField])}
              placeholder={i18n.t('EditOwnPostFieldNameURL.name')}
              value={values.url}
              onChange={handleChange('url')}
              onBlur={() => {
                setFieldTouched('url');
              }}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <div className={styles.button} onClick={closeEditDialog}>
            <div className={styles.buttonText}>{i18n.t('EditOwnPostSelectOption1.name')}</div>
          </div>

          <div className={styles.buttonsGap} />

          <div className={styles.button} onClick={handleSubmit} disabled={!isValid}>
            <div className={clsx([styles.buttonText, isValid ? null : styles.disabled])}>{i18n.t('EditOwnPostSelectOption2.name')}</div>
          </div>
        </div>
      </div>
    )}
  </Formik>
);

export default EditDialog;
