import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M45.501,52h-36c-0.271,0-0.5-0.229-0.5-0.5v-39c0-0.271,0.229-0.5,0.5-0.5h36c0.829,0,1.5-0.672,1.5-1.5	S46.33,9,45.501,9h-36c-1.93,0-3.5,1.57-3.5,3.5v39c0,1.93,1.57,3.5,3.5,3.5h36c0.829,0,1.5-0.672,1.5-1.5S46.33,52,45.501,52z M58.001,32l-12-12l0,10.5h-24c-0.829,0-1.5,0.672-1.5,1.5c0,0.828,0.671,1.5,1.5,1.5h24l0,10.5L58.001,32z" />
    </svg>
  </SvgIcon>
);
