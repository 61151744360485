export const convertQuiz = data => {
  const {
    id,
    uuid,
    description,
    image,
    label_text,
    overall_failure_feedback,
    overall_success_feedback,
    is_samsung_quiz,
    path,
    quizes,
    subtitle,
    target_url,
    title,
  } = data;

  return {
    id,
    uuid,
    description,
    image,
    label: label_text,
    failureFeedback: overall_failure_feedback,
    successFeedback: overall_success_feedback,
    isReversalQuiz: is_samsung_quiz === '1',
    path,
    quizes,
    subtitle,
    targetUrl: target_url,
    title,
  };
};
