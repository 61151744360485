export const convertChannel = data => {
  const { name, label, allow_mute, base, notify, subscribed } = data;

  return {
    name,
    label,
    allowMute: allow_mute,
    base,
    notify,
    subscribed,
  };
};

export const convertNotificationChannels = data => {
  let byId = {};
  let allIds = [];

  data.forEach(item => {
    const { name } = item;

    allIds.push(name);

    byId = {
      ...byId,
      [name]: convertChannel(item),
    };
  });

  return { byId, allIds };
};
