import React, { Component } from 'react';
import Modal from 'react-modal';
// import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import styles from './styles';

export default class SocialLikedDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  render() {
    const {
      // socials,
      // activeItem,
      isOpen,
      onClose,
    } = this.props;
    // let likes = activeItem < socials.length ? socials[activeItem].votes : [];
    return (
      <Modal
        isOpen={isOpen}
        style={{
          overlay: {
            display: 'flex',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            display: 'flex',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
          },
        }}>
        <div style={styles.container}>
          <div>
            <div style={styles.header}>Likes</div>
            <div style={styles.closeContainer} onClick={onClose}>
              <Icon className="fa fa-times" style={styles.iconClose} />
            </div>
          </div>
          <div style={styles.content}>
            {/* {likes.map((like, index) => (
              <div key={index} style={styles.like}>
                <div style={styles.name}>{like.owner}</div>
                <div style={styles.username}>{moment(new Date(like.createdAt)).fromNow(true)}</div>
              </div>
            ))} */}
          </div>
        </div>
      </Modal>
    );
  }
}
