import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { Colours } from '../../resources';

class ColoredLinearProgress extends Component {
  render() {
    const { classes } = this.props;
    return <LinearProgress {...this.props} classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} />;
  }
}

const styles = () => ({
  colorPrimary: {
    backgroundColor: Colours.white,
  },
  barColorPrimary: {
    backgroundColor: Colours.primary,
  },
});

export default withStyles(styles)(ColoredLinearProgress);
