export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 20px',
    height: '100%',
    cursor: 'pointer',
  },

  icon: {
    width: 26,
    height: 26,
  },
};
