import React from 'react';
import { SVG } from '../../../../../resources';
import styles from './index.module.scss';

export default () => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <SVG.TLogo className={styles.logo} />
    </div>
  </div>
);
