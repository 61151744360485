import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Link } from '../../../components';
import { SVG, Colours, CommonStyles } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';
import { getWinnerPageAuthUrl } from '../../../lib/api';
import { trackScreen } from '../../../lib/matomo';
import { userSettingsSelector } from '../../../redux/selector';

const stylesLandscape = {
  bottom: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: Colours.darkBlack,
    paddingBottom: '2vh',
  },
  copyrightContainer: {
    marginTop: '3vmin',
    height: '8.5vmin',
    width: isLandscapeMode() ? '60vw' : '90vw',
    borderWidth: 0,
    borderTopWidth: 1,
    borderTopColor: Colours.gray,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoContainer: {
    marginTop: '5vmin',
    display: 'flex',
    width: isLandscapeMode() ? '60vw' : '90vw',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  iconLogo: {
    width: '9vmin',
    height: '4vmin',
    color: Colours.white,
  },
  iconLabel: {
    width: '18vmin',
    height: '2vmin',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2vmin',
    ...CommonStyles.h3,
    color: Colours.whiteGray,
  },
  textContainer1: {
    marginTop: '2vmin',
    ...CommonStyles.h3,
    fontSize: isLandscapeMode() ? '2.5vmin' : '3vmin',
    color: Colours.whiteGray,
  },
  label: {
    fontSize: isLandscapeMode() ? '2.5vmin' : '3vmin',
    marginLeft: '5vmin',
    color: Colours.whiteGray,
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

const stylesPortrait = {
  ...stylesLandscape,
  bottom: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: Colours.darkBlack,
  },
  copyrightContainer: {
    marginTop: '5vmin',
    height: '12vmin',
    width: '90vw',
    borderWidth: 0,
    borderTopWidth: 1,
    borderTopColor: Colours.gray,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const styles = isLandscapeMode() ? stylesLandscape : stylesPortrait;

class AppFooter extends Component {
  _handleTrackScreen = path => {
    if (this.props.userSettings.web_matomo) trackScreen(path);
  };

  render() {
    const { auth } = this.props;
    return (
      <div style={styles.bottom}>
        {isLandscapeMode() && (
          <div style={styles.logoContainer}>
            <SVG.TLogo style={styles.iconLogo} />
            <div
              style={{
                ...styles.iconLabel,
                backgroundImage: `url(${i18n.t('countryclaimHeader.image')})`,
              }}
            />
          </div>
        )}
        <div style={styles.copyrightContainer}>
          <div style={styles.textContainer1}>{i18n.t('copyright.name')}</div>
          <div style={styles.textContainer}>
            <Link
              style={styles.label}
              to={auth.contactUrl ? getWinnerPageAuthUrl(`/iframe?${auth.contactUrl}`, auth) : ''}
              onClick={() => this._handleTrackScreen(auth.contactUrl ? getWinnerPageAuthUrl(`/iframe?${auth.contactUrl}`, auth) : '')}>
              {i18n.t('contact.name')}
            </Link>
            <Link style={styles.label} to="/privacy" onClick={() => this._handleTrackScreen('/privacy')}>
              {i18n.t('privacy.name')}
            </Link>
            <Link style={styles.label} to="/imprint" onClick={() => this._handleTrackScreen('/imprint')}>
              {i18n.t('imprint.name')}
            </Link>
            <Link style={styles.label} to="/faqs" onClick={() => this._handleTrackScreen('/faqs')}>
              {i18n.t('faq.name')}
            </Link>
          </div>
        </div>
        <div style={{ height: '100px', backgroundColor: Colours.primary }} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...userSettingsSelector(state),
});

export default connect(mapStateToProps)(AppFooter);
