import React from 'react';

import '../../../components/App.css';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';

const ELearningMenu = ({ menuEntryList, eTrainingPath }) => {
  return (
    <div className={styles.eTrainingContainer}>
      <div className={styles.container}>
        <div className={styles.buttonsContainer}>
          <div className={styles.innerButtonsContainer}>
            <div className={styles.arrowContainer}></div>
            <div className={styles.arrowContainer}>
              <div className={styles.arrowup}></div>
            </div>
            <div className={styles.arrowContainer}></div>
          </div>
        </div>
      </div>
      <div className={styles.greyContainer}>
        <div className={styles.listContainer}>
          {menuEntryList.map(entry => {
            let linkUrl = eTrainingPath + '/' + entry.id;
            return (
              <div key={entry.title} className={styles.menuLinkWrapper}>
                <Link className={styles.menuLinks} to={{ pathname: linkUrl }}>
                  {'• ' + entry.title}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ELearningMenu;
