import { Colours } from '../../../resources';

export default {
  item: {
    width: 350,
    margin: 15,
    borderRadius: 5,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 9px 8px, rgba(0, 0, 0, 0.23) 0px 4px 8px',
  },
  padding: {
    height: 20,
    flex: 1,
  },
  img: {
    width: 350,
    height: 219,
    marginTop: 20,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    paddingBottom: 0,
    justifyContent: 'center',
  },
  title: {
    color: Colours.darkBlack,
    fontSize: 20,
    fontWeight: 'bold',
  },
  subTitle: {
    marginTop: 3,
    color: Colours.gray,
    fontSize: 17,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionContainer: {
    flex: 1,
    padding: 20,
  },
  description: {
    marginTop: 3,
    color: Colours.gray,
    fontSize: 15,
    overflow: 'hidden',
    maxHeight: 185,
    flexDirection: 'column',
  },
  button: {
    margin: 20,
    marginTop: 0,
    width: 310,
    background: Colours.primary,
    fontWeight: 'bold',
  },
  video: {
    width: 350,
    height: 240,
    marginTop: 20,
  },
};
