import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 17.75 9.93">
      <path d="M17.52.23a.7.7,0,0,0-1,0l-7.6,7.6L1.28.23A.72.72,0,0,0,.22.23a.73.73,0,0,0,0,1L8.88,9.93l8.64-8.66A.7.7,0,0,0,17.52.23Z" />
    </svg>
  </SvgIcon>
);
