import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Ebene_1" data-name="Ebene 1">
          <path d="M56.51,30h-37a1.5,1.5,0,0,0,0,3h37a1.5,1.5,0,0,0,0-3Zm-37-16h37a1.5,1.5,0,0,0,0-3h-37a1.5,1.5,0,0,0,0,3Zm37,35h-37a1.5,1.5,0,0,0,0,3h37a1.5,1.5,0,0,0,0-3ZM12,12.5a3,3,0,1,1-3-3A3,3,0,0,1,12,12.5Zm0,19a3,3,0,1,1-3-3H9A3,3,0,0,1,12,31.5Zm0,19a3,3,0,1,1-3-3A3,3,0,0,1,12,50.5Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
