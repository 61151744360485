import React, { useEffect, useState } from 'react';
import MessageIcon from '@material-ui/icons/Message';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { tagsMarker } from '../../../../../utils/URL';

export default function SocialDetailDialogAddComment(props) {
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(props.comment);
  }, [props.comment]);

  return (
    <div style={styles.comment}>
      <div style={styles.commentTop}>{props.title ? tagsMarker(props.title) : <></>}</div>
      <div style={styles.commentBtm}>
        <input style={styles.commentInput} value={comment} placeholder={props.placeholder} onChange={e => props.onChange(e.target.value)} />
        <Button variant="contained" style={styles.buttonText} onClick={$event => props.postCommet($event)}>
          {/* {i18n.t('addCommentSEND.name')} */}
          <MessageIcon />
        </Button>
      </div>
    </div>
  );
}
