import React from 'react';
import Icon from '@material-ui/core/Icon';
import { ColoredLinearProgress } from '../../../components';
import styles from './index.module.scss';

export default ({ quiz, nextTitle, progress, selectedAnswer, onAnswerSelected, onNextClick, quizRef }) => {
  // console.log({ isReversalQuiz });
  return (
    <div
      className={styles.container}
      ref={el => {
        quizRef(el);
      }}>
      <div className={styles.content}>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: quiz.question }} />
        <div className={styles.buttonsContainer}>
          {quiz.answers.map((item, index) => {
            const buttonMargin = index % 2 === 0 ? { marginRight: '2vmin', marginLeft: 0 } : { marginRight: 0, marginLeft: '2vmin' };
            const isSelectedAnswer = selectedAnswer === index;
            const buttonSelectedClass = isSelectedAnswer ? styles.buttonSelected : '';
            const textSelectedClass = isSelectedAnswer ? styles.textSelected : '';
            return (
              <div
                key={item.title + '-' + index}
                className={`${styles.button} ${buttonSelectedClass}`}
                style={{ ...buttonMargin }}
                onClick={() => onAnswerSelected(index)}>
                {isSelectedAnswer && <Icon className={`fa fa-check-square ${styles.icon} ${styles.iconSelected}`} />}
                {!isSelectedAnswer && <Icon className={`far fa-square ${styles.icon}`} />}
                <div className={`${styles.text} ${textSelectedClass}`} dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            );
          })}
        </div>
        <div className={styles.buttonNext} onClick={onNextClick}>
          <div className={styles.buttonNextText}>{nextTitle}</div>
        </div>
        <ColoredLinearProgress className={styles.progress} variant="determinate" value={progress} />
      </div>
    </div>
  );
};
