export const CONTENT_TYPE = {
  video: 'video',
  image: 'image',
  empty: 'empty',
};

export const MEDIA_FILTER = {
  allPosts: 'ALL_POSTS',
  myPosts: 'MY_POSTS',
  myCountry: 'MY_COUNTRY',
};

export const MEDIA_TYPE = {
  image: 'media--image',
  video: 'media--video',
};

export const DATA_STATUSES = {
  initial: 'INITIAL',
  loaded: 'LOADED',
  loading: 'LOADING',
  updated: 'UPDATED',
  updating: 'UPDATING',
  loadingError: 'LOADING ERROR',
  updatingError: 'UPDATING ERROR',
  error: 'ERROR',
};

export const NOTIFICATION_CHANNEL_ID = {
  general: 'general',
  news: 'news',
  article_country: 'article_country',
  socialWall: 'social_wall',
  socialWallCountry: 'social_wall_country',
  socialWallComments: 'social_wall_comments',
  socialWallLikes: 'social_wall_likes',
  elearning: 'elearning',
  localFactsheet: 'local_fact_sheet',
  elearningFactsheet: 'elearning_factsheet',
  localQuiz: 'local_quiz',
  elearningQuiz: 'elearning_quiz',
  elearningEtraining: 'elearning_etraining',
  winnerMonthlyQuiz: 'elearning_user_wins_sp',
  elearningUserWinsSp: 'elearning_user_wins_sp',
  winnerJumperRanking: 'chart_jumper_winner',
  social_global: 'social_wall_local_digest',
  social_local: 'social_wall_global_digest',
};

export const BROWSERS = {
  mozilla: 'Mozilla Firefox',
  samsung: 'Samsung Internet',
  opera: 'Opera',
  ie: 'Microsoft Internet Explorer',
  edge: 'Microsoft Edge',
  chrome: 'Google Chrome or Chromium',
  safari: 'Apple Safari',
  unknown: 'unknown',
};

// Club membership types - #33721
export const TROPHY_TYPE = {
  gold: 'Gold',
  bronze: 'Bronze',
  silver: 'Silver',
};

// Club membership details with navigation links - #33721
// Fix for issue not found on network throttle, use it as a optional if locale do not contains it - #34087
export const MEMBERSHIP_DETAILS = {
  Gold: {
    name: 'Shop Gold Members',
    link: '/iframe?https://shopstage.winners-circle.net/gold-member/?app=true&embedded=1',
    link_web: 'https://shopstage.winners-circle.net/gold-member/?embedded=1',
  },
  Silver: {
    name: 'Shop Silver Members',
    link: '/iframe?https://shopstage.winners-circle.net/silver-member/?app=true&embedded=1',
    link_web: 'https://shopstage.winners-circle.net/silver-member/?embedded=1',
  },
  Bronze: {
    name: 'Shop Bronze Members',
    link: '/iframe?https://shopstage.winners-circle.net/bronze-member/?app=true&embedded=1',
    link_web: 'https://shopstage.winners-circle.net/bronze-member/?embedded=1',
  },
};

// Navigation Prefixs
export const NAVIGATION_PREFIX = {
  internal: 'internal/:',
  shop: 'shop/:',
  game: 'game/:',
  iframe: 'iframe/:',
  http: 'http://',
  https: 'https://',
};

// Navigation URL Config
// export const NAVIGATION_URL = {
//   shop: config.shopUrl,
// };

export const NAVIGATION_QUERY = {
  overlayTitle: 'overlayTitle',
  id: 'id', // Auth.id
  token: 'token', // Auth.token
};

export const APP_STORAGE = {
  root: 'persist:root',
  lastPath: 'lastPath',
  webView: 'webView',
};

export const URL_REPLACEMENT = {
  userID: '$$USER_ID$$',
};

export const BINARY_PERFORMANCE = {
  ENABLE: 1,
  DISABLE: 0,
};
