import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg id="Ebene_1" data-name="Ebene 1" viewBox="0 0 64 64">
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          x1="32"
          y1="48.09"
          x2="32"
          y2="-7.91"
          gradientTransform="translate(0 11.91)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ffd329" />
          <stop offset="1" stopColor="#ffde5f" />
        </linearGradient>
      </defs>
      <title>QuizSuccess</title>
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path style={{ fill: 'url(#Unbenannter_Verlauf)' }} d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4Z" />
      <path
        style={{ fill: '#706259', opacity: 0.85, isolation: 'isolate' }}
        d="M32,49A20.89,20.89,0,0,1,13.27,38a1.5,1.5,0,1,1,2.65-1.41A17.9,17.9,0,0,0,32,46a17.9,17.9,0,0,0,16.09-9.43A1.5,1.5,0,0,1,50.73,38h0A20.9,20.9,0,0,1,32,49Z"
      />
      <path
        style={{ fill: '#706259', opacity: 0.85, isolation: 'isolate' }}
        d="M19,22a4,4,0,1,0,4,4A4,4,0,0,0,19,22Zm26,0a4,4,0,1,0,4,4A4,4,0,0,0,45,22Z"
      />
    </svg>
  </SvgIcon>
);
