import React from 'react';

import { SVG } from '../../../../../resources';

import styles from './index.module.scss';

export default ({ counter }) => {
  return (
    <div className={styles.container}>
      <SVG.Cup className={styles.cup} />
      <div className={styles.counter}>{counter}</div>
    </div>
  );
};
