import qs from 'qs';
import MobileDetect from 'mobile-detect';

export function buildURL(url, params) {
  if (params == null) return url;

  const serializedParams = qs.stringify(params);
  if (!serializedParams) return url;

  return `${url}${url.indexOf('?') < 0 ? '?' : '&'}${serializedParams}`;
}
export function isMobile() {
  if (typeof window !== 'undefined') {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
  }
}
let orientation = 0;
if (typeof window !== 'undefined') {
  orientation = window.orientation;
  const supportsOrientationChange = 'onorientationchange' in window;
  const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';

  window.addEventListener(
    orientationEvent,
    () => {
      orientation = window.orientation;
    },
    false,
  );
}

export function isLandscapeMode() {
  if (isMobile()) {
    return orientation % 180 !== 0;
  } else {
    if (typeof window !== 'undefined') {
      return window.innerWidth > window.innerHeight;
    }
    return true;
  }
}

export function isMSBrowser() {
  if (typeof window !== 'undefined') {
    let ua = window.navigator.userAgent;
    return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;
  }
  return false;
}

export function valueIsDefined(value, field) {
  let showColumn = false;
  let filteredRows = value.rows.filter(row => {
    return row[field] !== null && row[field] !== undefined && row[field] !== '' && row[field].toString() !== '0';
  });
  if (filteredRows.length > 0) {
    showColumn = true;
  }
  return showColumn;
}

export function parseURLSearch(str) {
  return Object.fromEntries(new URLSearchParams(str));
}

export function getRankingYears(yearsBack = 3) {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear - yearsBack; i <= currentYear; i++) {
    years.push(i.toString());
  }
  return years;
}
