import { Colours } from '../../resources';

export default {
  overlay: {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    borderWidth: 0,
  },
  contentInside: {
    backgroundColor: 'white',
    width: '640px',
    padding: '48px',
    fontFamily: 'TeleNeoWeb',
    fontWeight: 500,
    color: Colours.brownGray,
    lineHeight: '24px',
    fontSize: '18px',
  },
  link: {
    color: 'inherit',
  },
  commonLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};
