import { Colours, CommonStyles } from '../../../resources';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '76vh',
  },
  content: {
    width: '60vw',
    display: 'flex',
    flexDirection: 'row',
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    marginTop: '3vmin',
    color: Colours.darkBlack,
    fontSize: '2.5vmin',
    lineHeight: '3.5vmin',
  },
  imageArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '5vw',
  },
  img: {
    height: '70vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
  },
  closeContainer: {
    position: 'absolute',
    right: '10vw',
    top: '15vh',
    cursor: 'pointer',
  },
  iconClose: {
    fontSize: '5vmin',
    fontWeight: 'regular',
  },
  appstoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '7vmin',
    marginBottom: '5vmin',
  },
  buttonContainer: {
    cursor: 'pointer',
    flexDirection: 'row',
    ...CommonStyles.flexCenter,
    backgroundColor: Colours.black,
    marginRight: '3vmin',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
    borderTopLeftRadius: '0.3vmin',
    borderBottomLeftRadius: '0.3vmin',
    height: '6vmin',
  },
  iconGooglePlay: {
    width: '4.5vmin',
    height: '4.5vmin',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    marginRight: '0.5vmin',
  },
  iconApple: {
    width: '4.5vmin',
    height: '4.5vmin',
    marginRight: '0.5vmin',
    fill: Colours.white,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelAppStore: {
    fontSize: '1.5vmin',
    color: Colours.white,
  },
  labelAppStore1: {
    fontSize: '2vmin',
    fontWeight: 'bold',
    color: Colours.white,
  },
};
