import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.1 132">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Slogan">
          <path d="M63.8.9,49.8,53H43.9L32,10.4h-.5L19.7,53H13.8L0,.9H5.5L16.7,43.8h.5L28.9.9h6L46.6,43.8h.5L58.3.9Z" />
          <path d="M72.1.9h5.3V53H72.1Z" />
          <path d="M126.9.9V53h-5.4L96.3,11.2h-.5V53H90.5V.9H96l25.2,41.9h.5V.9Z" />
          <path d="M176.5.9V53H171L145.8,11.2h-.5V53H140V.9h5.5l25.2,41.9h.5V.9Z" />
          <path d="M194.9,5.9V23.8h22.3v5H194.9V48H218v5H189.6V.9H218v5Z" />
          <path d="M242.9,31.7h-9V53h-5.3V.9h17.2c8.7,0,16.1,4.9,16.1,15.4,0,9.3-5.8,14.2-13.1,15.2L262.4,53h-6.2ZM234,26.6h11.5c5.6,0,10.8-3,10.8-10.4S251.1,5.9,245.5,5.9H234Z" />
          <path d="M268.8,38.9h5.4c.9,7.1,5.5,9.9,11.3,9.9,6.4,0,10.4-3.6,10.4-9.1,0-5.2-3-7.8-8.4-9.6l-6.2-2C274,25.8,270,20.8,270,14.4c0-9,7.1-14.4,15.4-14.4,9.4,0,15,5.7,16,14.3H296c-.9-5.9-4.5-9.1-10.6-9.1-5.1,0-9.8,3-9.8,9,0,4.4,2.7,7.2,8,9l6.3,2c6.9,2.1,11.6,6.6,11.6,14.4,0,8.9-6.5,14.5-15.8,14.5C275.9,53.9,269.8,48.6,268.8,38.9Z" />
          <path d="M317.6.9V7.5c0,9.7-2.4,12.9-7.6,13.3V17.1c2.7-.4,3.5-2.4,3.7-8.5h-3.9V.9Z" />
          <path d="M23,78c9.8,0,17,6.3,18.7,16.6H36c-1.6-7.3-6.6-11.4-13-11.4-9.7,0-15.1,8-15.1,21.8s5.5,21.8,15.1,21.8c6.5,0,11.5-4.1,13-11.4h5.7C40,125.7,32.7,132,23,132,9.9,132,2.3,121.7,2.3,105S9.9,78,23,78Z" />
          <path d="M51.2,78.9h5.3V131H51.2Z" />
          <path d="M83.9,109.7h-9V131H69.6V78.9H86.8c8.7,0,16.1,4.9,16.1,15.4,0,9.3-5.8,14.2-13.1,15.2L103.4,131H97.2Zm-9-5.1H86.5c5.6,0,10.8-3,10.8-10.4S92.1,83.9,86.5,83.9H75v20.7Z" />
          <path d="M130.9,78c9.8,0,17,6.3,18.7,16.6h-5.7c-1.6-7.3-6.6-11.4-13-11.4-9.7,0-15.1,8-15.1,21.8s5.5,21.8,15.1,21.8c6.5,0,11.5-4.1,13-11.4h5.7c-1.7,10.3-9,16.6-18.7,16.6-13.1,0-20.7-10.3-20.7-27S117.8,78,130.9,78Z" />
          <path d="M159.2,78.9h5.3V126h21.6v5H159.2V78.9Z" />
          <path d="M199.6,83.9v17.9h22.3v5H199.6V126h23.1v5H194.3V78.9h28.4v5Z" />
          <path d="M246.5,124.7l18.9-18.1c3.2-3.1,6.4-6.8,6.4-12.9,0-6.3-4.1-10.7-10.1-10.7-6.3,0-9.8,3.4-10.6,9.3h-5.3c.8-8,6-14.3,15.9-14.3,9,0,15.4,6.4,15.4,15.7,0,7.3-3.4,12.1-8.2,16.7l-16.4,15.3v.4h24.7V131H246.5Z" />
          <path d="M285.9,112.5V97.4c0-12.8,7.6-19.4,17.3-19.4s17.4,6.6,17.4,19.4v15.1c0,12.8-7.6,19.4-17.4,19.4S285.9,125.3,285.9,112.5Zm29.5-.1v-15c0-9.7-5.1-14.5-12.2-14.5S291,87.7,291,97.4v15c0,9.7,5.1,14.5,12.2,14.5S315.4,122.1,315.4,112.4Z" />
          <path d="M329.3,124.7l18.9-18.1c3.2-3.1,6.4-6.8,6.4-12.9,0-6.3-4.1-10.7-10.1-10.7-6.3,0-9.8,3.4-10.6,9.3h-5.3c.8-8,6-14.3,15.9-14.3,9,0,15.4,6.4,15.4,15.7,0,7.3-3.4,12.1-8.2,16.7l-16.4,15.4v.4H360v4.9H329.3v-6.4Z" />
          <path d="M387.1,78.9V131h-5.2V86.1h-.5l-15.3,12V91.9l16.2-13Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
