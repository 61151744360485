import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M6,58V53.91A10.87,10.87,0,0,1,16.83,43h30.3A10.87,10.87,0,0,1,58,53.87h0V58H55V53.91A7.88,7.88,0,0,0,
        47.15,46H16.87A7.88,7.88,0,0,0,9,53.89H9V58ZM20,27V18A12.15,12.15,0,0,1,32,6,12.21,12.21,0,0,1,44,18v9A12.21,
        12.21,0,0,1,32,39,12.15,12.15,0,0,1,20,27Zm3,0a9,9,0,0,0,18,0V18a9,9,0,0,0-18,0Z"
      />
    </svg>
  </SvgIcon>
);
