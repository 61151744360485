import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getMonthRanking: ['auth', 'month', 'year'],
  getMonthRankingSuccess: ['monthRanking'],
  requestMonthRankingFailed: [],
  getJumperRanking: ['auth', 'month', 'year'],
  getJumperRankingSuccess: ['jumperRanking'],
  requestJumperRankingFailed: [],
  getYearRanking: ['auth', 'year'],
  getYearRankingSuccess: ['yearRanking'],
  requestYearRankingFailed: [],
  getAccountStatement: ['auth'],
  getAccountStatementSuccess: ['accountStatement'],
  requestAccountStatementFailed: [],
  getYearlySinglePageSuccess: ['singlePage'],
  getMonthlySinglePageSuccess: ['singlePage'],
  getJumperSinglePageSuccess: ['singlePage'],
  getFavoriteProductSinglePageSuccess: ['singlePage'],
  clear: [],
});

export const TableTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  monthRanking: {},
  yearRanking: {},
  jumperRanking: {},
  accountStatement: {},
  fetchMonthRanking: false,
  fetchYearRanking: false,
  fetchJumperRanking: false,
  fetchAccountStatement: false,
  singlePageYearly: null,
  singlePageMonthly: null,
  singlePageJumper: null,
  singlePageFavoriteProduct: null,
};

/* ------------- Reducers ------------- */
const requestMonthRanking = state => ({ ...state, fetchMonthRanking: true });
const getMonthRankingSuccess = (state, { monthRanking }) => ({
  ...state,
  fetchMonthRanking: false,
  monthRanking,
});
const failureMonthRanking = (state, { error }) => ({
  ...state,
  fetchMonthRanking: false,
  error,
});

const requestJumperRanking = state => ({ ...state, fetchJumperRanking: true });
const getJumperRankingSuccess = (state, { jumperRanking }) => ({
  ...state,
  fetchJumperRanking: false,
  jumperRanking,
});
const failureJumperRanking = (state, { error }) => ({
  ...state,
  fetchJumperRanking: false,
  error,
});

const requestYearRanking = state => ({ ...state, fetchYearRanking: true });
const getYearRankingSuccess = (state, { yearRanking }) => ({
  ...state,
  fetchYearRanking: false,
  yearRanking,
});
const failureYearRanking = (state, { error }) => ({
  ...state,
  fetchYearRanking: false,
  error,
});

const requestAccountStatement = state => ({
  ...state,
  fetchAccountStatement: true,
});
const getAccountStatementSuccess = (state, { accountStatement }) => ({
  ...state,
  fetchAccountStatement: false,
  accountStatement,
});
const failureAccountStatement = (state, { error }) => ({
  ...state,
  fetchAccountStatement: false,
  error,
});

const getYearlySinglePageSuccess = (state, { singlePage }) => ({
  ...state,
  singlePageYearly: singlePage,
});
const getMonthlySinglePageSuccess = (state, { singlePage }) => ({
  ...state,
  singlePageMonthly: singlePage,
});
const getJumperSinglePageSuccess = (state, { singlePage }) => ({
  ...state,
  singlePageJumper: singlePage,
});
const getFavoriteProductSinglePageSuccess = (state, { singlePage }) => ({
  ...state,
  fetchSinglePageFavoriteProduct: false,
  singlePageFavoriteProduct: singlePage,
});

const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const tableReducer = createReducer(defaultState, {
  [Types.REQUEST_MONTH_RANKING_FAILED]: failureMonthRanking,
  [Types.GET_MONTH_RANKING]: requestMonthRanking,
  [Types.GET_MONTH_RANKING_SUCCESS]: getMonthRankingSuccess,

  [Types.REQUEST_JUMPER_RANKING_FAILED]: failureJumperRanking,
  [Types.GET_JUMPER_RANKING]: requestJumperRanking,
  [Types.GET_JUMPER_RANKING_SUCCESS]: getJumperRankingSuccess,

  [Types.REQUEST_YEAR_RANKING_FAILED]: failureYearRanking,
  [Types.GET_YEAR_RANKING]: requestYearRanking,
  [Types.GET_YEAR_RANKING_SUCCESS]: getYearRankingSuccess,

  [Types.REQUEST_ACCOUNT_STATEMENT_FAILED]: failureAccountStatement,
  [Types.GET_ACCOUNT_STATEMENT]: requestAccountStatement,
  [Types.GET_ACCOUNT_STATEMENT_SUCCESS]: getAccountStatementSuccess,

  [Types.GET_YEARLY_SINGLE_PAGE_SUCCESS]: getYearlySinglePageSuccess,
  [Types.GET_MONTHLY_SINGLE_PAGE_SUCCESS]: getMonthlySinglePageSuccess,
  [Types.GET_JUMPER_SINGLE_PAGE_SUCCESS]: getJumperSinglePageSuccess,
  [Types.GET_FAVORITE_PRODUCT_SINGLE_PAGE_SUCCESS]: getFavoriteProductSinglePageSuccess,

  [Types.CLEAR]: clear,
});
