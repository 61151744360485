import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getNews: ['auth', 'offset', 'limit', 'showPublished'],
  getNewsSuccess: ['news', 'loadingMoreAvailable', 'offset'],
  requestFailed: [],
  clear: [],
});

export const NewsType = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  news: [],
  fetchNews: false,
  loadingMoreAvailable: false,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state, fetchNews: true });
const getNewsSuccess = (state, { news, loadingMoreAvailable, offset }) => ({
  ...state,
  fetchNews: false,
  news: offset === 0 ? news : [...state.news, ...news],
  loadingMoreAvailable,
});
const failure = (state, { error }) => ({ ...state, fetchNews: false, error });
const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const newsReducer = createReducer(defaultState, {
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_NEWS]: request,
  [Types.GET_NEWS_SUCCESS]: getNewsSuccess,
  [Types.CLEAR]: clear,
});
