import { all, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from '../auth';
import { SocialTypes } from '../social';
import { ChartType } from '../chart';
import { NewsType } from '../news';
import { NodeType } from '../node';
import { ELearningTypes } from '../elearning';
import { TableTypes } from '../table';
import { SinglePageTypes } from '../singlePage';
import { UserSettingsType } from '../userSettings';
import { FactSheetsTypes } from '../factSheets';
import { QuizTypes } from '../quiz';
import { NotificationsTypes } from '../notifications';
import { NotificationSettingsTypes } from '../notificationSettings';
import { signOut } from './auth';
import { getSocial, getSocialCount, getSinglePost, likeMedia, deleteMedia, editMedia, updateMedia, createPost, getLastPost } from './social';
import { getChart } from './chart';
import { getNews } from './news';
import { getQuiz, postQuizAnswer } from './quiz';
import { getFactSheetsList } from './factSheets';
import { getNotifications, markNotification, seeNotifications } from './notifications';
import { getNotificationChannels, muteNotificationChannel } from './notificationSettings';
import { getNodeDetail } from './node';
import { getFactSheets, getELearningItems } from './elearning';
import { getMonthRanking, getJumperRanking, getYearRanking, getAccountStatement } from './table';
import { getSinglePage } from './singlePage';
import { getUserClubMedal, getUserSettings, updateUserSettings } from './userSettings';

export default function* rootSaga() {
  yield all([
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    // takeLatest(AuthTypes.SET_AUTH_TOKEN, setAuthToken),
    takeLatest(ChartType.GET_CHART, getChart),
    takeLatest(NewsType.GET_NEWS, getNews),
    takeLatest(FactSheetsTypes.GET_FACT_SHEETS, getFactSheetsList),
    takeLatest(QuizTypes.GET_QUIZ, getQuiz),
    takeLatest(QuizTypes.POST_QUIZ_ANSWER, postQuizAnswer),
    takeLatest(NotificationsTypes.GET_NOTIFICATIONS, getNotifications),
    takeLatest(NotificationsTypes.MARK_NOTIFICATION, markNotification),
    takeLatest(NotificationsTypes.SEE_NOTIFICATIONS, seeNotifications),
    takeLatest(NotificationSettingsTypes.GET_NOTIFICATION_CHANNELS, getNotificationChannels),
    takeLatest(NotificationSettingsTypes.MUTE_NOTIFICATION_CHANNEL, muteNotificationChannel),
    // takeLatest(NotificationsTypes.NOTIFY_ABOUT_READING, readNotification),
    takeLatest(NodeType.GET_NODE_DETAIL, getNodeDetail),
    takeLatest(ELearningTypes.GET_FACT_SHEETS, getFactSheets),
    takeLatest(TableTypes.GET_MONTH_RANKING, getMonthRanking),
    takeLatest(TableTypes.GET_JUMPER_RANKING, getJumperRanking),
    takeLatest(TableTypes.GET_YEAR_RANKING, getYearRanking),
    takeLatest(TableTypes.GET_ACCOUNT_STATEMENT, getAccountStatement),
    takeLatest(SinglePageTypes.GET_SINGLE_PAGE, getSinglePage),
    takeLatest(ELearningTypes.GET_ELEARNING_ITEMS, getELearningItems),
    takeLatest(UserSettingsType.GET_USER_SETTINGS, getUserSettings),
    takeLatest(UserSettingsType.GET_USER_CLUB_MEDAL, getUserClubMedal),
    takeLatest(UserSettingsType.UPDATE_USER_SETTINGS, updateUserSettings),
    // SOCIAL MEDIA
    takeLatest(SocialTypes.GET_SOCIAL, getSocial),
    takeLatest(SocialTypes.GET_SOCIAL_COUNT, getSocialCount),
    takeLatest(SocialTypes.GET_SINGLE_POST, getSinglePost),
    takeLatest(SocialTypes.LIKE_MEDIA, likeMedia),
    takeLatest(SocialTypes.EDIT_MEDIA, editMedia),
    takeLatest(SocialTypes.DELETE_MEDIA, deleteMedia),
    takeLatest(SocialTypes.UPDATE_MEDIA, updateMedia),
    takeLatest(SocialTypes.CREATE_POST, createPost),
    takeLatest(SocialTypes.GET_LAST_POST, getLastPost),
  ]);
}
