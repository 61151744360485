import React from 'react';
import Button from '@material-ui/core/Button';
import { isLandscapeMode } from '../../lib/utils';
import { Colours, CommonStyles } from '../../resources';

const styles = {
  bottomContainer: {
    width: '100vw',
    backgroundColor: Colours.whiteGray,
    paddingTop: '5vmin',
    paddingBottom: '5vmin',
  },
  bottomTitle: {
    marginLeft: '20vw',
    ...CommonStyles.h1,
    marginBottom: '3vmin',
  },
  prizeAboutContainer: {
    width: isLandscapeMode() ? '60vw' : '80vw',
    marginLeft: isLandscapeMode() ? '20vw' : '10vw',
  },
  rowImage: {
    marginLeft: '15vw',
    marginTop: '3vmin',
    width: '80vw',
    display: 'flex',
    flexDirection: 'row',
  },
  moreContainer: {
    ...CommonStyles.h3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60vw',
    marginLeft: '20vw',
    marginTop: '3vmin',
    marginBottom: '3vmin',
    borderBottomWidth: 1,
    paddingBottom: '3vmin',
    borderBottomColor: Colours.darkBlack,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  iconArrow: {
    width: '3vmin',
    height: '3vmin',
    marginLeft: '3vmin',
    transform: 'rotate(270deg)',
  },
  ul: {
    marginLeft: '5vmin',
    marginTop: '2vmin',
  },
  button: {
    marginTop: '5vmin',
    marginLeft: isLandscapeMode() ? '20vw' : '10vw',
    ...CommonStyles.button,
  },
};

export default () => (
  <div style={styles.bottomContainer}>
    <div style={styles.bottomTitle}>3. Winning Logic</div>
    <div style={styles.prizeAboutContainer}>
      <div style={{ ...CommonStyles.h2, fontWeight: 'bold', marginBottom: '2vmin', paddingTop: '1vmin' }}>
        Headline Text Gold
        <br />
      </div>
      <div style={CommonStyles.h3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
        massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
        justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
      </div>
    </div>

    <div style={styles.prizeAboutContainer}>
      <div style={{ ...CommonStyles.h2, fontWeight: 'bold', marginTop: '3vmin', marginBottom: '3vmin', paddingTop: '1vmin' }}>
        Annual ranking
        <br />
      </div>
      <div style={CommonStyles.h3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
        massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
        justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
        sit amet adipiscing sem neque sed ipsum.
      </div>
    </div>

    <div style={styles.prizeAboutContainer}>
      <div style={{ ...CommonStyles.h2, fontWeight: 'bold', marginTop: '3vmin', marginBottom: '3vmin', paddingTop: '1vmin' }}>
        Sales pushes
        <br />
      </div>
      <div style={CommonStyles.h3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
        massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
        justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
        sit amet adipiscing sem neque sed ipsum.
        <br />
        <div style={styles.ul}>
          ● &nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />
        </div>
        <div style={styles.ul}>
          ● &nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />
        </div>
        <div style={styles.ul}>
          ● &nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />
        </div>
        <div style={styles.ul}>
          ● &nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />
        </div>
        <div style={styles.ul}>
          ● &nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />
        </div>
      </div>
    </div>

    <div style={styles.prizeAboutContainer}>
      <div style={{ ...CommonStyles.h2, fontWeight: 'bold', marginTop: '3vmin', marginBottom: '3vmin', paddingTop: '1vmin' }}>
        Film
        <br />
      </div>
      <div style={CommonStyles.h3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
        massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
        justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
        sit amet adipiscing sem neque sed ipsum.
      </div>
    </div>

    <Button variant="contained" color="primary" style={styles.button}>
      Verlinkung rules
    </Button>
  </div>
);
