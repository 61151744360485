import TClaim from './T-Claim';
import TClaimINT from './T-Claim-INT';
import TLogo from './T-Logo';
import Cup from './cup';
import News from './news';
import Social from './social';
import Info from './info';
import ETraining from './e-training';
import Shop from './shop';
import Apple from './apple';
import Folder from './folder';
import MenuBar from './menu-bars';
import UserStar from './user-star';
import RightArrow from './rightArrow';
import LeftArrow from './leftArrow';
import AppLogo from './appLogo';
import Protection from './protection';
import Logout from './logout';
import Program from './program';
import User from './user';
import Email from './email';
import Back from './back';
import QuizSuccess from './quizSuccess';
import QuizFailed from './quizFailed';
import ChatOutline from './chat-outline';
import HeartOutline from './heart-outline';
import PhoneCameraOutline from './photo-camera-outline';
import NotificationBell from './notificationBell';
import NotificationBellOutline from './notificationBellOutline';
// REFACTOR
import Triangle from './triangle';
import lineGraphSolid from './lineGraphSolid';
import Close from './close';
// NEW
import { trophyBronze as TrophyBronze, trophyGold as TrophyGold, trophyMagenta as TrophyMagenta, trophySilver as TrophySilver } from './trophy';
import ThreeCubeDotNew from './threeCubeDotNew';
import EditNew from './editNew';
import TrashNew from './trashNew';
import HeartNew from './heartNew';
import BrainNew from './brainNew';
import EtrainingNew from './etrainingNew';
import NewsNew from './newsNew';
import SocialFieldNew from './socialFieldNew';
import HeartFilledNew from './heartFilledNew';
import CommentNew from './commentNew';
import CommentFilledNew from './commentFilledNew';
import ShareNew from './shareNew';
import ArrowDownNew from './arrowDownNew';
import LoupeNew from './loupeNew';
import CancelNew from './cancelNew';
import LogoSamsung from './logoSamsung';
import NewAppLogo from './newAppLogo';
import NewAppLogoBold from './newAppLogoBold';
import NewAppLogoLogin from './newAppLogoLogin';
import PlayStore from './playStore';
import BlueBox from './blueBox';

import IconCupGray from './iconCupGray';
import IconCupMagenta from './iconCupMagenta';
import WiciLogo from './wici_logo_2024';

export default {
  Folder,
  AppLogo,
  TClaimINT,
  TClaim,
  TLogo,
  Cup,
  News,
  Social,
  Info,
  ETraining,
  Shop,
  Apple,
  MenuBar,
  UserStar,
  RightArrow,
  LeftArrow,
  Protection,
  Logout,
  User,
  Email,
  Program,
  Back,
  QuizSuccess,
  QuizFailed,
  ChatOutline,
  HeartOutline,
  PhoneCameraOutline,
  NotificationBell,
  NotificationBellOutline,
  // REFACTOR
  Triangle,
  lineGraphSolid,
  Close,
  // NEW
  TrophyMagenta,
  TrophyGold,
  TrophySilver,
  TrophyBronze,
  ThreeCubeDotNew,
  EditNew,
  TrashNew,
  HeartNew,
  BrainNew,
  EtrainingNew,
  NewsNew,
  SocialFieldNew,
  HeartFilledNew,
  CommentNew,
  CommentFilledNew,
  ShareNew,
  ArrowDownNew,
  LoupeNew,
  CancelNew,
  LogoSamsung,
  NewAppLogo,
  NewAppLogoBold,
  NewAppLogoLogin,
  PlayStore,
  BlueBox,
  IconCupGray,
  IconCupMagenta,
  WiciLogo,
};
