import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loading extends Component {
  state = {
    completed: 0,
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 20);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  };
  render() {
    return (
      <div style={{ marginTop: '20vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress variant="determinate" value={this.state.completed} />
      </div>
    );
  }
}
