import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getChart: ['auth'],
  getChartSuccess: ['charts'],
  getJumperRankingSuccess: ['jumperRanking', 'shopPoints'],
  requestFailed: [],
  clear: [],
});

export const ChartType = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  charts: {},
  jumperRanking: {},
  fetchJumperRanking: false,
  fetchChart: false,
  shopPoints: 0,
};

/* ------------- Reducers ------------- */
const request = state => ({
  ...state,
  fetchChart: true,
  fetchJumperRanking: true,
});
const getChartSuccess = (state, { charts }) => ({
  ...state,
  fetchChart: false,
  charts,
});
const getJumperRankingSuccess = (state, { jumperRanking, shopPoints }) => ({
  ...state,
  fetchJumperRanking: false,
  jumperRanking,
  shopPoints,
});
const failure = (state, { error }) => ({
  ...state,
  fetchChart: false,
  fetchJumperRanking: false,
  error,
});
const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const chartReducer = createReducer(defaultState, {
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_CHART]: request,
  [Types.GET_CHART_SUCCESS]: getChartSuccess,
  [Types.GET_JUMPER_RANKING_SUCCESS]: getJumperRankingSuccess,
  [Types.CLEAR]: clear,
});
