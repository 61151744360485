import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Colours, SVG } from '../../../../../../resources';

import styles from './index.module.scss';
import { authSelector, socialSelector } from '../../../../../../redux/selector';
import { connect } from 'react-redux';
import { MEDIA_FILTER } from '../../../../../../constants/common';
import I18n from 'i18n-js';

const Dropdown = ({ filter, options, onSelect, auth }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const local = queryParams.get('local');

  const [currentOption, setCurrentOption] = useState(auth.countryCode === 'NL' || local === '1' ? options[2] : options[0]);
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const close = () => {
    setOpen(false);
  };

  const select = option => {
    setCurrentOption(option);
    onSelect(option.value);
    close();
  };

  const renderOptionItem = option => (
    <div
      key={option.value}
      className={clsx([styles.optionItem, currentOption.value === option.value ? styles.selectedOption : null])}
      onClick={() => {
        select(option);
      }}>
      <div className={styles.selectItemText} numberOfLines={1}>
        {option.label}
      </div>
    </div>
  );

  const renderOptions = () => (isOpen ? <div className={styles.optionsContainer}>{options.map(renderOptionItem)}</div> : null);

  useEffect(() => {
    if (local === '1') {
      select(options[2]);
    }
  }, [local]);

  useEffect(() => {
    if (filter && filter.country)
      setCurrentOption({
        label: I18n.t('uploadMediaDropdownFilterSelectOption3.name'),
        value: MEDIA_FILTER.myCountry,
      });
  }, [filter]);

  return (
    <div className={styles.container} onClick={toggle}>
      <div className={styles.label}>{currentOption.label}</div>
      <SVG.ArrowDownNew className={styles.arrowIcon} fill={Colours.whiteBackground} />
      {renderOptions()}
    </div>
  );
};

const mapStateToProps = state => ({
  ...socialSelector(state),
  ...authSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
