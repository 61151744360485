import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <path d="M23,54.5a1.5,1.5,0,0,1-1.06-2.56L41.88,32,21.94,12.06a1.5,1.5,0,0,1,2-2.19l.07.07,21,21a1.51,1.51,0,0,1,0,2.12l-21,21A1.5,1.5,0,0,1,23,54.5Z" />
      </g>
    </svg>
  </SvgIcon>
);
