import ReactPiwik from 'react-piwik';

export function trackScreen(screen) {
  // eslint-disable-next-line
  const piwik = new ReactPiwik({
    url: '//matomo.d.dom.de',
    siteId: 1,
    trackErrors: true,
  });
  ReactPiwik.push(['setCustomUrl', screen]);
  ReactPiwik.push(['trackPageView']);
}
