import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getSinglePage: ['auth', 'alias'],
  getSinglePageSuccess: ['singlePage', 'alias'],
  setPageBeforeShop: ['pageBeforeShop'],
  requestFailed: [],
  clear: [],
});

export const SinglePageTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  singlePages: {},
  fetchSinglePage: false,
  pageBeforeShop: null,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state, fetchSinglePage: true });
const getSinglePageSuccess = (state, { singlePage, alias }) => ({
  ...state,
  fetchSinglePage: false,
  singlePages: { ...state.singlePages, [alias]: singlePage },
});
const failure = (state, { error }) => ({ ...state, fetchSinglePage: false, error });
const clear = state => ({ ...state, ...defaultState });
const setPageBeforeShop = (state, { pageBeforeShop }) => ({ ...state, pageBeforeShop });

/* ------------- Hookup Reducers To Types ------------- */
export const singlePageReducer = createReducer(defaultState, {
  [Types.SET_PAGE_BEFORE_SHOP]: setPageBeforeShop,
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_SINGLE_PAGE]: request,
  [Types.GET_SINGLE_PAGE_SUCCESS]: getSinglePageSuccess,
  [Types.CLEAR]: clear,
});
