import { call, put, select } from 'redux-saga/effects';
import {
  getNotifications as apiGetNotifications,
  markNotification as apiMarkNotification,
  seeNotifications as apiSeeNotifications,
} from '../../lib/api';
import NotificationsActions from '../notifications';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

import { convertNotifications } from '../../converters/notifications';
import { markAllSeen } from '../../utils/markAllNotificationsSeen';

import { getAuth } from '../selector/auth';
import { selectNotificationsById } from '../selector/notifications';

export function* getNotifications() {
  try {
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const data = yield call(apiGetNotifications, { auth: authRefreshed });
      if (!data.error) {
        yield put(NotificationsActions.saveNotifications(convertNotifications(data)));
      } else {
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* markNotification({ payload }) {
  try {
    const { id, uuid, channelId } = payload;
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      yield call(apiMarkNotification, {
        auth: authRefreshed,
        id,
        uuid,
        channelId,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* seeNotifications() {
  try {
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      yield call(apiSeeNotifications, { auth: authRefreshed });
      const notifications = yield select(selectNotificationsById);
      yield put(NotificationsActions.updateNotificationsById(markAllSeen(notifications)));
    }
  } catch (err) {
    console.log(err);
  }
}
