import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { PNG, SVG, Const } from '../../../resources';
import styles from './styles';
import { Loading } from '../../../components';

export default class MobileAppDownload extends Component {
  render() {
    const { content, onClose, pageIndex } = this.props;
    return (
      <div style={styles.container}>
        {content ? (
          <div style={styles.content}>
            <div style={styles.titleContainer}>
              <div style={styles.description} dangerouslySetInnerHTML={{ __html: content.value }} />
              <div style={styles.appstoreContainer}>
                <div
                  style={styles.buttonContainer}
                  onClick={() => {
                    window.open(Const.appStoreLink);
                  }}>
                  <SVG.Apple style={styles.iconApple} />
                  <div style={styles.labelContainer}>
                    <div style={styles.labelAppStore}>Download on the</div>
                    <div style={styles.labelAppStore1}>App Store</div>
                  </div>
                </div>
                <div
                  style={styles.buttonContainer}
                  onClick={() => {
                    window.open(Const.playStoreLink);
                  }}>
                  <div style={{ ...styles.iconGooglePlay, backgroundImage: `url(${PNG.GooglePlay})` }} />
                  <div style={styles.labelContainer}>
                    <div style={styles.labelAppStore}>GET IT ON</div>
                    <div style={styles.labelAppStore1}>Google Play</div>
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.imageArea}>
              <div style={{ ...styles.img, backgroundImage: `url(${pageIndex === 0 ? PNG.ELearningAppDownload : PNG.ELearningAppDownloadQuiz})` }} />
            </div>
          </div>
        ) : (
          <Loading />
        )}
        <div style={styles.closeContainer} onClick={onClose}>
          <Icon className="fa fa-times" style={styles.iconClose} />
        </div>
      </div>
    );
  }
}
