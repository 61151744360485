import { Component } from 'react';
import qs from 'qs';
import { getLocalData } from '../resources/locale';

let navigation = null;
export const localStorage = typeof window !== 'undefined' ? window.localStorage : null;
export const getNavigation = () => {
  return navigation;
};
export const setNavigation = history => {
  navigation = history;
};
export default class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: NaN,
      windowHeight: NaN,
    };
  }
  _handleScroll = () => {
    this._handleOverrideScroll && this._handleOverrideScroll();
  };

  _handleResize = () => {
    const { windowWidth, windowHeight } = this.state;
    if (
      (windowWidth > windowHeight && window.innerWidth < window.innerHeight) ||
      (windowWidth < windowHeight && window.innerWidth > window.innerHeight)
    ) {
      this._handleChangeReload();
    }
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
    this._overrideHandleResize && this._overrideHandleResize();
  };
  _handleChangeReload = () => {
    // HOT FIX
    // in future will be better to remove BaseComponent at all
    // window.location.reload();
  };
  getAuth() {
    const { location, auth } = this.props;
    const queryResult = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { id, token } = queryResult;
    let paramAuth = {};
    if (id && token) {
      paramAuth = { ...queryResult, fromParameter: true };
    } else {
      paramAuth = auth;
    }
    return paramAuth;
  }
  async componentDidMount() {
    // this.props.checkAuthTokenStatus && this.props.checkAuthTokenStatus();
    navigation = this.props.history;
    window.addEventListener('scroll', this._handleScroll);
    window.addEventListener('resize', this._handleResize);
    window.addEventListener('orientationchange', this._handleChangeReload);
    window.addEventListener('languagechange', this._handleChangeReload);

    let auth = this.getAuth();
    if (auth.fromParameter) {
      await getLocalData(null, auth);
    }
    this.overrideComponentDidMount && this.overrideComponentDidMount(auth);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
    window.removeEventListener('resize', this._handleResize);
    window.removeEventListener('orientationchange', this._handleChangeReload);
    window.removeEventListener('languagechange', this._handleChangeReload);
    this.overrideComponentWillUMount && this.overrideComponentWillUMount();
  }
}
