import React from 'react';
import i18n from 'i18n-js';
import clsx from 'clsx';

import styles from './index.module.scss';

const DeleteDialog = ({ onDeletePost, closeDeleteDialog }) => (
  <div className={styles.container}>
    <div className={styles.title}>{i18n.t('DeleteOwnPostHeadline.name')}</div>
    <div className={styles.description}>{i18n.t('DeleteOwnPostCopy.name')}</div>
    <div className={styles.buttons}>
      <div className={clsx([styles.button, styles.buttonGap])} onClick={onDeletePost}>
        <div className={styles.buttonText}>{i18n.t('DeleteOwnPostSelectOption1.name')}</div>
      </div>

      <div className={styles.button} onClick={closeDeleteDialog}>
        <div className={styles.buttonText}>{i18n.t('DeleteOwnPostSelectOption2.name')}</div>
      </div>
    </div>
  </div>
);

export default DeleteDialog;
