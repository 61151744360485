import { createBrowserHistory, createMemoryHistory } from 'history';

export const history = !!(typeof window !== 'undefined' && window.document && window.document.createElement)
  ? createBrowserHistory()
  : createMemoryHistory({ initialEntries: ['/'] });

const sessionStorage = !!(typeof window !== 'undefined' && window.document) ? window.sessionStorage : { setItem: () => {}, getItem: () => {} };
class PathLocationManager {
  pastLocations = [];
  key = 'appLocationHistory';

  constructor() {
    const jsonFromSessionStorage = sessionStorage.getItem(this.key);
    this.pastLocations = jsonFromSessionStorage ? JSON.parse(jsonFromSessionStorage) : [];
  }

  push(location) {
    this.pastLocations.push(location);
    this.dumpToSessionStorage();
  }

  pop() {
    this.pastLocations.pop();
    this.dumpToSessionStorage();
  }

  length() {
    return this.pastLocations.length;
  }

  setLocation(index, location) {
    this.pastLocations[index] = location;
    this.dumpToSessionStorage();
  }

  getLocation(index) {
    return this.pastLocations[index];
  }

  getAllLocation() {
    return this.pastLocations;
  }

  setLocations(locations) {
    this.pastLocations = locations;
    this.dumpToSessionStorage();
  }

  dumpToSessionStorage() {
    sessionStorage.setItem(this.key, JSON.stringify(this.pastLocations));
  }
}

const pastLocations = new PathLocationManager();
let replacedPerformed = false;

function updatePastLocations(location, action) {
  // console.log({ action });
  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location);
      replacedPerformed = false;
      break;
    case 'REPLACE':
      // only when using history.replace
      pastLocations.setLocation(pastLocations.length() - 1, location);
      replacedPerformed = true;
      break;
    case 'POP': {
      // happens when using the back button, or forward button
      pastLocations.pop();
      // location according to pastLocations
      const appLocation = pastLocations.getLocation(pastLocations.length() - 1);
      if (!(appLocation && appLocation.key === location.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations.setLocations([location]);
      }
      break;
    }
    default:
  }
}
history.listen(updatePastLocations);

function isPreviousLocationWithinApp() {
  return pastLocations.length() > 1;
}

export function goBackOrReplace(location, state) {
  // console.log(replacedPerformed);
  if (replacedPerformed) {
    const allLocation = pastLocations.getAllLocation();
    const search = allLocation.find(data => data.pathname === location.pathname);
    // console.log({ search, allLocation });
    // if (!search) {
    window.location.href = search && search.pathname ? search.pathname : location.pathname;
    // } else {
    //   history.goBack();
    // }
  } else if (isPreviousLocationWithinApp()) {
    history.goBack();
  } else {
    history.push(location, state);
  }
}
