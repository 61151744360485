import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';

import { SheetsRegistry } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';

import MaterialTheme from '../lib/MaterialTheme';

const generateClassName = createGenerateClassName();
const sheetsManager = new Map();

export const sheetsRegistry = new SheetsRegistry();

let muiTheme;

export default class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    let lightTheme = MaterialTheme['light'];
    lightTheme.typography = {
      useNextVariants: true,
    };
    muiTheme = createMuiTheme(lightTheme);
  }

  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
  }

  render() {
    const { store, children } = this.props;

    return (
      <JssProvider generateClassName={generateClassName} registry={sheetsRegistry}>
        <MuiThemeProvider sheetsManager={sheetsManager} theme={muiTheme}>
          <Provider store={store}>
            <Fragment>
              <CssBaseline />
              {children}
            </Fragment>
          </Provider>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}
