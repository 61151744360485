import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 48.91">
      <g id="Layer_2" data-name="Layer 2">
        <g id="apple.png_2x" data-name="apple.png+@2x">
          <g id="icon">
            <path d="M30.21,0c-4.36.35-10.55,4.85-9.7,11.53C26.25,11.74,31.05,5.25,30.21,0ZM35,25.92a11.32,11.32,0,0,1,5.58-9.51s-3.66-5.49-9.37-5.49c-4.25,0-6.5,2.46-9.36,2.46-3.39,0-5.15-2.46-9.37-2.46C7.66,10.92,0,15.21,0,25.92c0,10.31,7.57,23,13.43,23,3.07,0,4.43-2,8.46-2,4.64,0,4.88,2,8.37,2,6.21,0,11.74-13,11.74-13A10.58,10.58,0,0,1,35,25.92Z" />
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);
