import React, { Component } from 'react';
import i18n from 'i18n-js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

import { ReloadContainer } from '../../../components';
import TopPanel from './TopPanel';
import MediaCard from './MediaCard';

import styles from './styles';

export default class SocialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorLearn: null,
      reportItem: null,
      showReportDialog: false,
      showLearnDialog: false,
      closeEdit: null,
    };
  }

  _handleLearnClick = event => {
    this.setState({ anchorLearn: event.currentTarget, showLearnDialog: true });
  };

  _handleMenuClick = (event, reportItem) => {
    this.setState({ anchorEl: event.currentTarget, reportItem });
  };

  _handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
      reportItem: null,
      showReportDialog: false,
      anchorLearn: null,
      showLearnDialog: false,
    });
  };

  _handleClickMenu = () => {
    this.setState({ showReportDialog: true });
  };

  _handleReportItem = item => {
    this.props.onReport(item);
    this._handleCloseMenu();
  };

  _handleLikeClick = item => {
    this.props.onLike(item);
  };

  _handleEdit = data => {
    this.props.onEdit(data);
  };

  _handleDelete = item => {
    this.props.onDelete(item);
  };

  _handleCommentClick = commentItem => {
    this.props.onComment(commentItem);
  };

  setNewPopupOpen = () => {
    this.setState({
      closeEdit: false,
    });
  };

  onOpenDetails = index => {
    this.props.onPress(index);
    this.setNewPopupOpen();
  };
  render() {
    const {
      auth,
      data,
      // onPress,
      loading,
      // sectionLabel,
      listRef,
    } = this.props;
    const { anchorEl, showReportDialog, anchorLearn, showLearnDialog } = this.state;
    const open = Boolean(anchorEl);
    return (
      <ReloadContainer loading={loading} listRef={listRef}>
        <TopPanel setNewPopupOpen={this.setNewPopupOpen} />
        <Grid item container xs={12} style={styles.gridContainer}>
          <Grid container justify="center">
            {data.map((item, index) => (
              <Grid key={index} item>
                <MediaCard
                  {...{
                    item,
                    auth,
                  }}
                  onReport={this._handleReportItem}
                  onEdit={this._handleEdit}
                  closeEdit={this.state.closeEdit}
                  onLike={this._handleLikeClick}
                  onDelete={this._handleDelete}
                  openDetails={() => this.onOpenDetails(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Menu
          style={{ left: -70 }}
          id="long-menu"
          anchorEl={anchorEl}
          open={open && !showReportDialog}
          onClose={this._handleCloseMenu}
          PaperProps={{ style: styles.menuContainer }}>
          <MenuItem onClick={this._handleClickMenu} style={{ paddingTop: 0, paddingBottom: 0 }}>
            Report Item
          </MenuItem>
        </Menu>
        <Popover
          id="report-dialog"
          anchorEl={anchorEl}
          open={open && showReportDialog}
          onClose={this._handleCloseMenu}
          PaperProps={{ style: styles.menuContainer }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <div style={styles.reportDialogTitle}>{i18n.t('reportPOPupHL.name')}</div>
          <div style={styles.reportDialogText}>{i18n.t('reportPOPupCopy.name')}</div>
          <div style={styles.reportButtons}>
            <Button style={styles.reportButton} variant="outlined" onClick={this._handleReportItem}>
              {i18n.t('reportPOPupYes.name')}
            </Button>
            <Button style={styles.reportButton} variant="outlined" onClick={this._handleCloseMenu}>
              {i18n.t('reportPOPupNo.name')}
            </Button>
          </div>
        </Popover>
        <Popover
          id="learn-dialog"
          anchorEl={anchorLearn}
          open={showLearnDialog}
          onClose={this._handleCloseMenu}
          PaperProps={{ style: styles.menuContainer }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div style={styles.reportDialogTitle}>{i18n.t('SocialWallLink.name')}</div>
          <div
            style={styles.socialWallDialogText}
            dangerouslySetInnerHTML={{
              __html: i18n.t('SocialWallPopUpCopy.name'),
            }}
          />
          <div style={styles.reportButtons}>
            <Button style={styles.reportButton} variant="outlined" onClick={this._handleReportItem}>
              OK
            </Button>
          </div>
        </Popover>
      </ReloadContainer>
    );
  }
}
