import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M58.001,4h-52c-1.1,0-2,0.9-2,2v39c0,1.1,0.9,2,2,2h13v13l13-13h26c1.1,0,2-0.9,2-2V6 C60.001,4.9,59.101,4,58.001,4z M32.008,35h-16v-3h16V35z M48.008,27h-32v-3h32V27z M48.008,19h-32v-3h32V19z" />
    </svg>
  </SvgIcon>
);
