import jss from 'jss';
import preset from 'jss-preset-default';
import Colours from './colours';

jss.setup(preset());
const styleClass = {
  touch: {
    '&:hover': {
      background: `${Colours.whiteGray} !important`,
    },
    '&:active': {
      background: `${Colours.lightGray} !important`,
    },
  },
  colorText: {
    '&:hover': {
      color: `${Colours.primary} !important`,
    },
    '&:active': {
      color: `${Colours.lightPrimary} !important`,
    },
  },
  colorSVG: {
    '&:hover': {
      fill: `${Colours.primary} !important`,
    },
    '&:active': {
      fill: `${Colours.lightPrimary} !important`,
    },
  },
  imageTouch: {
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  comboStyle: {
    '&:focus': {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
    },
  },
  collapsibleFeature: {
    paddingTop: '3.5vw',
    paddingBottom: '3vw',
    borderBottomColor: Colours.lightGray,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
};
const { classes } = jss.createStyleSheet(styleClass).attach();
export default classes;
