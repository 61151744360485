import React from 'react';
import moment from 'moment';

import Heart from './Heart';
import Comment from './Comment';
import Share from './Share';

import styles from './styles';

const Footer = ({ item, liked, likesCount, commented, commentCount, externalLink, onLike, onComment }) => (
  <div style={styles.container}>
    <div style={styles.actionContainer}>
      <Heart {...{ item, liked, likesCount, onLike }} />
      <Comment {...{ item, commented, commentCount, onComment }} />
      <Share {...{ externalLink }} />
    </div>

    <div style={styles.rightBlock}>
      <div style={styles.creator}>{item.nickname}</div>
      <div style={styles.date}>{moment(item.created).format('DD MMM YYYY')}</div>
    </div>
  </div>
);

export default Footer;
