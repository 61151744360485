import React from 'react';
import i18n from 'i18n-js';

import { PNG } from '../../../resources';

import '../../../components/App.css';
import styles from './index.module.scss';
import ELearningMenu from '../ELearningMenu';
import FactSheetMenu from '../FactSheetMenu';
// import { isMobile } from '../../../lib/utils';

const ELearningMain = ({ onPress, quizResponseList, factSheetsSets, quizContainerVisibleFlag, history }) => {
  // console.log({ buttonsList, quizResponseList });
  let labelEtrain = i18n.t('startEtraining.name');
  let labelQuiz = i18n.t('startQuiz.name');
  let labelFactSheet = i18n.t('samsungFact.name');

  // if (buttonsList.length > 0) {
  //   if (buttonsList[0].quiz && buttonsList[0].quiz.length > 0) {
  //     labelQuiz = buttonsList[0].quiz;
  //   }
  //   if (buttonsList[0].fact_sheet && buttonsList[0].fact_sheet.length > 0) {
  //     labelFactSheet = buttonsList[0].fact_sheet;
  //   }
  //   if (buttonsList[0].e_training && buttonsList[0].e_training.length > 0) {
  //     labelEtrain = buttonsList[0].e_training;
  //   }
  // }

  const buttons = [
    {
      title: labelFactSheet,
      icon: PNG.FactSheet,
    },
    {
      title: labelQuiz,
      icon: PNG.BtnQuizStart,
    },
    {
      title: labelEtrain,
      icon: PNG.BtnELearningStart,
    },
  ];

  const [showFactShetsSets, setShowFactShetsSets] = React.useState(false);
  const [showQuizMenu, setShowQuizMenu] = React.useState(quizContainerVisibleFlag ? quizContainerVisibleFlag : false);

  const onClickFunction = function(index) {
    switch (index) {
      case 0:
        // Clicked on Factsheet
        console.log(factSheetsSets.length);
        if (factSheetsSets.length === 1) {
          history.push({ pathname: '/e-training/web-fact-list/' + factSheetsSets[0].id });
        } else {
          setShowFactShetsSets(!showFactShetsSets);
        }
        setShowQuizMenu(false);
        break;
      case 1:
        // Clicked on Quiz
        setShowQuizMenu(quizResponseList && quizResponseList.length > 0 ? !showQuizMenu : false);
        setShowFactShetsSets(false);
        break;
      default:
        // Reset both
        setShowQuizMenu(false);
        setShowFactShetsSets(false);
        break;
    }
    onPress(index);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.buttonsContainer}>
          <div className={styles.innerButtonsContainer}>
            {buttons.map((item, index) => {
              return (
                <>
                  <div key={item.title} onClick={() => onClickFunction(index)} className={styles.button}>
                    {item.icon && <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />}
                    <div className={styles.titleContainer}>
                      <span className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }} />
                    </div>
                  </div>
                  <div className={styles.mobileListContainer}>
                    {index === 0 && showFactShetsSets && (
                      <FactSheetMenu menuEntryList={factSheetsSets} eTrainingPath={'/e-training/web-fact-list'} history={history} />
                    )}
                    {index === 1 && showQuizMenu && <ELearningMenu menuEntryList={quizResponseList} eTrainingPath={'/e-training/web-quiz'} />}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.desktopListContainer}>
        {showQuizMenu && <ELearningMenu menuEntryList={quizResponseList} eTrainingPath={'/e-training/web-quiz'} />}
        {showFactShetsSets && <FactSheetMenu menuEntryList={factSheetsSets} eTrainingPath={'/e-training/web-fact-list'} />}
      </div>
    </>
  );
};

export default ELearningMain;
