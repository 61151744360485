import React from 'react';
import clsx from 'clsx';
import i18n from 'i18n-js';

import styles from './index.module.scss';

const Footer = ({ isValid, handleSubmit, onClose }) => (
  <div className={styles.container}>
    <div className={styles.button} onClick={onClose}>
      <div className={styles.buttonText}>{i18n.t('uploadMediaCancelButton.name')}</div>
    </div>

    <div className={styles.buttonsGap} />

    <div className={clsx([styles.uploadButton, isValid ? null : styles.disabledButton])} onClick={handleSubmit} disabled={!isValid}>
      <div className={clsx([styles.buttonText, isValid ? null : styles.disabledText])}>{i18n.t('uploadMediaUploadButton.name')}</div>
    </div>
  </div>
);

export default Footer;
