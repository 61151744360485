import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import SinglePageActions from '../../../redux/singlePage';
import { userSettingsSelector } from '../../../redux/selector';
import { getUnseenCount } from '../../../redux/selector/notifications';

import { trackScreen } from '../../../lib/matomo';

import { Link } from '../../../components';

import { SVG, Const } from '../../../resources';

import styles from './styles.module.scss';
// import { getUserMembershipType } from '../../../lib/api';
import { MEMBERSHIP_DETAILS, TROPHY_TYPE } from '../../../constants/common';
import { UserSettingsActions } from '../../../redux';

const AppLinks = ({ auth, unseenCount, userSettings, containerStyle, getUserClubMedal }) => {
  const [memberShipType, setMemberShipType] = useState(false);

  const onTrackScreen = path => {
    if (userSettings.web_matomo) trackScreen(path);
  };

  // Load memberships from locale file - #34087
  // else use default.
  const membershipLinks = {
    Gold: i18n.t('shopGold') || MEMBERSHIP_DETAILS.Gold,
    Silver: i18n.t('shopSilver') || MEMBERSHIP_DETAILS.Silver,
    Bronze: i18n.t('shopBronze') || MEMBERSHIP_DETAILS.Bronze,
  };

  const openShop = () => {
    // Use locale memberships shop url - #34079
    // Use only shop link - #34079 - note-#10
    let shopUrl = i18n.t('shop.link_web');
    let shopUrl2 = shopUrl.slice(shopUrl.indexOf('iframe') + 7);

    if (shopUrl2.includes('/?embedded=1')) {
      // (!) The link to the trophy shop should always have a "/" at the end! - #34079 - note-#12
      shopUrl2 = shopUrl2.replace('?embedded=1', '');
    }

    window.open(shopUrl2, '_blank');
  };

  const openAppstor = () => {
    window.open(Const.appStoreLink);
  };

  const openPlayStore = () => {
    window.open(Const.playStoreLink);
  };

  const renderUnseenCount = () => {
    if (unseenCount === 0) {
      return null;
    }

    return (
      <div className={styles.counter}>
        <div className={styles.counterText}>{unseenCount}</div>
      </div>
    );
  };

  useEffect(() => {
    if (
      userSettings &&
      userSettings.currentMedal &&
      membershipLinks[userSettings.currentMedal] &&
      membershipLinks[userSettings.currentMedal].link_web
    ) {
      setMemberShipType(userSettings.currentMedal);
    } else {
      getUserClubMedal(auth);
    }
  }, [auth]);

  useEffect(() => {
    // Get logged in user current membership status - #33721
    // To Fix #34138 validate user trophy befor assigning to state.
    if (
      userSettings &&
      userSettings.currentMedal &&
      membershipLinks[userSettings.currentMedal] &&
      membershipLinks[userSettings.currentMedal].link_web
    ) {
      setMemberShipType(userSettings.currentMedal);
    }
  }, [userSettings]);

  // Display trophy icon on base of current membership type - #33721
  const displayClubMembership = () => {
    const navigateToMembership = type => {
      // Memberships from locale file - #34087
      if (membershipLinks[type] && membershipLinks[type].link_web) {
        // Shop Redirection to new window instead of iframe - #34079
        let shopUrl = membershipLinks[type].link_web;
        let shopUrl2 = shopUrl.slice(shopUrl.indexOf('iframe') + 7);
        if (shopUrl2.includes('/?embedded=1')) {
          // (!) The link to the trophy shop should always have a "/" at the end! - #34079 - note-#12
          shopUrl2 = shopUrl2.replace('?embedded=1', '');
        }
        // Open link in new window
        window.open(shopUrl2, '_blank');
      }
    };

    let trophyIcon = <></>;
    switch (memberShipType) {
      case TROPHY_TYPE.gold:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.gold)}>
            <SVG.TrophyGold className={styles.icon} />
          </div>
        );
        break;

      case TROPHY_TYPE.silver:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.silver)}>
            <SVG.TrophySilver className={styles.icon} />
          </div>
        );
        break;

      case TROPHY_TYPE.bronze:
        trophyIcon = (
          <div className={styles.button} onClick={() => navigateToMembership(TROPHY_TYPE.bronze)}>
            <SVG.TrophyBronze className={styles.icon} />
          </div>
        );
        break;

      default:
        trophyIcon = <></>;
        break;
    }
    return trophyIcon;
  };

  return (
    <div className={clsx([styles.container, containerStyle || null])}>
      <Link className={styles.button} to={'/notifications'} onClick={() => onTrackScreen('/notifications')}>
        <SVG.NotificationBell
          className={styles.icon}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
        {renderUnseenCount()}
      </Link>

      <div className={styles.button} onClick={openShop}>
        <SVG.Shop className={styles.icon} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" />
      </div>

      {displayClubMembership()}

      <Link className={styles.button} to={'/about'} onClick={() => onTrackScreen('/about')}>
        {/* <img src={PNG.NewWiciAppLogo} className={styles.appIcon} /> */}
        <SVG.WiciLogo
          className={clsx([styles.icon, styles.appLogo])}
          hoverChange
          hoverIcon="white"
          defaultIcon="black"
          style={{
            width: '75px',
            height: '75px',
            marginTop: '-10px',
          }}
        />
      </Link>

      {/* <div className={styles.gap} /> */}

      <div className={styles.button} onClick={openAppstor}>
        <SVG.Apple
          className={styles.appStoreIcon}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      </div>

      <div className={styles.button} onClick={openPlayStore}>
        <SVG.PlayStore
          className={styles.playStoreIcon}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...userSettingsSelector(state),
  currentScreen: state.routing.location.pathname,
  unseenCount: getUnseenCount(state),
});

const mapDispatchToProps = dispatch => ({
  setPageBeforeShop: pageBeforeShop => dispatch(SinglePageActions.setPageBeforeShop(pageBeforeShop)),
  getUserClubMedal: auth => dispatch(UserSettingsActions.getUserClubMedal(auth)),
  getUserClubMedalSuccess: (auth, currentMedal) => dispatch(UserSettingsActions.getUserClubMedalSuccess(auth, currentMedal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLinks);
