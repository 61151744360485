import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M58.001,4h-52c-1.1,0-2,0.9-2,2v39c0,1.1,0.9,2,2,2h13v13l13-13h26c1.1,0,2-0.9,2-2V6 C60.001,4.9,59.101,4,58.001,4z M57.001,44h-26.2l-8.8,8.8V44h-15V7h50V44z M16.001,16.99h32v2h-32V16.99z M16.001,32.99h16v2h-16 V32.99z M16.001,24.99h32v2h-32V24.99z" />
    </svg>
  </SvgIcon>
);
