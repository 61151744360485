import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M38.003,53.001V54.1c0,3.301-2.7,5.9-6,5.9c-3.3,0-6-2.6-6-5.9v-1.099H38.003z M56.001,48.901l-0.2-0.801 l-4.3-21.199c-1.2-7.9-6.8-15.2-14.5-17.101v-0.8c0-3.266-2.401-5-5-5c-2.599,0-5,1.931-5,5.2v0.8c-7.7,1.9-13.3,9-14.5,16.9 l-4.3,21.199l0,0l-0.2,0.801c-0.2,1.199,0.8,2.1,2,2.1h44C55.201,51.001,56.201,50.101,56.001,48.901z" />
    </svg>
  </SvgIcon>
);
