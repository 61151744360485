import React from 'react';
import i18n from 'i18n-js';

import Header from './Header';
import FirstPlace from './FirstPlace';
import AnotherPlace from './AnotherPlace';
// import Background from './Background';

import styles from './styles.module.scss';

export default ({ charts, jumperRanking, pageIndex, onPressMore }) => {
  const { LastRankingPos } = jumperRanking;
  const showSlide = jumperRanking && jumperRanking.LastRankingPos;

  const renderContent = () => {
    if (jumperRanking) {
      if (LastRankingPos === 1) {
        return <FirstPlace {...{ LastRankingPos }} />;
      } else {
        return <AnotherPlace {...{ jumperRanking, pageIndex }} />;
      }
    } else {
      return null;
    }
  };

  return showSlide ? (
    <div id={'slide-3'} className={styles.container}>
      <Header {...{ charts }} />
      <div className={styles.content}>
        {renderContent()}
        <div className={styles.button} onClick={onPressMore}>
          {i18n.t('moreInformation.name')}
        </div>
      </div>
      {/* <Background /> */}
    </div>
  ) : null;
};
