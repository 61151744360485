import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 530 236.1">
      <path
        d="M135.3,53l-14,52.1h-5.9l-11.9-42.6h-0.5l-11.8,42.6h-5.9L71.5,53H77l11.2,42.9h0.5L100.4,53h6l11.7,42.9
		h0.5L129.8,53H135.3z"
      />
      <path d="M143.6,53h5.3v52.1h-5.3V53z" />
      <path d="M198.4,53v52.1H193l-25.2-41.8h-0.5v41.8H162V53h5.5l25.2,41.9h0.5V53H198.4z" />
      <path d="M248,53v52.1h-5.5l-25.2-41.8h-0.5v41.8h-5.3V53h5.5l25.2,41.9h0.5V53H248z" />
      <path d="M266.4,58v17.9h22.3v5h-22.3v19.2h23.1v5h-28.4V53h28.4v5H266.4z" />
      <path
        d="M314.4,83.8h-9v21.3h-5.3V53h17.2c8.7,0,16.1,4.9,16.1,15.4c0,9.3-5.8,14.2-13.1,15.2l13.6,21.5h-6.2
		L314.4,83.8z M305.5,78.7H317c5.6,0,10.8-3,10.8-10.4S322.6,58,317,58h-11.5V78.7z"
      />
      <path
        d="M340.3,91h5.4c0.9,7.1,5.5,9.9,11.3,9.9c6.4,0,10.4-3.6,10.4-9.1c0-5.2-3-7.8-8.4-9.6l-6.2-2
		c-7.3-2.3-11.3-7.3-11.3-13.7c0-9,7.1-14.4,15.4-14.4c9.4,0,15,5.7,16,14.3h-5.4c-0.9-5.9-4.5-9.1-10.6-9.1c-5.1,0-9.8,3-9.8,9
		c0,4.4,2.7,7.2,8,9l6.3,2c6.9,2.1,11.6,6.6,11.6,14.4c0,8.9-6.5,14.5-15.8,14.5C347.4,106,341.3,100.7,340.3,91z"
      />
      <path d="M389.1,53v6.6c0,9.7-2.4,12.9-7.6,13.3v-3.7c2.7-0.4,3.5-2.4,3.7-8.5h-3.9V53H389.1z" />
      <path
        d="M94.5,130.1c9.8,0,17,6.3,18.7,16.6h-5.7c-1.6-7.3-6.6-11.4-13-11.4c-9.7,0-15.1,8-15.1,21.8
		c0,13.8,5.5,21.8,15.1,21.8c6.5,0,11.5-4.1,13-11.4h5.7c-1.7,10.3-9,16.6-18.7,16.6c-13.1,0-20.7-10.3-20.7-27
		C73.7,140.4,81.4,130.1,94.5,130.1z"
      />
      <path d="M122.7,131h5.3v52.1h-5.3V131z" />
      <path
        d="M155.4,161.8h-9v21.3h-5.3V131h17.2c8.7,0,16.1,4.9,16.1,15.4c0,9.3-5.8,14.2-13.1,15.2l13.6,21.5h-6.2
		L155.4,161.8z M146.4,156.7H158c5.6,0,10.8-3,10.8-10.4S163.6,136,158,136h-11.5V156.7z"
      />
      <path
        d="M202.4,130.1c9.8,0,17,6.3,18.7,16.6h-5.7c-1.6-7.3-6.6-11.4-13-11.4c-9.7,0-15.1,8-15.1,21.8
		c0,13.8,5.5,21.8,15.1,21.8c6.5,0,11.5-4.1,13-11.4h5.7c-1.7,10.3-9,16.6-18.7,16.6c-13.1,0-20.7-10.3-20.7-27
		C181.7,140.4,189.3,130.1,202.4,130.1z"
      />
      <path d="M230.7,131h5.3v47.1h21.6v5h-26.9V131z" />
      <path d="M271.1,136v17.9h22.3v5h-22.3v19.2h23.1v5h-28.4V131h28.4v5H271.1z" />
      <path
        d="M318,176.8l18.9-18.1c3.2-3.1,6.4-6.8,6.4-12.9c0-6.3-4.1-10.7-10.1-10.7c-6.3,0-9.8,3.4-10.6,9.3h-5.3
		c0.8-8,6-14.3,15.9-14.3c9,0,15.4,6.4,15.4,15.7c0,7.3-3.4,12.1-8.2,16.7L324,177.8v0.4h24.7v4.9H318V176.8z"
      />
      <path
        d="M357.4,164.6v-15.1c0-12.8,7.6-19.4,17.3-19.4c9.8,0,17.4,6.6,17.4,19.4v15.1c0,12.8-7.6,19.4-17.4,19.4
		C365,184,357.4,177.4,357.4,164.6z M386.9,164.5v-15c0-9.7-5.1-14.5-12.2-14.5c-7,0-12.2,4.8-12.2,14.5v15
		c0,9.7,5.1,14.5,12.2,14.5C381.8,179,386.9,174.2,386.9,164.5z"
      />
      <path
        d="M400.8,176.8l18.9-18.1c3.2-3.1,6.4-6.8,6.4-12.9c0-6.3-4.1-10.7-10.1-10.7c-6.3,0-9.8,3.4-10.6,9.3h-5.3
		c0.8-8,6-14.3,15.9-14.3c9,0,15.4,6.4,15.4,15.7c0,7.3-3.4,12.1-8.2,16.7l-16.4,15.4v0.4h24.7v4.9h-30.7V176.8z"
      />
      <path d="M458.6,131v52.1h-5.2v-44.9h-0.5l-15.3,12V144l16.2-13H458.6z" />
    </svg>
  </SvgIcon>
);
