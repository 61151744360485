import React from 'react';
import clsx from 'clsx';

import { SVG } from '../../../../../../resources';

import styles from './index.module.scss';

const Comment = ({ item, commented, onComment, commentCount }) => (
  <div className={styles.container} onClick={() => onComment(item)}>
    {commented ? <SVG.CommentFilledNew className={clsx([styles.icon, styles.active])} /> : <SVG.CommentNew className={styles.icon} />}
    <div className={styles.counter}>{commentCount}</div>
  </div>
);

export default Comment;
