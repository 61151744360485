/* eslint-disable quotes */
import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Icon from '@material-ui/core/Icon';
import url from 'url';
import Modal from 'react-modal';
import 'react-alice-carousel/lib/alice-carousel.css';

import { AppHeader, NavigationBar } from '../../Common/HeaderFooter/AppHeader';
import { AppLinks, Loading, CardWithImageButton } from '../../../components';
import CloseButton from '../../../components/Common/CloseButton';
import qs from 'qs';

import { PNG } from '../../../resources';

import './styles.css';
import styles from './styles';
import scssStyles from './index.module.scss';
import { APP_STORAGE, MEDIA_TYPE, NAVIGATION_PREFIX, URL_REPLACEMENT } from '../../../constants/common';
import i18n from 'i18n-js';
import { Base64 } from '../../../lib/base64';
import { goBackOrReplace } from '../../../utils/History';
import { findLength } from '../../../utils/URL';
// import { Details } from '@material-ui/icons';

export default class NewsDetail extends Component {
  constructor(props) {
    super(props);
    // console.log(window.location.search);
    const queryResult = window.location.search ? qs.parse(window.location.search, { ignoreQueryPrefix: true }) : { id: false, token: false };
    const { id, token } = queryResult;
    this.state = {
      activeItem: 0,
      showModal: false,
      userId: id && token ? id : false,
    };
  }
  _handleContentClick = e => {
    const { isMobileApp } = this.props;
    const targetLink = e.target.closest('a');
    if (!targetLink || isMobileApp) return;
    e.preventDefault();
    const targetLinkParsed = url.parse(targetLink.href);
    // const newProtocol = targetLinkParsed.pathname;
    // console.log(targetLink.href, targetLinkParsed);
    if (targetLinkParsed.protocol === 'internal:') {
      this.props.history.push(targetLinkParsed.path);
    } else if (targetLinkParsed.pathname.includes(NAVIGATION_PREFIX.shop)) {
      const splitUrl = targetLinkParsed.pathname.split(NAVIGATION_PREFIX.shop); // Split the sub route
      const linkPath = splitUrl.pop(); // Fetch the path
      const wiciShopUrl = url.parse(i18n.t('shop.link_web').replace('/iframe?', ''));
      const shopUrl = `${wiciShopUrl.protocol}//${wiciShopUrl.hostname}`;
      const shopLink = `${shopUrl}${linkPath}`; // remove shop protocol and add shop url
      // console.log({ shopLink, linkPath });
      localStorage.setItem(APP_STORAGE.lastPath, window.location.pathname);
      window.open(shopLink, '_blank');
    } else if (targetLinkParsed.pathname.includes(NAVIGATION_PREFIX.iframe)) {
      const splitUrl = targetLinkParsed.pathname.split(`${NAVIGATION_PREFIX.iframe}/`); // Split the sub route
      const linkPath = `${splitUrl.pop()}${targetLinkParsed.search}`; // Fetch the path
      localStorage.setItem(APP_STORAGE.lastPath, window.location.pathname);
      this.props.history.push({ pathname: '/app-iframe', search: linkPath });
    } else if (targetLinkParsed.protocol === '') {
      this.props.history.push(targetLinkParsed.path);
    } else if (targetLink.target === '_blank') {
      window.open(targetLink.href, '_blank');
    } else {
      window.location.href = targetLink.href;
    }
  };
  processDetails = value => {
    const { auth } = this.props;
    const { userId } = this.state;
    const encUserID = Base64.encode(userId || auth.id);
    // const decodeUserID = Base64.decode(encUserID);
    // TODO update further more if required
    if (value) {
      const updateHtml = value.replaceAll(URL_REPLACEMENT.userID, encUserID);
      return updateHtml;
    } else {
      return value;
    }

    // console.log({ value: value.replace('$$USER_ID$$', encUserID), encUserID, decodeUserID });
  };

  render() {
    const { detail, auth, isMobileApp, history, windowWidth, windowHeight, unpublished } = this.props;
    const { activeItem, showModal } = this.state;
    if (!detail) {
      return (
        <div className={scssStyles.container}>
          <AppHeader />
          <Loading />
        </div>
      );
    }
    const media = detail.media_full_view;
    let mediaUrl = null;
    let mediaUrl2 = null;
    let mediaGeneratedThumb = null;
    let mediaType = 'image';
    if (media && media[0].url) {
      mediaType = media[0].type;
      mediaUrl = media[0].url;
    }
    if (media && media[0].thumb) {
      mediaGeneratedThumb = media[0].thumb;
    } else {
      mediaGeneratedThumb = PNG.PlayVideoPoster;
    }
    if (media && media[1] && media[1].url) {
      mediaUrl2 = media[1].url;
    }
    // const gallery = '';
    const gallery = findLength(detail) ? (findLength(detail.gallery) ? (findLength(detail.gallery.data) ? detail.gallery.data : '') : '') : '';
    // console.log(gallery)
    const media_gallery = detail.media_gallery;
    const handleOnDragStart = e => e.preventDefault();
    let items = [];
    if (media_gallery) {
      items = media_gallery.map((media_item, index) => {
        return (
          <div key={`imgWrapper${index}`} style={styles.galleryImageWrapper} onClick={() => this.setState({ showModal: true, activeItem: index })}>
            <img src={media_item.style} onDragStart={handleOnDragStart} key={`img${index}`} style={styles.galleryImage} alt="" />
          </div>
        );
      });
    }

    const onShowMore = selectedData => {
      if (selectedData['call-to-action'] === 'Generic') {
        const redirectUri = selectedData.target_url;
        const redirectUriParsed = url.parse(redirectUri);
        if (redirectUriParsed.protocol === 'internal:') {
          const pathParts = redirectUriParsed.path.split('/');
          const pathFirstLevel = pathParts[1];
          let path;
          switch (pathFirstLevel) {
            case 'ranking':
            case 'social':
            case 'e-training':
              path = redirectUriParsed.path;
              break;
            case 'news':
              if (pathParts.length > 2) {
                path = redirectUriParsed.path.replace('/news', '/web/node/article');
              } else {
                path = redirectUriParsed.path;
              }
              break;
            default:
              path = redirectUriParsed.path;
              break;
          }
          history.push(path);
        } else {
          window.location.href = redirectUri;
        }
      } else {
        const redirectUri = selectedData.link || selectedData.target_url;
        if (redirectUri && redirectUri.length > 0) {
          const URL = url.parse(redirectUri);
          if (URL.path && URL.path.length > 0) {
            const pathMapObj = {
              '/api/web/node/article': '/web/node/article',
              '/api/unpublished/node/article': '/unpublished/node/article',
            };
            const path = URL.path.replace(/\/api\/web\/node\/article|\/api\/unpublished\/node\/article/gi, function(matched) {
              return pathMapObj[matched];
            });
            // const path = URL.path.replace('/api/web/node/article', '/web/node/article');
            history.push(path);
          }
        }
      }
    };

    const getBack = () => {
      goBackOrReplace({ pathname: '/news' });
    };

    return (
      <div className={scssStyles.container}>
        <AppHeader />
        <div className={scssStyles.content}>
          {unpublished && !isMobileApp && (
            <>
              <div>
                <CardWithImageButton info={detail} onPress={() => onShowMore(detail)} />
              </div>
              <div className={scssStyles.separator}></div>
            </>
          )}
          <div className={scssStyles.titleContainer}>
            <div className={scssStyles.closeContainer}>
              <CloseButton onPress={() => getBack()} />
            </div>
            <h1 className={scssStyles.title}>{detail.title}</h1>
            <div className={scssStyles.subtitle}>{detail.subtitle}</div>
            <div className={scssStyles.imageContainer}>
              {mediaType === 'image' && media && mediaUrl2 && (
                <div style={styles.img2Wrapper}>
                  <div
                    style={{
                      ...styles.img,
                      ...styles.img2,
                      backgroundImage: `url(${mediaUrl})`,
                    }}
                  />
                  <div
                    style={{
                      ...styles.img,
                      ...styles.img2,
                      ...styles.img2Margin,
                      backgroundImage: `url(${mediaUrl2})`,
                    }}
                  />
                </div>
              )}
              {mediaType === 'image' && media && !mediaUrl2 && <div style={{ ...styles.img, backgroundImage: `url(${mediaUrl})` }} />}
              {mediaType === 'video' && (
                <div style={styles.videoWrapper}>
                  <video
                    controls="controls" preload="metadata" style={styles.video} playsInline poster={mediaGeneratedThumb}
                    autoPlay>
                    <source src={mediaUrl} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
            <div
              onClick={this._handleContentClick}
              className={scssStyles.description}
              dangerouslySetInnerHTML={{
                __html: this.processDetails(detail.body && detail.body.value),
              }}
            />

            {media_gallery && media_gallery.length > 0 && (
              <div style={styles.galleryContainer}>
                <AliceCarousel
                  responsive={{
                    0: { items: 1 },
                    960: { items: 3 },
                    1440: { items: 5 },
                  }}
                  dotsDisabled={true}
                  buttonsDisabled={true}
                  items={items}
                  ref={el => (this.Carousel = el)}
                  startIndex={activeItem}
                  onSlideChanged={e => {
                    this.setState({ activeItem: e.item });
                  }}
                />
                <div style={styles.dotContainer}>
                  {items.map((item, i) => (
                    <div key={i} onClick={() => this.Carousel.slideTo(i)} style={styles.dotWrapper}>
                      {activeItem === i ? (
                        <Icon
                          className="fa fa-circle"
                          style={{
                            ...styles.iconDot,
                            ...styles.activeDot,
                            marginRight: 10,
                          }}
                        />
                      ) : (
                        <Icon className="far fa-circle" style={{ ...styles.iconDot, marginRight: 10 }} />
                      )}
                    </div>
                  ))}
                </div>
                <div onClick={() => this.Carousel.slidePrev()} style={{ ...styles.arrow, ...styles.arrowLeft }}>
                  <Icon className="fa fa-chevron-left" style={{ ...styles.iconArrow, marginRight: 10 }} />
                </div>
                <div onClick={() => this.Carousel.slideNext()} style={{ ...styles.arrow, ...styles.arrowRight }}>
                  <Icon className="fa fa-chevron-right" style={{ ...styles.iconArrow, marginLeft: 10 }} />
                </div>
              </div>
            )}

            <div
              onClick={this._handleContentClick}
              style={styles.description}
              dangerouslySetInnerHTML={{
                __html: detail.body_bottom && detail.body_bottom.value,
              }}
            />
          </div>
        </div>
        <div style={styles.bottomContainer}>
          {/* <Button variant="contained" color="primary" style={styles.button} onClick={this.props.onPressMore}>*/}
          {/* {i18n.t('moreInformation.name')}*/}
          {/* </Button>*/}
        </div>
        {!isMobileApp && <NavigationBar active={1} history={history} windowWidth={windowWidth} windowHeight={windowHeight} />}
        {!isMobileApp && <AppLinks auth={auth} style={{ top: '160px' }} />}

        {media_gallery && media_gallery.length > 0 && (
          <Modal
            isOpen={showModal}
            style={{
              overlay: {
                display: 'flex',
                backgroundColor: 'black',
                zIndex: 9999,
                justifyContent: 'center',
                alignItems: 'center',
              },
              content: {
                display: 'flex',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                alignItems: 'center',
                backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
              },
            }}>
            <div style={styles.modalContainer}>
              <div onClick={() => this.Carousel.slidePrev()} style={{ left: 5 }}>
                <Icon className="fa fa-chevron-left" style={{ ...styles.modalIconArrow, marginRight: 10 }} />
              </div>
              {gallery.length && gallery[activeItem] && gallery[activeItem].type === MEDIA_TYPE.image && (
                <div
                  style={{
                    ...styles.modalImg,
                    backgroundImage: `url(${media_gallery[activeItem].original})`,
                  }}
                />
              )}
              {gallery.length && gallery[activeItem] && gallery[activeItem].type === MEDIA_TYPE.video && (
                <div style={styles.modalVideoContainer}>
                  <video controls="controls" preload="metadata" style={styles.modalVideo} autoPlay>
                    <source src={media_gallery[activeItem].original} type="video/mp4" />
                  </video>
                </div>
              )}
              <div onClick={() => this.Carousel.slideNext()} style={{ right: 0 }}>
                <Icon className="fa fa-chevron-right" style={{ ...styles.modalIconArrow, marginLeft: 10 }} />
              </div>
            </div>

            <div style={styles.modalCloseContainer} onClick={() => this.setState({ showModal: false })}>
              <Icon className="fa fa-times" style={styles.modalIconClose} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
