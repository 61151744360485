import { Colours, CommonStyles } from '../../../resources';
import { isLandscapeMode } from '../../../lib/utils';

export default {
  gridContainer: {
    ...CommonStyles.gridContainer,
    flexBasis: 'auto',
    flex: 1,
  },
  menuButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: Colours.modalGray,
  },
  reportDialogTitle: {
    color: Colours.primary,
    fontSize: 21,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    borderBottomColor: Colours.lightGray,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    textAlign: 'center',
  },
  socialWallDialogText: {
    color: '#6b6b6b',
    fontSize: 18,
    padding: 16,
    width: 500,
  },
  reportDialogText: {
    color: '#6b6b6b',
    fontSize: 15,
    padding: 16,
    width: 240,
  },
  reportButtons: {
    paddingBottom: 20,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  reportButton: {
    width: 180,
    marginBottom: 10,
  },
  menuContainer: {
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.12), 0px 4px 4px 1px rgba(0, 0, 0, 0.14), 0px 3px 4px 2px rgba(0, 0, 0, 0.08)',
  },
  gridTitle: {
    width: isLandscapeMode() ? '60vw' : '80vw',
    marginTop: 30,
    marginBottom: 15,
    color: Colours.darkBlack,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  item: {
    width: 350,
    margin: 15,
    borderRadius: 10,
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 9px 8px, rgba(0, 0, 0, 0.23) 0px 4px 8px',
    position: 'relative',
  },
  img: {
    width: 350,
    height: 350,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
  },
  video: {
    backgroundColor: Colours.black,
  },
  labelContainer: {
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    color: Colours.modalGray,
    fontSize: 21,
    width: '305px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  date: {
    marginTop: 3,
    color: '#6b6b6b',
    fontSize: 15,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingRight: 12,
    paddingBottom: 3,
  },
  iconsContainer: {},
  actionButton: {
    fontSize: 15,
    fontColor: Colours.modalGray,
    alignItems: 'flex-start',
  },
  icon: {
    width: 25,
    height: 25,
    color: Colours.modalGray,
  },
  actionDetailContainer: {
    paddingLeft: 12,
    paddingBottom: 12,
  },
  likeText: {
    color: Colours.darkBlack,
    fontSize: 16,
    fontWeight: 'bold',
  },
  commentText: {
    marginTop: 3,
    color: Colours.slideBar,
    fontSize: 15,
    fontWeight: 'bold',
  },
  cameraButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cameraButtonIcon: {
    width: 30,
    height: 30,
    color: Colours.modalGray,
  },
  cameraButtonText: {
    fontSize: 24,
    marginLeft: 10,
    color: Colours.darkCyan,
  },
};
