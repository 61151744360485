import React, { useState } from 'react';
import { connect } from 'react-redux';

import SocialActions from '../../../../redux/social';

import Popup from './Popup';
import UploadButton from './UploadButton';
import SearchPanel from './SearchPanel';

import styles from './index.module.scss';

const TopPanel = ({ createPost, setNewPopupOpen }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const openModal = file => {
    setUploadFile(file);
    setPopupOpen(true);
    setNewPopupOpen(false);
  };

  const closeModal = () => {
    setPopupOpen(false);
    setNewPopupOpen(false);
  };

  const renderPopup = () => {
    return popupOpen ? <Popup {...{ uploadFile, closeModal, onSend }} /> : null;
  };

  const onSend = values => {
    createPost({ ...values, file: uploadFile.data, type: uploadFile.type });
    setPopupOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <UploadButton {...{ openModal }} />
        <SearchPanel {...{ setNewPopupOpen }} />
      </div>
      {renderPopup()}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  createPost: payload => dispatch(SocialActions.createPost(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopPanel);
