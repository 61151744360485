import React from 'react';
import styles from './styles';
import Toggle from '../Toogle';
import FakeToggle from '../FakeToggle';
import { Colours } from '../../../resources';

const PrivacyToggle = ({
  onChange,
  value,
  mobile,
  title,
  fullText,
  shortText,
  showFullText,
  iconOn,
  iconOff,
  fullBtnText,
  shortBtnText,
  onClick,
  disabled,
  onLabel,
  offLabel,
  divider,
}) => {
  return (
    <React.Fragment>
      <div style={mobile ? styles.alalysisToogleWrapperMobile : styles.alalysisToogleWrapper}>
        <span style={mobile ? styles.alalysisToogleLabelMobile : styles.alalysisToogleLabel}>{title}</span>
        <div style={styles.alalysisToogleDescription}>{showFullText ? fullText : shortText}</div>
        <div style={styles.alalysisToogleDescriptionBtn} onClick={onClick}>
          {showFullText ? fullBtnText : shortBtnText}
        </div>
        <div style={mobile ? styles.alalysisToogleContainerMobile : styles.alalysisToogleContainer}>
          <img src={value ? iconOn : iconOff} style={mobile ? styles.alalysisToogleImageMobile : styles.alalysisToogleImage} alt="" />

          {disabled ? (
            <FakeToggle title={onLabel} mobile={mobile} />
          ) : (
            <Toggle onChange={onChange} value={value} mobile={mobile} onLabel={onLabel} offLabel={offLabel} />
          )}
        </div>

        {divider && <div style={{ backgroundColor: Colours.whiteGray, height: 1, marginTop: mobile ? 15 : 30 }} />}
      </div>
    </React.Fragment>
  );
};

export default PrivacyToggle;
