import React from 'react';
import i18n from 'i18n-js';

import { SVG } from '../../../../../resources';

import { CONTENT_TYPE } from '../../../../../constants/common';

import styles from './index.module.scss';

const UploadButton = ({ openModal }) => {
  const defineFileType = (type = '') => {
    if (type.includes(CONTENT_TYPE.image)) {
      return CONTENT_TYPE.image;
    }

    if (type.includes(CONTENT_TYPE.video)) {
      return CONTENT_TYPE.video;
    }

    return CONTENT_TYPE.empty;
  };

  const onFileSelect = event => {
    if (event.target.files[0]) {
      openModal({
        type: defineFileType(event.target.files[0].type),
        data: event.target.files[0],
      });
    }
  };

  return (
    <div className={styles.button}>
      <SVG.PhoneCameraOutline className={styles.buttonIcon} />
      <input
        id="upload" type={'file'} accept="image/*, video/*" multiple={false} hidden
        onChange={onFileSelect} />
      <label htmlFor="upload" className={styles.buttonText}>
        {i18n.t('uploadBtnText.name')}
      </label>
    </div>
  );
};

export default UploadButton;
