import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  setAuthToken: ['auth'],
  authTokenRefreshed: ['auth'],
  signOut: [],
  clear: [],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  token: null,
  refreshToken: null,
  id: null,
  expiresIn: null,
  lastFetchedTime: null,
  csrfToken: null,
  countryCode: null,
  languageCode: null,
  contactUrl: null,
};

/* ------------- Reducers ------------- */
const setAuthToken = (state, { auth }) => ({
  ...state,
  ...auth,
  lastFetchedTime: new Date().getTime(),
});
const clear = state => ({ ...state, ...defaultState });

/* ------------- Hookup Reducers To Types ------------- */
export const authReducer = createReducer(defaultState, {
  [Types.CLEAR]: clear,
  [Types.SET_AUTH_TOKEN]: setAuthToken,
  [Types.AUTH_TOKEN_REFRESHED]: setAuthToken,
});
