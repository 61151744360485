import { call, put, select } from 'redux-saga/effects';
import { getAllChannels as apiGetAllChannels, muteChannel as apiMuteChannel } from '../../lib/api';
import NotificationSettingsActions from '../notificationSettings';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

import { convertNotificationChannels } from '../../converters/notificationSettings';

import { getAuth } from '../selector/auth';

export function* getNotificationChannels() {
  try {
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const data = yield call(apiGetAllChannels, { auth: authRefreshed });

      if (!data.error) {
        yield put(NotificationSettingsActions.saveNotificationChannels(convertNotificationChannels(data)));
      } else {
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* muteNotificationChannel({ payload }) {
  try {
    const { channelId, status } = payload;
    yield put(NotificationSettingsActions.setMuteStatus({ channelId, status: !status }));
    const auth = yield select(getAuth);
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const data = yield call(apiMuteChannel, {
        auth: authRefreshed,
        channelId,
        status,
      });
      if (data.status === 403) {
        yield put(NotificationSettingsActions.setMuteStatus(payload));
      }
    }
  } catch (err) {
    yield put(NotificationSettingsActions.setMuteStatus(payload));
  }
}
