import React from 'react';

import { SVG } from '../../../../../../resources';

import styles from './styles';

const DotButton = ({ onPress }) => {
  return (
    <div style={styles.container} onClick={onPress}>
      <SVG.ThreeCubeDotNew style={{ ...styles.icon, fill: '#393939' }} />
    </div>
  );
};

export default DotButton;
