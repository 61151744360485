import { Icon, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Colours, StyleClass, SVG } from '../../../../../resources';
import { tagsMarker } from '../../../../../utils/URL';
import styles from './styles';

export default function SocialDetailDialogTitle(props) {
  const [socialItem, setSocialItem] = useState('');
  useEffect(() => {
    setSocialItem(props.socialItem);
  }, [props.socialItem]);

  return (
    <div style={styles.topTitleBox}>
      <div style={styles.userName}>
        <span style={styles.userNameTitle}>{socialItem.nickname}</span>
        <span style={styles.addTitleDate}>{props.formattedDate}</span>
      </div>
      <div style={styles.title}>{tagsMarker(socialItem.title)}</div>
      <div style={styles.likeCommentBtn}>
        <div style={styles.like}>
          <IconButton style={styles.likeBtn} aria-label="Like" onClick={$event => props.onLikePressed($event)}>
            {socialItem.votes ? (
              <SVG.HeartFilledNew
                style={{
                  ...styles.icon,
                  color: Colours.primary,
                }}
              />
            ) : (
              <SVG.HeartNew
                style={{
                  ...styles.icon,
                  color: Colours.modalGray,
                }}
              />
            )}
          </IconButton>
          <div style={styles.likeText} className={StyleClass.imageTouch} onClick={$event => props.onLikedPressed($event)}>
            {socialItem.vote_count}
          </div>
        </div>
        <div style={styles.comment}>
          <IconButton style={styles.messageBtn} aria-label="Comments">
            {socialItem.comments ? (
              <SVG.CommentFilledNew
                style={{
                  ...styles.icon,
                  color: Colours.primary,
                }}
              />
            ) : (
              <SVG.CommentNew
                style={{
                  ...styles.icon,
                  color: Colours.modalGray,
                }}
              />
            )}
          </IconButton>
          <div style={styles.commentText}>{socialItem.comment_count}</div>
        </div>
        <div style={styles.closeContainer} onClick={props.onClose}>
          <Icon className="fa fa-times" style={styles.iconClose} />
        </div>
      </div>
    </div>
  );
}
