import React, { useEffect, useRef } from 'react';

import '../../../components/App.css';
import styles from './index.module.scss';
import { Link, withRouter } from 'react-router-dom';

const FactSheetMenu = ({ menuEntryList, eTrainingPath }) => {
  const linkRef = useRef(null);

  useEffect(() => {
    // console.log({ histroy, });
    if (menuEntryList.length === 1 && linkRef && linkRef.current) {
      try {
        linkRef.current.click();
      } catch (error) {
        console.log(error);
      }
    }
  }, [menuEntryList]);

  return (
    <div className={styles.eTrainingContainer}>
      <div className={styles.container}>
        <div className={styles.buttonsContainer}>
          <div className={styles.innerButtonsContainer}>
            <div className={styles.arrowContainer}>
              <div className={styles.arrowup}></div>
            </div>
            <div className={styles.arrowContainer}></div>
            <div className={styles.arrowContainer}></div>
          </div>
        </div>
      </div>
      <div className={styles.greyContainer}>
        <div className={styles.listContainer}>
          {menuEntryList.map(entry => {
            let linkUrl = eTrainingPath + '/' + entry.id;
            return (
              <div key={entry.title} className={styles.menuLinkWrapper}>
                <Link className={styles.menuLinks} to={linkUrl} ref={linkRef}>
                  {'• ' + entry.menu_name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FactSheetMenu);
