import React from 'react';
import clsx from 'clsx';
import i18n from 'i18n-js';

import styles from './index.module.scss';

const Region = ({ onChange, value }) => (
  <div className={styles.container}>
    <div className={styles.button} onClick={() => onChange('')}>
      <div className={styles.checkbox}>
        <div className={clsx([styles.innerCheckbox, !value ? styles.active : null])} />
      </div>
      <div className={styles.buttonText}>{i18n.t('uploadMediaRadioButton1.name')}</div>
    </div>

    <div className={styles.buttonsGap} />

    <div className={styles.button} onClick={() => onChange('true')}>
      <div className={styles.checkbox}>
        <div className={clsx([styles.innerCheckbox, value ? styles.active : null])} />
      </div>
      <div className={styles.buttonText}>{i18n.t('uploadMediaRadioButton2.name')}</div>
    </div>
  </div>
);

export default Region;
