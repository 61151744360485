import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M52,9V37a20,20,0,0,1-40,0V9Zm3-3H9V37a23,23,0,0,0,46,0ZM32,33a5.38,5.38,0,0,0,5.4-5.36V24.3a5.4,5.4,0,1,
        0-10.8,0v3.3A5.51,5.51,0,0,0,32.06,33Zm5.64,2H26.4A5,5,0,0,0,21,39.58,3,3,0,0,0,21,40H43c.1-3-2.3-5-5.3-5Z"
      />
    </svg>
  </SvgIcon>
);
