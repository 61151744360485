import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M4,16V53a2,2,0,0,0,2,2H58a2,2,0,0,0,2-2V16ZM32,35.4,9.53,19H54.47ZM7,52V19.63L31.41,37.44l.1.05h.1a.86.86,0,0,0,
        .39.09h0a.9.9,0,0,0,.4-.09h.09l.1-.05L57,19.63V52Z"
      />
    </svg>
  </SvgIcon>
);
