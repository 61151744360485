import React from 'react';
import { Colours } from '../../../resources';

import Cup from './Cup';

import styles from './index.module.scss';

const Bar = ({ name, score, rank, maxValue = 400, lineColor, trophy, main, transparent = false, transparentBar = false }) => {
  const totalOpacity = transparent ? 0.4 : 1;

  const renderCup = () => {
    let gold = false;
    let color = false;

    switch (trophy) {
      case 'gold':
        gold = true;
        color = Colours.gold;
        break;

      case 'silver':
        gold = false;
        color = Colours.silver;
        break;
      case 'bronce':
        gold = false;
        color = Colours.bronce;
        break;

      default:
        break;
    }
    if (trophy && (gold || color)) {
      return <Cup counter={rank} gold={gold} color={color} />;
    } else {
      const rankStyle = main ? styles.largeRank : styles.rank;
      return <div className={rankStyle}>{+rank}</div>;
    }
  };

  const renderName = () => {
    const nameStyle = main ? styles.largeName : styles.name;
    return <div className={nameStyle}>{name}</div>;
  };

  const renderProgressGraph = () => {
    const progressValue = score / (maxValue / 100);
    const rightFffset = 100 - progressValue;

    let fillColor = null;

    if (lineColor === 'gold') {
      fillColor = Colours.gold;
    } else if (lineColor === 'silver') {
      fillColor = Colours.silver;
    } else if (lineColor === 'pink') {
      fillColor = Colours.lightPrimary;
    } else if (transparentBar) {
      fillColor = '#c1789c';
    }

    return (
      <div className={styles.progressContainer}>
        <div
          className={styles.slider}
          style={{
            right: `calc(${rightFffset}% - ${76 * ((100 - progressValue) / 100)}px)`,
            backgroundColor: fillColor,
          }}>
          {score}
        </div>
        <div
          className={styles.filledLine}
          style={{
            width: `${progressValue}%`,
            backgroundColor: fillColor,
          }}></div>
        <div className={styles.progressLine} style={{ backgroundColor: fillColor }}></div>
      </div>
    );
  };

  return (
    <div className={styles.container} style={{ opacity: totalOpacity }}>
      <div className={styles.topInfo}>
        {renderCup()}
        {renderName()}
      </div>
      {renderProgressGraph()}
    </div>
  );
};

export default Bar;
