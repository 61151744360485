import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path
        d="M51,33c3.31,0,6-2.69,6-6v-4c0-3.31-2.69-6-6-6s-6,2.69-6,6v4C45,30.31,47.69,33,51,33z M48,23c0-1.66,1.34-3,3-3
        s3,1.34,3,3v4c0,1.66-1.34,3-3,3s-3-1.34-3-3V23z M17.17,38H8.85C6.73,37.99,5.01,39.69,5,41.81c0,0,0,0,0,0l0,0V45H2v-3.18
        c0.01-3.77,3.07-6.83,6.84-6.82h0h8.33c1.62,0,3.19,0.58,4.43,1.63c-1.13,0.37-2.21,0.91-3.19,1.59C18.01,38.08,17.59,38,17.17,38z
         M62,41.82V45h-3v-3.18C58.99,39.71,57.28,38,55.17,38h-8.32c-0.42,0.01-0.83,0.08-1.22,0.22c-0.98-0.68-2.06-1.22-3.2-1.59
        c1.23-1.05,2.8-1.63,4.42-1.63h8.32C58.94,35,61.99,38.05,62,41.82z M13,33c3.31,0,6-2.69,6-6v-4c0-3.31-2.69-6-6-6s-6,2.69-6,6v4
        C7,30.31,9.69,33,13,33z M10,23c0-1.66,1.34-3,3-3s3,1.34,3,3v4c0,1.66-1.34,3-3,3s-3-1.34-3-3V23z M15,53v-4.49
        c-0.01-5.79,4.68-10.5,10.47-10.51c0,0,0,0,0,0h13.1c5.78,0.03,10.44,4.73,10.43,10.51V53h-3v-4.49c0.01-4.12-3.31-7.47-7.43-7.51
        H25.49c-4.14,0.01-7.5,3.37-7.49,7.51c0,0,0,0,0,0l0,0V53H15z M32,36c4.42,0,8-3.58,8-8v-6.72c0-4.42-3.58-8-8-8s-8,3.58-8,8V28
        C24,32.42,27.58,36,32,36z M27,21.28c0-2.76,2.24-5,5-5s5,2.24,5,5V28c0,2.76-2.24,5-5,5s-5-2.24-5-5V21.28z"
      />
    </svg>
  </SvgIcon>
);
