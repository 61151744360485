import React from 'react';
import i18n from 'i18n-js';

import styles from './styles.module.scss';

const Header = ({ charts }) => {
  const renderMonth = () => {
    if (charts.month_last_score_month > 0) {
      return <div className={styles.description}>{i18n.t(`monthNames.${charts.month_last_score_month - 1}`)}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{i18n.t('jumperRanking.name')}</div>
      {renderMonth()}
    </div>
  );
};

export default Header;
