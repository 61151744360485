import React, { Component } from 'react';
import * as TWEEN from '@tweenjs/tween.js';
import { SVG } from '../../../resources';
import styles from './styles';

export default class Fade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
      index: 0,
    };
    this.width = 0;
    this.timeout = null;
    this.divsContainer = null;
  }

  componentWillMount() {
    this.setState({ children: this.props.children });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeListener);
    this.setWidth();
  }

  componentWillUnmount() {
    this.willUnmount = true;
    window.removeEventListener('resize', this.resizeListener);
  }

  resizeListener = () => {
    this.setWidth();
  };

  applyStyle() {
    const fullwidth = this.width * this.props.children.length;
    this.divsContainer.style.width = `${fullwidth}px`;
    for (let index = 0; index < this.divsContainer.children.length; index++) {
      const eachDiv = this.divsContainer.children[index];
      if (eachDiv) {
        eachDiv.style.width = `${this.width}px`;
        eachDiv.style.left = `${index * -this.width}px`;
      }
    }
  }
  setWidth() {
    this.width = document.querySelector('.fade-wrapper').clientWidth;
    this.applyStyle();
  }
  _handlePrev = () => this.onFade('prev');
  _handleNext = () => this.onFade('next');
  onFade(type) {
    const { index, children } = this.state;
    if (type === 'prev') {
      this.fadeImages(index === 0 ? children.length - 1 : index - 1);
    } else {
      this.fadeImages((index + 1) % children.length);
    }
  }

  render() {
    const { children, index } = this.state;
    return (
      <div style={styles.slideContainer}>
        <div onClick={this._handlePrev} style={{ visibility: 'hidden' }}>
          <SVG.LeftArrow style={styles.iconArrow} />
        </div>
        <div style={styles.fadeContainer} className="fade-wrapper">
          <div style={styles.wrap} ref={wrap => (this.divsContainer = wrap)}>
            {children.map((each, key) => (
              <div key={key} style={{ ...styles.wrapChild, opacity: key === index ? 1 : 0, zIndex: key === index ? 1 : 0 }}>
                {each}
              </div>
            ))}
          </div>
        </div>
        <div onClick={this._handleNext} style={{ visibility: 'hidden' }}>
          <SVG.RightArrow style={styles.iconArrow} />
        </div>
      </div>
    );
  }

  fadeImages(newIndex) {
    let { index } = this.state;
    const { transitionDuration } = this.props;
    const value = { opacity: 0 };

    let animate = () => {
      if (this.willUnmount) {
        TWEEN.default.removeAll();
        return;
      }
      requestAnimationFrame(animate);
      TWEEN.default.update();
    };

    animate();
    const tween = new TWEEN.Tween(value)
      .to({ opacity: 1 }, transitionDuration)
      .onUpdate(value => {
        this.divsContainer.children[newIndex].style.opacity = value.opacity;
        this.divsContainer.children[index].style.opacity = 1 - value.opacity;
      })
      .start();
    tween.onComplete(() => {
      if (this.willUnmount) {
        return;
      }
      this.setState({ index: newIndex });
    });
  }
}
