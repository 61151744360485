import { call, put } from 'redux-saga/effects';
import {
  getUserSettings as apiGetUserSettings,
  getUserMembershipType as apiGetUserClubMedal,
  postUserSettings as apiPostUserSettings,
} from '../../lib/api';
import UserSettingsActions from '../userSettings';
import AuthActions from '../auth';
import Cookies from 'universal-cookie';

export function* getUserSettings({ auth }) {
  try {
    const ret = yield call(apiGetUserSettings, auth);
    yield put(UserSettingsActions.getUserSettingsSuccess(ret));
  } catch (err) {
    yield put(AuthActions.signOut());
  }
}

export function* getUserClubMedal({ auth }) {
  try {
    const ret = yield call(apiGetUserClubMedal, auth);
    yield put(UserSettingsActions.getUserClubMedalSuccess(ret.current_medal || 'None'));
  } catch (err) {
    yield put(AuthActions.signOut());
  }
}

export function* updateUserSettings({ auth, data }) {
  try {
    const ret = yield call(apiPostUserSettings, auth, data);
    yield put(UserSettingsActions.getUserSettingsSuccess(ret));
    if (!ret.web_matomo) {
      const cookies = new Cookies();
      const allCookies = Object.keys(cookies.getAll());

      allCookies.forEach(cookie => {
        if (cookie.startsWith('_pk_')) {
          cookies.remove(cookie, { path: '/' });
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
}
