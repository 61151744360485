import React from 'react';

import { SVG } from '../../../../resources';

import styles from './index.module.scss';

const Cup = ({ counter, cupStyle, counterStyle }) => (
  <div className={styles.container}>
    <SVG.Cup className={cupStyle} />
    <div className={counterStyle}>{counter}</div>
  </div>
);

export default Cup;
