import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 46 56">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <rect x="24.14" y="13" width="3" height="4" />
        <polygon points="30.57 39 27.07 39 27.07 21 20.57 21 20.57 24 24.07 24 24.07 39 20.57 39 20.57 42 30.57 42 30.57 39" />
        <path d="M44,0H2A2,2,0,0,0,0,2V54a2,2,0,0,0,2,2H44a2,2,0,0,0,2-2V2A2,2,0,0,0,44,0ZM3,53V3H7V53Zm40,0H9V3H43Z" />
      </g>
    </svg>
  </SvgIcon>
);
