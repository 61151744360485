import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { persistStore } from 'redux-persist';
// import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { history } from '../utils/History.js';

// creates the store
export default (rootReducer, rootSaga) => {
  // const history = createBrowserHistory();
  /* ------------- Redux Configuration ------------- */
  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware({});
  middleware.push(sagaMiddleware);
  middleware.push(routerMiddleware(history));

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(applyMiddleware(...middleware));

  const store = createStore(rootReducer, compose(...enhancers));
  const persistor = persistStore(store, null, null);
  // kick off root saga
  sagaMiddleware.run(rootSaga);
  return { store, history, persistor };
};
