import React from 'react';

import { SVG } from '../../../../../../resources';

import styles from './styles';

const PersonalEditButtons = ({ onDelete, onEdit }) => {
  return (
    <div style={styles.container}>
      <div style={styles.editButton} onClick={onEdit}>
        <SVG.EditNew style={{ ...styles.editIcon, fill: '#393939' }} />
      </div>

      <div style={styles.deleteButton} onClick={onDelete}>
        <SVG.TrashNew style={{ ...styles.deleteIcon, fill: '#393939' }} />
      </div>
    </div>
  );
};

export default PersonalEditButtons;
