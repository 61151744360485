import React from 'react';

import { SVG } from '../../../../../../resources';

import { prepareExternalLink } from '../../../../../../utils/ExternalLink';

import styles from './index.module.scss';

const openLink = (e, externalLink) => {
  e.preventDefault();
  window.open(prepareExternalLink(externalLink));
};

const Share = ({ externalLink }) =>
  externalLink ? (
    <a onClick={e => openLink(e, externalLink)} className={styles.container}>
      <SVG.ShareNew className={styles.icon} />
    </a>
  ) : null;

export default Share;
