import socketIO from 'socket.io-client';
import { ChartActions, NewsActions, SocialActions } from '../redux';
import config from '../config';

const channels = [
  {
    api: '/api/column1',
    action: (dispatch, auth) => dispatch(ChartActions.getChart(auth)),
  },
  {
    api: '/api/web/articles',
    action: dispatch => dispatch(NewsActions.newPostsAvailable(true)),
  },
  {
    api: '/api/get-media',
    action: dispatch => dispatch(SocialActions.newPostsAvailable(true)),
  },
];

class Socket {
  constructor() {
    this.socket = null;
    this.dispatch = null;
    this.auth = null;
  }
  _handleNewConnect = () => {
    this.socket.emit('register', this.auth);
    this.socket.on('message', msg => {
      if (msg.api) {
        channels.forEach(channel => {
          if (channel.api === msg.api && this.dispatch) {
            channel.action(this.dispatch, this.auth);
          }
        });
      }
      // console.log('message', msg);
    });
  };
  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }
  initSocket(auth) {
    if (!this.auth || this.auth.token !== auth.token) {
      this.auth = auth;
      if (this.socket) {
        this.unInitialize();
      }
      if (!this.socket) {
        this.socket = socketIO(config.socketServer);
        this.socket.on('connect', this._handleNewConnect);
      }
    }
  }
  unInitialize() {
    this.socket.disconnect();
    this.socket = null;
  }
}
const winnerSocket = new Socket();
export default winnerSocket;
