import React from 'react';

import createRoutes from './routes';
import Root from './containers/Root';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import rootSaga from './redux/sagas';
import configureStore from './redux/configureStore';
import { reducer } from './redux';

const { store, history } = configureStore(reducer, rootSaga);
const routes = createRoutes(history);
loadCSS('https://use.fontawesome.com/releases/v5.1.0/css/all.css', document.querySelector('#insertion-point-jss'));
export default () => <Root store={store}>{routes}</Root>;
