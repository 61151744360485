import React from 'react';
import { connect } from 'react-redux';

import { authSelector } from '../../../redux/selector';

import { AppHeader, NavigationBar, AppLinks, Loading } from '../../index';
import WebFactSheetsList from './WebFactSheetsList';

import styles from './index.module.scss';

const WebFactSheets = ({ auth, history }) => {
  const checkItemId = () => {
    const params = new URLSearchParams(window.location.search);
    const urlId = params.get('id');
    const urlToken = params.get('token');
    return urlId && urlToken;
  };

  if (!auth.id || !auth) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <AppHeader />
      <div className={styles.content}>
        <AppLinks auth={auth} />
        <WebFactSheetsList {...{ auth, history, checkItemId }} />
      </div>
      <NavigationBar active={3} history={history} />
    </div>
  );
};

const mapStateToProps = state => ({
  ...authSelector(state),
});

export default connect(mapStateToProps, null)(WebFactSheets);
