export const convertFact = data => {
  const { body, features, image, link, tags, title, type, uuid } = data;

  return {
    body,
    features,
    image,
    link,
    tags,
    title,
    type,
    uuid,
  };
};

export const convertFactSheets = data => {
  let byId = {};
  let allIds = [];

  data.forEach(item => {
    const { uuid } = item;

    allIds.push(uuid);

    byId = {
      ...byId,
      [uuid]: convertFact(item),
    };
  });

  return { byId, allIds };
};
