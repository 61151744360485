import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 47">
      <path d="M56.92,47.35c-1.82-1.51-2.42-3.63-2.86-5.82A18.39,18.39,0,0,1,53.71,39a4.16,4.16,0,0,0-1-2.82,2.51,2.51,0,0,1,.22-3.51,2.67,2.67,0,0,0,.71-2c0-3.74,0-7.49,0-11.23,0-1.76-.05-1.79-1.77-1.13-5.68,2.17-11.37,4.3-17,6.55a7.7,7.7,0,0,1-6.05,0c-8.63-3.39-17.3-6.67-25.95-10-.79-.3-1.56-.69-2.33-1-.59-.43-.69-.73,0-1.48a17.82,17.82,0,0,1,2.23-1.21c5-1.93,9.94-3.81,14.9-5.72,4.5-1.73,9-3.49,13.48-5.24a1.3,1.3,0,0,1,1.12,0,10.41,10.41,0,0,0,1.2.5c9.23,3.59,18,6.82,27.2,10.37a24.3,24.3,0,0,1,2.58,1.34c.64.34.84,1.12,0,1.48-1.86.76-3.71,1.57-5.59,2.27A1.75,1.75,0,0,0,56.27,18c0,4.28,0,8.57,0,12.85a2.77,2.77,0,0,0,.58,1.64,2.89,2.89,0,0,1,.33,3.71A1.82,1.82,0,0,0,57.12,38a6.46,6.46,0,0,0,3.5,3.59c.83.37,1.71.62,2.57.93v1.1c-1.42,1.88-3.49,2.75-5.53,3.69Z" />
      <path d="M49.44,25.79a12,12,0,0,0,0,1.47c.32,2.32-.8,3.92-2.63,5.09a33,33,0,0,1-14.49,5A12.08,12.08,0,0,1,28.86,37a36,36,0,0,1-10.21-3.59,19.39,19.39,0,0,1-2.75-1.84,4.18,4.18,0,0,1-1.69-3.69c.07-1.53,0-3.07,0-4.6s0-1.47,1.47-.93c4.13,1.57,8.27,3.1,12.37,4.77a9.26,9.26,0,0,0,7.32,0c4.15-1.67,8.36-3.23,12.55-4.81,1.46-.55,1.48-.52,1.5,1.07C49.45,24.19,49.44,25,49.44,25.79Z" />
    </svg>
  </SvgIcon>
);
