import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getSocial: ['payload'],
  getSocialCount: ['payload'],
  getSinglePost: ['payload'], // HOT FIX, SHOULD BE REMOVED
  getSocialSuccess: ['socials', 'loadingMoreAvailable', 'offset'],
  getLastPost: ['payload'],
  createPost: ['payload'],
  getSocialSinglePageSuccess: ['singlePage'],
  saveLastPost: ['payload'], // REMOVE
  saveCreatedPost: ['payload'],
  setFilterString: ['payload'],
  setFilterOwner: ['payload'],
  setFilterCountry: ['payload'],
  likeMedia: ['auth', 'media'],
  editMedia: ['payload'],
  deleteMedia: ['payload'],
  updateMedia: ['payload'],
  likeMediaSuccess: ['media'], // REMOVE
  updateMediaSuccess: ['payload'],
  deleteMediaSuccess: ['payload'],
  clear: [],
  requestFailed: [],
});

export const SocialTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  socials: [],
  fetchSocialAction: false,
  fetchSocial: false,
  loadingMoreAvailable: false,
  singlePage: null,
  filter: {
    search: null,
    country: null,
    owner: null,
  },
  total_count: 0,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state, fetchSocial: true });

const getSocialSuccess = (state, { socials, loadingMoreAvailable, offset }) => ({
  ...state,
  fetchSocial: false,
  socials: offset === 0 ? socials : [...state.socials, ...socials],
  loadingMoreAvailable,
});

const getSocialCountSuccess = (state, { payload }) => {
  // console.log({state, payload})
  return {
    ...state,
    total_count: payload || 0,
  };
};

// HOT FIX
const getSinglePost = state => ({
  ...state,
});

const setFilterString = (state, { payload: { value } }) => ({
  ...state,
  filter: {
    ...state.filter,
    search: value,
  },
});

const setFilterOwner = (state, { payload: { id } }) => ({
  ...state,
  filter: {
    ...state.filter,
    owner: id,
  },
});

const setFilterCountry = (state, { payload: { code } }) => ({
  ...state,
  filter: {
    ...state.filter,
    country: code,
  },
});

const failure = (state, { error }) => ({ ...state, fetchSocial: false, error });

const getSocialSinglePageSuccess = (state, { singlePage }) => ({
  ...state,
  singlePage,
});

const clear = state => ({ ...state, ...defaultState });

const likeMedia = state => ({ ...state, fetchSocialAction: true });

const likeMediaSuccess = (state, { media }) => {
  let socials = [...state.socials];
  let itemIndex = -1;
  socials.forEach((social, index) => {
    if (social.uuid === media.uuid) {
      itemIndex = index;
    }
  });
  if (itemIndex >= 0) {
    socials[itemIndex] = media;
  }
  return {
    ...state,
    socials: [...socials],
  };
};

const updateMediaSuccess = (state, { payload }) => {
  const { media } = payload;
  const newSocials = state.socials.map(item => {
    if (item.uuid === media.uuid) {
      return media;
    }
    return item;
  });
  return {
    ...state,
    socials: newSocials,
  };
};

const saveLastPost = (state, { payload: { post } }) => ({
  ...state,
  fetchSocial: false,
  socials: [post, ...state.socials],
});

const saveCreatedPost = (state, { payload: { post } }) => ({
  ...state,
  fetchSocial: false,
  socials: [post, ...state.socials],
});

const deleteMediaSuccess = (state, { payload: { uuid } }) => {
  const newSocials = state.socials.filter(item => {
    if (item.uuid === uuid) {
      return false;
    }
    return true;
  });
  return {
    ...state,
    socials: newSocials,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const socialReducer = createReducer(defaultState, {
  [Types.SET_FILTER_STRING]: setFilterString,
  [Types.SET_FILTER_OWNER]: setFilterOwner,
  [Types.SET_FILTER_COUNTRY]: setFilterCountry,
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_SOCIAL]: request,
  [Types.GET_SOCIAL_COUNT]: getSocialCountSuccess,
  [Types.GET_SINGLE_POST]: getSinglePost, // HOT FIX
  [Types.GET_SOCIAL_SUCCESS]: getSocialSuccess,
  [Types.GET_SOCIAL_SINGLE_PAGE_SUCCESS]: getSocialSinglePageSuccess,
  [Types.SAVE_LAST_POST]: saveLastPost,
  [Types.SAVE_CREATED_POST]: saveCreatedPost,
  [Types.CLEAR]: clear,
  [Types.LIKE_MEDIA]: likeMedia,
  [Types.LIKE_MEDIA_SUCCESS]: likeMediaSuccess, // REMOVE
  [Types.UPDATE_MEDIA_SUCCESS]: updateMediaSuccess,
  [Types.DELETE_MEDIA_SUCCESS]: deleteMediaSuccess,
});
