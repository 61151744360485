import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

import { StyleClass } from '../../../resources';
import { detectBrowser } from '../../../utils/Browser';
import { BROWSERS, NAVIGATION_PREFIX } from '../../../constants/common';

import styles from './index.module.scss';
import { authSelector } from '../../../redux/selector';

export const index = ({ onPress, eLearnings, ...props }) => {

  const [auth, setAuth] = useState({languageCode: "EN"});
  
  useEffect(() => {
    if (props.auth) {
      setAuth(props.auth);
    } else {
      setAuth({
        languageCode: "EN"
      });
    }
    return () => {
      setAuth({
        languageCode: ""
      })
    }
  }, [props]);
  

  // Fix links for navigation
  const getFixedLink = (url) => {
    // Include code as default
    let requestUrl = `${url}${auth.languageCode}`;
    // Update Greece & Czech Republic
    if (auth.languageCode.toUpperCase() === 'EL') {
      requestUrl = `${url}GR`;
    } else if (auth.languageCode.toUpperCase() === 'CS') {
      requestUrl = `${url}CZ`;
    }

    return requestUrl.startsWith(NAVIGATION_PREFIX.internal) ||
      requestUrl.startsWith(NAVIGATION_PREFIX.shop) ||
      requestUrl.startsWith(NAVIGATION_PREFIX.game) ||
      requestUrl.startsWith(NAVIGATION_PREFIX.iframe) ||
      requestUrl.startsWith(NAVIGATION_PREFIX.http) ||
      requestUrl.startsWith(NAVIGATION_PREFIX.https)
      ? requestUrl
      : `https://${requestUrl}`;
  };

  return (
    <Grid item xs={12}>
      <Grid container justify="center">
        {eLearnings.map((value, index) => (
          <Grid key={`${value}-${index}`} item>
            {/* FIX FOR SAFARI web / mobile */}
            {detectBrowser() === BROWSERS.safari ? (
              <a className={clsx([StyleClass.touch, styles.item])} href={getFixedLink(value.url)}>
                <div className={styles.img} style={{ backgroundImage: `url(${value.image})` }} />
                <div className={styles.labelContainer}>
                  <div className={styles.title}>{value.name}</div>
                </div>
              </a>
            ) : (
              <div className={clsx([StyleClass.touch, styles.item])} onClick={() => onPress(value.url)}>
                <div className={styles.img} style={{ backgroundImage: `url(${value.image})` }} />
                <div className={styles.labelContainer}>
                  <div className={styles.title}>{value.name}</div>
                </div>
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  ...authSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
