import React from 'react';

import EditDialog from './EditDialog';
import DeleteDialog from './DeleteDialog';
import ReportDialog from './ReportDialog';
import Video from './Video';
import Image from './Image';

import { CONTENT_TYPE } from '../../../../../constants/common';

import styles from './index.module.scss';

const Content = ({
  item,
  openDetails,
  editDialog,
  deleteDialog,
  reportDialog,
  onReportPost,
  onEditPost,
  onDeletePost,
  closeEditDialog,
  closeDeleteDialog,
  closeReportDialog,
}) => {
  const renderDialogs = () => {
    if (deleteDialog) {
      return <DeleteDialog {...{ onDeletePost, closeDeleteDialog }} />;
    }

    if (editDialog) {
      return (
        <EditDialog
          {...{
            initialValues: {
              title: item.title || '',
              url: item.external_link || '',
            },
            onEditPost,
            closeEditDialog,
          }}
        />
      );
    }

    if (reportDialog) {
      return <ReportDialog {...{ onReportPost, closeReportDialog }} />;
    }
  };

  const renderMedia = () => {
    switch (item.type) {
      case CONTENT_TYPE.video:
        return <Video url={item.video_url.trim()} placeholder={item.thumb} poster={item.thumb.trim()} {...{ openDetails }} />;

      case CONTENT_TYPE.image:
        return <Image url={item.image_url.trim()} {...{ openDetails }} />;

      default:
        return null;
    }
  };

  const onOpen = e => {
    if (e.target !== e.currentTarget) return;
    openDetails();
  };

  return (
    <div className={styles.container} onClick={onOpen}>
      {renderDialogs()}
      {renderMedia()}
    </div>
  );
};

export default Content;
