import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <rect style={{ fill: 'none' }} width="64" height="64" />
      <path d="M17,36,9.9,29A1.48,1.48,0,1,1,12,26.9l4.9,4.9L27.8,20.9A1.52,1.52,0,0,1,30,23ZM41.36,50H5V14H53V36l3,3V12.8A1.79,1.79,0,0,0,54.22,11H3.8A1.79,1.79,0,0,0,2,12.78H2V51.2A1.79,1.79,0,0,0,3.78,53H44.36ZM62,49a2.56,2.56,0,0,1-.87,1.86l-6,6a2.77,2.77,0,0,1-2,.81,2.82,2.82,0,0,1-2-.82l-17-17c-.6-.6-.57-1.49-.52-2.74V35.94c0-1.15,0-1.72-2-3.94l-.54-.61L29.2,29.54,33.73,25l1.93,1.93.6.53c2.22,2,2.9,2,3.94,2h2a2.49,2.49,0,0,1,1.86.54l17,17A2.83,2.83,0,0,1,62,49ZM35.61,36v1l5.63-5.63h-1c-1.39,0-2.44-.14-4.55-1.84l-1.9,1.9C35.51,33.6,35.61,34.58,35.61,36ZM60,49a.8.8,0,0,0-.23-.6L43.41,32l-7.18,7.18L52.51,55.5a.85.85,0,0,0,1.17,0l6.06-6.06A.6.6,0,0,0,60,49Z" />
    </svg>
  </SvgIcon>
);
