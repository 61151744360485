export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  editButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 5,
    paddingLeft: 20,
    height: '100%',
    cursor: 'pointer',
  },

  deleteButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 5,
    height: '100%',
    cursor: 'pointer',
  },

  editIcon: {
    width: 20,
    height: 20,
  },

  deleteIcon: {
    width: 24,
    height: 24,
  },
};
