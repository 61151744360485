import React from 'react';

import { SVG } from '../../../../../resources';

import styles from './styles.module.scss';

const Triangle = ({ counter }) => (
  <div className={styles.container}>
    <SVG.Triangle className={styles.triangle} />
    <div className={styles.counter}>{counter > 0 ? `+${counter}` : counter}</div>
  </div>
);

export default Triangle;
