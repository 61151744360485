import React from 'react';
import i18n from 'i18n-js';

import { SVG, Colours } from '../../../resources';

import { Gauge } from '../../../components';
// import Background from './Background';

import styles from './styles.module.scss';

// WIP
// SHOULD BE REFACTORED
export default ({ charts, pageCount, onPressMore }) => {
  let shopUrl = i18n.t('shop.link');
  let shopUrl2 = shopUrl.slice(shopUrl.indexOf('iframe') + 7);

  if (shopUrl2.includes('embedded=1')) {
    shopUrl2 = shopUrl2.replace('embedded=1', '');
  }

  const renderShopItem = () => {
    if (
      (!charts.favorite_item || charts.favorite_item.length === 0) &&
      (!charts.favorite_item_available || charts.favorite_item_available.length === 0)
    ) {
      return (
        <div className={styles.chartInnerView}>
          <div className={styles.cupCross}>
            <div className={styles.questionMark}>?</div>
            <div className={styles.cupText}>{i18n.t('chooseDesired.name')}</div>
          </div>
        </div>
      );
    }
    if (charts.favorite_item && charts.favorite_item_image) {
      return (
        <div className={styles.chartInnerView}>
          <div className={styles.cup}>
            <img src={charts.favorite_item_image} className={styles.cupImage} alt="Product" />
          </div>
        </div>
      );
    }
    if (!charts.favorite_item_available && charts.favorite_item_image) {
      return (
        <div className={styles.chartInnerView}>
          <div className={styles.cupCross}>
            <SVG.Close className={{ ...styles.cupCrossImg, fill: Colours.primary }} />
            <div className={styles.cupCrossText}>{i18n.t('noProduct.name')}</div>
          </div>
          <div className={styles.cup}>
            {charts.favorite_item_image && <img src={charts.favorite_item_image} className={styles.cupImage} alt="Product" />}
          </div>
        </div>
      );
    }
  };

  const totalPoints =
    charts.favorite_item_account_balance >= charts.favorite_item_required_points
      ? charts.favorite_item_required_points
      : charts.favorite_item_account_balance;

  return (
    <div id={`slide-${pageCount}`} className={styles.container}>
      {/* <div className={styles.darkBackground} /> */}
      <div className={styles.title}>{i18n.t('desiredProduct.name')}</div>

      <div className={styles.content}>
        <div className={styles.innerContent}>
          <div className={styles.gauge}>
            <Gauge
              className={styles.gauge}
              maximumTextValue={charts.favorite_item_required_points}
              maximumValue={charts.favorite_item_required_points}
              values={[charts.month_my_last_points, totalPoints]}
              colors={[Colours.primary, Colours.primary]}
            />
            <div className={styles.gaugeBackground} onClick={() => window.open(shopUrl2, '_blank')}>
              {renderShopItem()}
            </div>
          </div>
          <div className={styles.subContainer}>
            <div className={styles.labelBalance}>{charts.favorite_item_account_balance || 0}</div>
            {charts && !charts.favorite_item_available ? (
              <div className={{ flexDirection: 'row' }}>
                <div className={styles.labelText}>{i18n.t('shoppingPoints.name')}</div>
              </div>
            ) : (
              <div className={{ flexDirection: 'row' }}>
                <div className={styles.labelText}>
                  {i18n.t('xShoppingpointsOffY.name')} {charts.favorite_item_required_points || 0}
                </div>
              </div>
            )}
          </div>

          <div className={styles.buttonContainer}>
            <div className={styles.button} onClick={() => window.open(shopUrl2, '_blank')}>
              {i18n.t('changeDesiredProduct.name')}
            </div>
            <div className={styles.gap} />
            <div className={styles.button} onClick={onPressMore}>
              {i18n.t('moreInformation.name')}
            </div>
          </div>
        </div>
      </div>

      {/* <Background /> */}
    </div>
  );
};
