import { call, put, select } from 'redux-saga/effects';
import { getFactSheets as apiGetFactSheets } from '../../lib/api';
import FactSheetsActions from '../factSheets';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

import { convertFactSheets } from '../../converters/factSheets';

import { getAuth } from '../selector/auth';

export function* getFactSheetsList() {
  try {
    const auth = yield select(getAuth);

    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const data = yield call(apiGetFactSheets, authRefreshed);
      if (!data.error) {
        yield put(FactSheetsActions.saveFactSheets(convertFactSheets(data)));
      } else {
        yield put(FactSheetsActions.requestFailed('getFactSheets'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(FactSheetsActions.requestFailed('getFactSheets'));
  }
}
