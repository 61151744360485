import { createReducer, createActions } from 'reduxsauce';

import { DATA_STATUSES } from '../constants/common';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getNotificationChannels: [],
  saveNotificationChannels: ['payload'],
  muteNotificationChannel: ['payload'],
  setMuteStatus: ['payload'],
  clear: [],
});

export const NotificationSettingsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  status: DATA_STATUSES.initial,
};

/* ------------- Reducers ------------- */
const saveNotificationChannels = (state, { payload }) => ({
  ...state,
  data: {
    byId: payload.byId,
    allIds: payload.allIds,
  },
});

const setMuteStatus = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    byId: {
      ...state.data.byId,
      [payload.channelId]: {
        ...state.data.byId[payload.channelId],
        notify: payload.status,
      },
    },
  },
});

const clear = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const notificationSettingsReducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_NOTIFICATION_CHANNELS]: saveNotificationChannels,
  [Types.SET_MUTE_STATUS]: setMuteStatus,
  [Types.CLEAR]: clear,
});
