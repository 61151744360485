import { call, put } from 'redux-saga/effects';
import { getSinglePage as apiGetSinglePage } from '../../lib/api';
import { refreshOauth } from './auth';
import SinglePageActions from '../singlePage';
import AuthActions from '../auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';
import { getLocalInformation } from '../../resources/locale';

export function* getSinglePage({ auth, alias, isMobileApp }) {
  try {
    if (isMobileApp || alias === '/privacy') {
      let localData = getLocalInformation();
      let newAuth = {
        ...auth,
        languageCode: auth.languageCode || localData.languageCode,
        countryCode: auth.countryCode || localData.countryCode,
      };
      const ret = yield call(apiGetSinglePage, newAuth, alias);
      if (!ret.error) {
        yield put(SinglePageActions.getSinglePageSuccess(ret[0].body, alias));
      } else {
        yield put(SinglePageActions.requestFailed('getSinglePage'));
      }
    } else {
      const authRefreshed = yield call(refreshOauth, auth);
      if (authRefreshed) {
        const ret = yield call(apiGetSinglePage, authRefreshed, alias);
        if (!ret.error) {
          yield put(SinglePageActions.getSinglePageSuccess(ret[0].body, alias));
        } else {
          yield put(SinglePageActions.requestFailed('getSinglePage'));
          if (getNavigation().location.pathname !== '/') {
            localStorage.setItem('lastPath', getNavigation().location.pathname);
          }
          yield put(AuthActions.signOut());
        }
      }
    }
  } catch (err) {
    yield put(SinglePageActions.requestFailed('getSinglePage'));
  }
}
