import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props} viewBox="0 0 311.7 311.5">
    <svg width="150%" height="150%" x="-50" y="-85" viewBox="0 0 311.7 311.5">
      <g>
        <g>
          <path d="M10.5,146.3l-9.1-39.6H5l7.5,34l8-34h3.9l8.1,34l7.5-34h3.5l-9.1,39.6h-3.7L22.4,112l-8.2,34.3H10.5z" />
          <path d="M50.4,146.3v-39.6h3.5v39.6H50.4z" />
          <path d="M65.3,146.3v-39.6h3.8l19.2,33.9v-33.9h3.4v39.6h-3.8l-19.2-33.7v33.7H65.3z" />
          <path d="M103.2,146.3v-39.6h3.8l19.2,33.9v-33.9h3.4v39.6h-3.8l-19.2-33.7v33.7H103.2z" />
          <path d="M141,146.3v-39.6h22.8v3.1h-19.3v14.5h18v3.1h-18v15.9H164v3.1H141z" />
          <path
            d="M172.8,146.3v-39.6h12.8c2.1,0,3.8,0.2,5.2,0.6c1.4,0.4,2.6,1.1,3.7,2c2.2,1.9,3.3,4.5,3.3,7.9c0,3.3-1,5.9-3.1,7.9
			c-1,1-2.3,1.7-3.9,2.1c2,0.4,3.4,1.2,4.3,2.3c1.1,1.4,1.8,4.2,2.1,8.5c0.2,2.9,0.4,4.8,0.5,5.7c0.2,1,0.5,1.8,1,2.6h-4
			c-0.4-0.8-0.7-1.6-0.9-2.6c-0.2-0.9-0.3-2.7-0.4-5.5c-0.1-2.6-0.4-4.4-0.9-5.6c-0.5-1.2-1.3-2.1-2.4-2.8c-1.5-0.9-4-1.3-7.3-1.3
			h-6.4v17.7H172.8z M176.3,125.6h8.1c3.3,0,5.6-0.5,7-1.6c1.8-1.5,2.8-3.7,2.8-6.7c0-2.6-0.7-4.5-2.1-5.7c-1.4-1.2-3.6-1.8-6.6-1.8
			h-9.2V125.6z"
          />
          <path
            d="M205.2,135.2h3.4c0.3,2.3,1.1,4.2,2.4,5.7c1.8,2.1,4.4,3.2,7.6,3.2c2.7,0,4.8-0.7,6.4-2.1c1.7-1.5,2.6-3.6,2.6-6.1
			c0-2.6-1.1-4.7-3.3-6.1c-1.1-0.7-3.7-1.7-7.8-2.8c-3-0.8-5.2-1.7-6.6-2.7c-2.7-1.9-4.1-4.6-4.1-8.1c0-2.8,0.9-5.1,2.8-7.1
			c2.2-2.2,5.1-3.3,8.9-3.3c4.8,0,8.3,1.7,10.6,5.1c1,1.5,1.6,3.3,1.9,5.5h-3.7c-0.4-2.1-1-3.7-2-4.8c-1.6-1.8-3.9-2.7-6.8-2.7
			c-2.6,0-4.6,0.7-6.1,2.1c-1.5,1.4-2.2,3.1-2.2,5.3c0,2,0.7,3.6,2,4.8c1.3,1.2,3.6,2.2,6.9,3.1c2.9,0.8,4.9,1.4,5.9,1.8
			c1,0.4,2,0.9,3,1.6c2.8,2,4.2,4.7,4.2,8.3c0,3.2-1.1,5.9-3.3,8c-2.4,2.3-5.5,3.4-9.3,3.4c-4.7,0-8.2-1.5-10.6-4.5
			C206.5,140.8,205.6,138.3,205.2,135.2z"
          />
          <path d="M242.7,106.7v4.3c0,2.8-0.3,5-1,6.3c-0.6,1.4-1.8,2.4-3.4,3V118c1.4-0.8,2.1-2.4,2.1-4.7v-0.7h-1.7v-5.9H242.7z" />
        </g>
        <g>
          <g>
            <path
              d="M29.8,192.6h3.6c-0.4,3.2-1.3,5.9-2.9,8.2c-2.6,3.9-6.3,5.8-11.1,5.8c-5,0-9.1-2.2-12.1-6.7c-2.5-3.7-3.7-8.3-3.7-13.9
				c0-6.3,1.5-11.3,4.4-15.2c2.9-3.8,6.7-5.7,11.5-5.7c3.5,0,6.5,1.1,8.9,3.2c2.5,2.1,4,4.9,4.5,8.4h-3.6c-0.7-2.3-1.5-4-2.6-5.2
				c-2-2.2-4.4-3.3-7.4-3.3c-3.5,0-6.3,1.5-8.4,4.4c-2.5,3.4-3.7,7.8-3.7,13.2c0,5.3,1.1,9.6,3.4,12.9c1,1.5,2.3,2.6,3.8,3.5
				c1.5,0.9,3.2,1.3,5,1.3c3.2,0,5.8-1.4,7.9-4.2C28.6,197.6,29.4,195.3,29.8,192.6z"
            />
            <path d="M42,205.7v-39.6h3.5v39.6H42z" />
            <path
              d="M57,205.7v-39.6h12.8c2.1,0,3.8,0.2,5.2,0.6c1.4,0.4,2.6,1.1,3.7,2c2.2,1.9,3.3,4.5,3.3,7.9c0,3.3-1,5.9-3.1,7.9
				c-1,1-2.3,1.7-3.9,2.1c2,0.4,3.4,1.2,4.3,2.3c1.1,1.4,1.8,4.2,2.1,8.5c0.2,2.9,0.4,4.8,0.5,5.7c0.2,1,0.5,1.8,1,2.6h-4
				c-0.4-0.8-0.7-1.6-0.9-2.6c-0.2-0.9-0.3-2.7-0.4-5.5c-0.1-2.6-0.4-4.4-0.9-5.6c-0.5-1.2-1.3-2.1-2.4-2.8c-1.5-0.9-4-1.3-7.3-1.3
				h-6.4v17.7H57z M60.5,185h8.1c3.3,0,5.6-0.5,7-1.6c1.8-1.5,2.8-3.7,2.8-6.7c0-2.6-0.7-4.5-2.1-5.7c-1.4-1.2-3.6-1.8-6.6-1.8h-9.2
				V185z"
            />
            <path
              d="M115.8,192.6h3.6c-0.4,3.2-1.3,5.9-2.9,8.2c-2.6,3.9-6.3,5.8-11.1,5.8c-5,0-9.1-2.2-12.1-6.7c-2.5-3.7-3.7-8.3-3.7-13.9
				c0-6.3,1.5-11.3,4.4-15.2c2.9-3.8,6.7-5.7,11.5-5.7c3.5,0,6.5,1.1,8.9,3.2c2.5,2.1,4,4.9,4.5,8.4h-3.6c-0.7-2.3-1.5-4-2.6-5.2
				c-2-2.2-4.4-3.3-7.4-3.3c-3.5,0-6.3,1.5-8.4,4.4c-2.5,3.4-3.7,7.8-3.7,13.2c0,5.3,1.1,9.6,3.4,12.9c1,1.5,2.3,2.6,3.8,3.5
				c1.5,0.9,3.2,1.3,5,1.3c3.2,0,5.8-1.4,7.9-4.2C114.7,197.6,115.5,195.3,115.8,192.6z"
            />
            <path d="M128,205.7v-39.6h3.5v36.5h18.3v3.1H128z" />
            <path d="M157.2,205.7v-39.6H180v3.1h-19.3v14.5h18v3.1h-18v15.9h19.5v3.1H157.2z" />
            <path
              d="M223.1,202.6v3.1h-21.2c0.1-4,0.8-7.3,2.3-10c0.9-1.5,1.9-2.8,3-3.9c1.1-1.1,3.5-2.9,7-5.4c1.7-1.2,3.1-2.8,4.2-4.7
				c1-1.7,1.5-3.5,1.5-5.6c0-2.2-0.6-4-1.7-5.4c-1.4-1.7-3.3-2.5-5.7-2.5c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-2,3.4-2.1,5.9h-3.4
				c0.3-3.1,1.1-5.6,2.7-7.5c2-2.4,4.7-3.5,8.1-3.5c3.5,0,6.2,1.2,8.2,3.6c1.7,2.1,2.6,4.6,2.6,7.5c0,3.6-1.3,6.7-3.8,9.4
				c-0.9,0.9-1.6,1.6-2.2,2.1c-0.6,0.5-2.4,1.8-5.3,3.9c-2.2,1.6-3.8,3.2-4.8,4.9s-1.5,3.7-1.6,6H223.1z"
            />
            <path
              d="M241.5,165.3c3.9,0,6.9,1.9,8.9,5.8c1.7,3.3,2.6,8.2,2.6,14.6c0,7.2-1,12.4-3.1,15.8c-2.1,3.3-4.8,5-8.3,5
				c-7.7,0-11.6-6.7-11.6-20.2c0-6.9,0.9-12,2.8-15.4C234.9,167.2,237.8,165.3,241.5,165.3z M241.6,168.3c-3.2,0-5.5,2-6.8,6.1
				c-1,3.1-1.5,7.1-1.5,11.9c0,11.6,2.7,17.3,8.1,17.3c5.4,0,8.1-5.9,8.1-17.7c0-6-0.7-10.4-2-13.2
				C246.2,169.7,244.2,168.3,241.6,168.3z"
            />
            <path
              d="M280.4,202.6v3.1h-21.2c0.1-4,0.8-7.3,2.3-10c0.9-1.5,1.9-2.8,3-3.9c1.1-1.1,3.5-2.9,7-5.4c1.7-1.2,3.1-2.8,4.2-4.7
				c1-1.7,1.5-3.5,1.5-5.6c0-2.2-0.6-4-1.7-5.4c-1.4-1.7-3.3-2.5-5.7-2.5c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-2,3.4-2.1,5.9h-3.4
				c0.3-3.1,1.1-5.6,2.7-7.5c2-2.4,4.7-3.5,8.1-3.5c3.5,0,6.2,1.2,8.2,3.6c1.7,2.1,2.6,4.6,2.6,7.5c0,3.6-1.3,6.7-3.8,9.4
				c-0.9,0.9-1.6,1.6-2.2,2.1c-0.6,0.5-2.4,1.8-5.3,3.9c-2.2,1.6-3.8,3.2-4.8,4.9c-1,1.7-1.5,3.7-1.6,6H280.4z"
            />
            <path
              d="M298.8,165.3c3.9,0,6.9,1.9,8.9,5.8c1.7,3.3,2.6,8.2,2.6,14.6c0,7.2-1,12.4-3.1,15.8c-2.1,3.3-4.8,5-8.3,5
				c-7.7,0-11.6-6.7-11.6-20.2c0-6.9,0.9-12,2.8-15.4C292.2,167.2,295.1,165.3,298.8,165.3z M298.9,168.3c-3.2,0-5.5,2-6.8,6.1
				c-1,3.1-1.5,7.1-1.5,11.9c0,11.6,2.7,17.3,8.1,17.3c5.4,0,8.1-5.9,8.1-17.7c0-6-0.7-10.4-2-13.2
				C303.5,169.7,301.5,168.3,298.9,168.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);
