import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M53.57,48,37.63,32,53.57,16.09a4,4,0,0,0-5.66-5.66L32,26.37,16,10.43a4,4,0,1,0-5.66,5.66L26.32,32,10.37,48A4,4,0,0,0,16,53.63L32,37.68,47.92,53.63A4,4,0,1,0,53.57,48Z" />
    </svg>
  </SvgIcon>
);
