import { createReducer, createActions } from 'reduxsauce';

import { DATA_STATUSES } from '../constants/common';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // Sign In Actions
  getFactSheets: [],
  saveFactSheets: ['payload'],
  requestFailed: [],
  clear: [],
});

export const FactSheetsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  status: DATA_STATUSES.initial,
};

/* ------------- Reducers ------------- */
const request = state => ({ ...state });
const saveFactSheets = (state, { payload }) => ({
  ...state,
  data: {
    byId: payload.byId,
    allIds: payload.allIds,
  },
});
const failure = (state, { error }) => ({ ...state, error });
const clear = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const factSheetsReducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_FAILED]: failure,
  [Types.GET_FACT_SHEETS]: request,
  [Types.SAVE_FACT_SHEETS]: saveFactSheets,
  [Types.CLEAR]: clear,
});
