import React from 'react';
import i18n from 'i18n-js';

import Header from './Header';
import BestPerformance from './BestPerformance';
import AnnualRanking from './AnnualRanking';

import styles from './styles.module.scss';

export default ({ charts, onPressMore }) => (
  <div id={'slide-2'} className={styles.container}>
    <div className={styles.content}>
      <Header />
      <BestPerformance {...{ charts }} />
      <AnnualRanking {...{ charts }} />

      <div className={styles.buttonWrapper}>
        <div className={styles.button} onClick={onPressMore}>
          {i18n.t('moreInformation.name')}
        </div>
      </div>
    </div>
  </div>
);
