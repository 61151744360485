import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Icon from '@material-ui/core/Icon';
import { Link, GDPRToggle } from '../../components';
import { PNG } from '../../resources';
import { UserSettingsActions } from '../../redux';
import { isMobile } from '../../lib/utils';
import { authSelector, userSettingsSelector } from '../../redux/selector';
import styles from './styles';
import './styles.css';
import i18n from 'i18n-js';
class GDPRModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showSettings: false,
      cookiesFullText: false,
      analysisFullText: false,
      analysisChecked: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { userSettings, auth, getUserSettings } = props;
    if (!Object.keys(userSettings).length) {
      getUserSettings(auth);
    }
    if (userSettings.web_first_start_screen !== undefined && !Boolean(userSettings.web_first_start_screen) !== state.showModal && !isMobile()) {
      console.log('render');
      console.log(userSettings.web_first_start_screen);
      return {
        showModal: !Boolean(userSettings.web_first_start_screen),
      };
    }
    return null;
  }

  _handleUpdateUserSettings = data => {
    this.props.getUserSettingsSuccess({ web_first_start_screen: 1 });
    this.props.updateUserSettings(this.props.auth, { ...data, web_first_start_screen: 1 });
    this._handleSetGDPRCookie();
  };

  _handleSetGDPRCookie = () => {
    this.setState({ showModal: false });
  };

  renderMainCookies = () => {
    return (
      <div className="content">
        <div className="title">{i18n.t('gdprBannerTitle.name')}</div>
        <div>{i18n.t('gdprBannerBody.name')}</div>
        <div className="linksWrapper">
          <Link style={styles.link} onClick={() => this._handleUpdateUserSettings({})} to={'/privacy'}>
            {i18n.t('gdprBannerPrivacyLink.name')}
          </Link>
          <div style={styles.commonLink} onClick={() => this._handleUpdateUserSettings({ web_matomo: 0 })}>
            {i18n.t('gdprBannerDeclineLink.name')}
          </div>
          <div style={styles.commonLink} onClick={() => this.setState({ showSettings: true })}>
            {i18n.t('gdprBannerSettingsLink.name')}
          </div>
        </div>
        <div className="btnWrapper">
          <div onClick={() => this._handleUpdateUserSettings({ web_matomo: 1 })} className="btn">
            {i18n.t('gdprBannerButton.name')}
          </div>
        </div>
      </div>
    );
  };
  renderSettings = (cookiesFullText, analysisFullText, analysisChecked) => {
    return (
      <div className="content settingsContent">
        <div
          className="closeSettings"
          onClick={() =>
            this.setState({
              showSettings: false,
              cookiesFullText: false,
              analysisFullText: false,
              analysisChecked: this.props.userSettings.web_matomo,
            })
          }>
          <Icon className="fa fa-times" style={{ textDecoration: 'none' }} />
        </div>
        <div className="title">{i18n.t('privacySettingsTitle.name')}</div>
        <div>{i18n.t('privacySettingsBody.name')}</div>
        <div className="togglesWrapper">
          <GDPRToggle
            disabled
            title={i18n.t('privacyRequiredTitle.name')}
            showFullText={cookiesFullText}
            shortText={i18n.t('privacyRequiredBody.name')}
            fullText={i18n.t('privacyRequiredMoreBody.name')}
            onClick={() => this.setState({ cookiesFullText: !cookiesFullText })}
            onChange={() => null}
            value={true}
            fullBtnText={i18n.t('privacyShowLess.name')}
            shortBtnText={i18n.t('privacyFindOutMore.name')}
            iconOn={PNG.onCookies}
            iconOff={PNG.offCookies}
            onLabel={i18n.t('privacyToggleRequired.name')}
            divider
          />
          <GDPRToggle
            title={i18n.t('privacyAnalysisTitle.name')}
            showFullText={analysisFullText}
            shortText={i18n.t('privacyAnalysisBody.name')}
            fullText={i18n.t('privacyAnalysisMoreBody.name')}
            onChange={value => this.setState({ analysisChecked: value })}
            onClick={() => this.setState({ analysisFullText: !analysisFullText })}
            value={analysisChecked}
            fullBtnText={i18n.t('privacyShowLess.name')}
            shortBtnText={i18n.t('privacyFindOutMore.name')}
            iconOn={PNG.onAnalysis}
            iconOff={PNG.offAnalysis}
            divider
          />
        </div>
        <div className="btnWrapper btnSettingsWrapper">
          <Link style={styles.link} onClick={() => this._handleUpdateUserSettings({})} to={'/privacy'}>
            {i18n.t('gdprBannerPrivacyLink.name')}
          </Link>
          <div onClick={() => this._handleUpdateUserSettings({ web_matomo: analysisChecked ? 1 : 0 })} className="btn">
            {i18n.t('privacySettingsSaveButton.name')}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { showModal, showSettings, cookiesFullText, analysisFullText, analysisChecked } = this.state;
    return (
      <Modal
        isOpen={showModal}
        style={{
          overlay: styles.overlay,
          content: styles.content,
        }}>
        {showSettings ? this.renderSettings(cookiesFullText, analysisFullText, analysisChecked) : this.renderMainCookies(analysisChecked)}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  ...authSelector(state),
  ...userSettingsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getUserSettings: auth => dispatch(UserSettingsActions.getUserSettings(auth)),
  updateUserSettings: (auth, data) => dispatch(UserSettingsActions.updateUserSettings(auth, data)),
  getUserSettingsSuccess: (auth, data) => dispatch(UserSettingsActions.getUserSettingsSuccess(auth, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GDPRModal);
