import indigo from '@material-ui/core/colors/indigo';
import teal from '@material-ui/core/colors/teal';

export default {
  light: {
    palette: {
      type: 'light',
      primary: {
        contrastText: '#fff',
        dark: 'rgb(44, 56, 126)',
        light: 'rgb(101, 115, 195)',
        main: '#52b9f1',
      },
      secondary: {
        main: teal[500],
      },
    },
  },
  dark: {
    palette: {
      type: 'dark',
      primary: {
        main: teal[500],
      },
      secondary: {
        main: indigo[500],
      },
    },
  },
};
