import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export default props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.19 68.28">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Ebene_1" data-name="Ebene 1">
          <path d="M33,50a13.51,13.51,0,0,1,1.64-7H17A11,11,0,0,0,6,53.91V58H35.41A17,17,0,0,1,33,50Z" />
          <path d="M32,39A12.21,12.21,0,0,0,44,27V18A12.21,12.21,0,0,0,32,6,12.15,12.15,0,0,0,20,18v9A12.15,12.15,0,0,0,32,39Z" />
          <path d="M67.64,46a.87.87,0,0,0-.82-.57H55.29L51.73,34.59a.85.85,0,0,0-1.08-.54.87.87,0,0,0-.55.54L46.53,45.44H35a.86.86,0,0,0-.86.86.92.92,0,0,0,.35.69l9.33,6.92-3.56,11a.86.86,0,0,0,.55,1.08.85.85,0,0,0,.77-.13l9.32-6.76,9.33,6.78a.85.85,0,0,0,1.2-.17.82.82,0,0,0,.12-.8L58,53.91,67.32,47A.87.87,0,0,0,67.64,46Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
