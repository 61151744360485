import { Colours } from '../../../../../resources';

export default {
  topTitleBox: {
    backgroundColor: Colours.whiteGray,
    padding: '1rem',
    position: 'relative',
    order: '1',
  },
  userName: {},
  userNameTitle: {
    display: 'block',
    minHeight: '16px',
  },
  addTitleDate: {
    display: 'block',
    minHeight: '16px',
  },
  title: {
    display: 'none',
  },
  titleTags: {
    fontWeight: 'normal',
  },
  closeContainer: {
    display: 'flex',
    alignItems: 'center',
    // marginRight: '10px'
    padding: '1rem',
    background: Colours.offBlack,
    height: '70px',
  },
  iconClose: {
    fontSize: '3vmax',
    fontWeight: 'regular',
    color: 'white',
  },
  likeCommentBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  like: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  likeBtn: {
    padding: '10px 5px',
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  messageBtn: {
    padding: '10px 5px',
  },
  icon: {},
  likeText: {},
};
