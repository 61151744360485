import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import qs from 'qs';
import Config from '../../config';

const { unpublishedToken } = Config;

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      qs.parse(props.location.search, { ignoreQueryPrefix: true }).token === unpublishedToken ||
      (localStorage.getItem('authToken') && localStorage.getItem('authToken') === unpublishedToken) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
    }
  />
);
