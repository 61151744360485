import { call, put } from 'redux-saga/effects';
import { getFactSheets as apiGetFactSheets, getElearningItems as apiGetElearningItems } from '../../lib/api';
import ELearningActions from '../elearning';
import AuthActions from '../auth';
import { refreshOauth } from './auth';
import { getNavigation, localStorage } from '../../containers/BaseComponent';

export function* getFactSheets({ auth }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetFactSheets, authRefreshed);
      if (!ret.error) {
        yield put(ELearningActions.getFactSheetsSuccess(ret));
      } else {
        yield put(ELearningActions.requestFailed('getFactSheets'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(ELearningActions.requestFailed('getFactSheets'));
  }
}

export function* getELearningItems({ auth }) {
  try {
    const authRefreshed = yield call(refreshOauth, auth);
    if (authRefreshed) {
      const ret = yield call(apiGetElearningItems, authRefreshed);
      if (!ret.error) {
        yield put(ELearningActions.getElearningItemsSuccess(ret));
      } else {
        yield put(ELearningActions.requestFailed('getElearningItems'));
        if (getNavigation().location.pathname !== '/') {
          localStorage.setItem('lastPath', getNavigation().location.pathname);
        }
        yield put(AuthActions.signOut());
      }
    }
  } catch (err) {
    yield put(ELearningActions.requestFailed('getElearningItems'));
  }
}
