/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import clsx from 'clsx';
import qs from 'qs';
import { useDebouncedCallback } from 'use-debounce';

import { userSettingsSelector } from '../../../../redux/selector';

import GDPRModal from '../../../GDPRModal';

import { trackScreen } from '../../../../lib/matomo';

import { isMobile } from '../../../../lib/utils';

import { Link } from '../../../../components';

import { SVG } from '../../../../resources';

import styles from './index.module.scss';
import { APP_STORAGE } from '../../../../constants/common';

const NavigationBar = ({ history, active, userSettings, onPerformanceMenuClick, onETrainingMenuClick }) => {
  const [showPerformanceMenu, setShowPerformanceMenu] = useState(false);
  const [showETrainingMenu, setShowETrainingMenu] = useState(false);
  const [mobileMode, setMobileMode] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const onResize = useDebouncedCallback(() => {
    if (isMobile() !== mobileMode) {
      setShowPerformanceMenu(false);
      setShowETrainingMenu(false);
    }
    setMobileMode(isMobile());
  }, 200);

  const menuItems = [
    {
      icon: SVG.lineGraphSolid,
      title: i18n.t('myPerformance.name'),
      type: 'svg',
      to: '/',
    },
    {
      icon: SVG.News,
      title: i18n.t('new.name'),
      type: 'svg',
      to: '/news',
    },
    {
      icon: SVG.Social,
      title: i18n.t('socialWall.name'),
      type: 'svg',
      to: '/social',
    },
    {
      icon: SVG.ETraining,
      title: i18n.t('eTraining.name'),
      type: 'svg',
      to: '/e-learning',
    },
    {
      icon: SVG.Info,
      title: i18n.t('info.name'),
      type: 'svg',
      to: '/info',
    },
  ];

  useEffect(() => {
    const queryResult = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const { id, token } = queryResult;
    const verifyInApp = localStorage.getItem(APP_STORAGE.webView);
    if ((id && token) || verifyInApp) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }

    window.addEventListener('resize', onResize.callback);

    return () => {
      window.removeEventListener('resize', onResize.callback);
    };
  }, []);

  const onMouseOver = index => {
    if (!mobileMode) {
      if (index === 0) {
        setShowPerformanceMenu(true);
        setShowETrainingMenu(false);
      } else if (index === 3) {
        setShowPerformanceMenu(false);
        setShowETrainingMenu(true);
      } else {
        setShowPerformanceMenu(false);
        setShowETrainingMenu(false);
      }
    }
  };

  const onSubMenuClick = (index, isFirstTab) => {
    if (isFirstTab && onPerformanceMenuClick) {
      onPerformanceMenuClick(index);
    } else if (!isFirstTab && onETrainingMenuClick) {
      onETrainingMenuClick(index);
    } else {
      if (isFirstTab) {
        localStorage.setItem('MyPerformanceNavigationIndex', index);
        history.push('/');
      } else {
        localStorage.setItem('ETrainingNavigationIndex', index);
        history.push('/e-training', {
          navigationVisible: false,
        });
      }
    }
  };

  const onTrackScreen = path => {
    if (userSettings.web_matomo) trackScreen(path);
  };

  const onClickMenuItem = ({ index, to }) => {
    onTrackScreen(to);
    if (mobileMode) {
      switch (index) {
        case 0:
          setShowPerformanceMenu(!showPerformanceMenu);
          setShowETrainingMenu(false);
          break;

        case 3:
          setShowETrainingMenu(!showETrainingMenu);
          setShowPerformanceMenu(false);
          break;

        default:
          setShowPerformanceMenu(false);
          setShowETrainingMenu(false);
          break;
      }
    }
  };

  const renderMenuItem = (item, index) => (
    <Link
      className={styles.menuLink}
      key={item.title}
      onClick={() => onClickMenuItem({ index, to: item.to })}
      to={item.to}
      onMouseOver={() => onMouseOver(index)}>
      <item.icon className={clsx([styles.icon, active === index ? styles.active : null])} />
    </Link>
  );

  return !isMobileView ? (
    <div className={styles.container}>
      <GDPRModal />
      {showPerformanceMenu && <NavigationMenuMyPerformance onClick={onSubMenuClick} onMouseLeave={() => setShowPerformanceMenu(false)} />}
      {showETrainingMenu && <NavigationMenuELearning onClick={onSubMenuClick} onMouseLeave={() => setShowETrainingMenu(false)} />}

      <div className={styles.footer}>
        <div className={styles.innerFooter}>{menuItems.map((item, index) => renderMenuItem(item, index))}</div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = state => ({
  ...userSettingsSelector(state),
});

export default connect(mapStateToProps)(NavigationBar);

export const NavigationMenuMyPerformance = ({ onClick, onMouseLeave }) => {
  const performanceItems = [i18n.t('monthlyRanking.name'), i18n.t('annualRanking.name'), i18n.t('jumperRanking.name'), i18n.t('desiredProduct.name')];

  const onItemClick = index => {
    onClick(index, true);
    onMouseLeave();
  };

  return (
    <div
      className={styles.modal}
      onMouseLeave={() => {
        onMouseLeave && onMouseLeave();
      }}>
      {performanceItems.map((item, index, arr) => (
        <>
          <div className={styles.subMenuItemText} onClick={() => onItemClick(index)} key={`${item}`}>
            {item}
          </div>
          {arr.length - 1 !== index && <div key={`separator-${index}`} className={styles.separator} />}
        </>
      ))}
    </div>
  );
};

// DUPLICATE NavigationMenuMyPerformance Modal
export const NavigationMenuELearning = ({ onClick, onMouseLeave }) => {
  const elearningItems = [i18n.t('samsungFact.name'), i18n.t('startQuiz.name'), i18n.t('startEtraining.name')];

  const onItemClick = index => {
    onClick(index, false);
    onMouseLeave();
  };

  return (
    <div
      className={styles.modal}
      onMouseLeave={() => {
        onMouseLeave && onMouseLeave();
      }}>
      {elearningItems.map((item, index, arr) => (
        <>
          <div className={styles.subMenuItemText} onClick={() => onItemClick(index)} key={`${item}`}>
            {item}
          </div>
          {arr.length - 1 !== index && <div key={`separator-${index}`} className={styles.separator} />}
        </>
      ))}
    </div>
  );
};
