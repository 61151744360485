import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../helpers/PrivateRoute';
import loadable from 'loadable-components';
import { ConnectedRouter } from 'react-router-redux';
import { Loading } from '../components';

const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ '../containers/Dashboard'), {
  LoadingComponent: () => <Loading />,
});
const MyPerformance = loadable(() => import(/* webpackChunkName: "Home" */ '../containers/MyPerformance'), {
  LoadingComponent: () => <Loading />,
});
const RankDetailPage = loadable(() => import(/* webpackChunkName: "Home" */ '../containers/MyPerformance/RankDetailPage'), {
  LoadingComponent: () => <Loading />,
});
const Social = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/Social'), {
  LoadingComponent: () => <Loading />,
});
const News = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/News'), {
  LoadingComponent: () => <Loading />,
});
const ETraining = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining'), {
  LoadingComponent: () => <Loading />,
});
const ETrainingStart = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/ELearningStart'), {
  LoadingComponent: () => <Loading />,
});
const ELearningDownload = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/ELearningDownload'), {
  LoadingComponent: () => <Loading />,
});

const OAuthCallback = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/OauthReceiver'), {
  LoadingComponent: () => <Loading />,
});
const NodeDetail = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/NodeDetail'), {
  LoadingComponent: () => <Loading />,
});
const Login = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/Auth/Login'), {
  LoadingComponent: () => <Loading />,
});
const Info = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/Info'), {
  LoadingComponent: () => <Loading />,
});
const Notifications = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/Notifications'), {
  LoadingComponent: () => <Loading />,
});
// const About = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/About'), {
//   LoadingComponent: () => <Loading />
// });
const SimplePage = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/SinglePage'), {
  LoadingComponent: () => <Loading />,
});
const NotificationSettings = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/NotificationSettings'), {
  LoadingComponent: () => <Loading />,
});
const IFrame = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/IFrame'), {
  LoadingComponent: () => <Loading />,
});
const WebFactSheets = loadable(() => import(/* webpackChunkName: "Social" */ '../components/ETraining/WebFactSheets'), {
  LoadingComponent: () => <Loading />,
});
const WebFactSheetsDetail = loadable(() => import(/* webpackChunkName: "Social" */ '../components/ETraining/WebFactSheets/WebFactSheetsDetail'), {
  LoadingComponent: () => <Loading />,
});

const WebFactSheetsSets = loadable(() => import(/* webpackChunkName: "Social" */ '../components/ETraining/WebFactSheetsSets'), {
  LoadingComponent: () => <Loading />,
});

const MobileFactSheets = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/FactSheets'), {
  LoadingComponent: () => <Loading />,
});
const MobileFactSheetsDetail = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/FactSheets/FactSheetsDetail'), {
  LoadingComponent: () => <Loading />,
});
const WebQuiz = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/QuizWeb'), {
  LoadingComponent: () => <Loading />,
});
const WebQuizResult = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/QuizWeb/WebQuizResult'), {
  LoadingComponent: () => <Loading />,
});
const MobileQuiz = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/Quiz'), {
  LoadingComponent: () => <Loading />,
});
const MobileQuizResult = loadable(() => import(/* webpackChunkName: "Social" */ '../containers/ETraining/Quiz/QuizResult'), {
  LoadingComponent: () => <Loading />,
});
const MobileScoreTableMonthly = loadable(
  () => import(/* webpackChunkName: "OAuthCallback" */ '../containers/MyPerformance/MobileRankDetail/ScoreTableMonthly'),
  {
    LoadingComponent: () => <Loading />,
  },
);
const MobileScoreTableYearly = loadable(
  () => import(/* webpackChunkName: "OAuthCallback" */ '../containers/MyPerformance/MobileRankDetail/ScoreTableYearly'),
  {
    LoadingComponent: () => <Loading />,
  },
);
const MobileAccountStatement = loadable(
  () => import(/* webpackChunkName: "OAuthCallback" */ '../containers/MyPerformance/MobileRankDetail/AccountStatement'),
  {
    LoadingComponent: () => <Loading />,
  },
);
const MobileRankingInfo = loadable(
  () => import(/* webpackChunkName: "OAuthCallback" */ '../containers/MyPerformance/MobileRankDetail/MobileRankingInfo'),
  {
    LoadingComponent: () => <Loading />,
  },
);

const AppiFrame = loadable(() => import(/* webpackChunkName: "OAuthCallback" */ '../containers/AppiFrame'), {
  LoadingComponent: () => <Loading />,
});

export default history => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route component={Login} exact path={`${process.env.PUBLIC_URL}/login`} />
      <Dashboard history={history}>
        <Route component={MyPerformance} exact path={`${process.env.PUBLIC_URL}/`} />
        <Route component={MyPerformance} exact path={`${process.env.PUBLIC_URL}/ranking`} />
        <Route component={RankDetailPage} exact path={`${process.env.PUBLIC_URL}/rank-detail`} />
        <Route component={Social} exact path={`${process.env.PUBLIC_URL}/social`} />
        <Route component={News} exact path={`${process.env.PUBLIC_URL}/news`} />
        <PrivateRoute component={News} exact path={`${process.env.PUBLIC_URL}/news-unpublished`} />
        <Route component={ETraining} exact path={`${process.env.PUBLIC_URL}/e-training`} />
        <Route component={ETraining} exact path={`${process.env.PUBLIC_URL}/e-learning`} />
        <Route component={ETrainingStart} exact path={`${process.env.PUBLIC_URL}/e-training/start`} />
        <Route component={ELearningDownload} exact path={`${process.env.PUBLIC_URL}/e-training/download`} />
        <Route component={ELearningDownload} exact path={`${process.env.PUBLIC_URL}/e-training/downloadquiz`} />
        <Route component={Info} exact path={`${process.env.PUBLIC_URL}/info`} />
        <Route component={Info} exact path={`${process.env.PUBLIC_URL}/info/contact`} />
        <Route component={OAuthCallback} exact path={`${process.env.PUBLIC_URL}/callback`} />
        <Route component={NodeDetail} exact path={`${process.env.PUBLIC_URL}/web/node/article/:uuid`} />
        <PrivateRoute component={NodeDetail} exact path={`${process.env.PUBLIC_URL}/unpublished/node/article/:uuid`} />
        <Route component={NodeDetail} exact path={`${process.env.PUBLIC_URL}/app/node/article/:uuid`} />
        <Route component={NodeDetail} exact path={`${process.env.PUBLIC_URL}/news/:alias`} />
        <Route component={Notifications} exact path={`${process.env.PUBLIC_URL}/notifications`} />
        {/* <Route component={About} exact path={`${process.env.PUBLIC_URL}/about`} />*/}
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/about`} />
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/imprint`} />
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/privacy`} />
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/faq`} />
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/faqs`} />
        <Route component={SimplePage} exact path={`${process.env.PUBLIC_URL}/rules`} />
        <Route component={NotificationSettings} exact path={`${process.env.PUBLIC_URL}/notification-settings`} />
        <Route component={IFrame} exact path={`${process.env.PUBLIC_URL}/iframe`} />
        <Route component={AppiFrame} exact path={`${process.env.PUBLIC_URL}/app-iframe`} />
        <Route component={WebFactSheets} exact path={`${process.env.PUBLIC_URL}/e-training/web-fact-sheets`} />
        <Route component={WebFactSheetsDetail} exact path={`${process.env.PUBLIC_URL}/e-training/web-fact-sheets/detail/:uuid`} />
        <Route component={WebFactSheetsDetail} exact path={`${process.env.PUBLIC_URL}/e-training/web-fact-sheets/detail`} />
        <Route component={WebFactSheetsSets} exact path={`${process.env.PUBLIC_URL}/e-training/web-fact-list/:factSheetId`} />
        <Route component={MobileFactSheets} exact path={`${process.env.PUBLIC_URL}/e-training/fact-sheets`} />
        <Route component={MobileFactSheetsDetail} exact path={`${process.env.PUBLIC_URL}/e-training/fact-sheets/detail`} />
        <Route component={MobileFactSheetsDetail} exact path={`${process.env.PUBLIC_URL}/e-training/fact-sheets/detail/:uuid`} />
        <Route component={WebQuiz} exact path={`${process.env.PUBLIC_URL}/e-training/web-quiz/:quizId`} />
        <Route component={WebQuiz} exact path={`${process.env.PUBLIC_URL}/e-training/web-quiz`} />
        <Route component={WebQuizResult} exact path={`${process.env.PUBLIC_URL}/e-training/web-quiz-result`} />
        <Route component={MobileQuiz} exact path={`${process.env.PUBLIC_URL}/e-training/quiz/:quizId`} />
        <Route component={MobileQuizResult} exact path={`${process.env.PUBLIC_URL}/e-training/quiz-result`} />
        <Route component={MobileScoreTableMonthly} exact path={`${process.env.PUBLIC_URL}/scoreTableMonthly`} />
        <Route component={MobileScoreTableYearly} exact path={`${process.env.PUBLIC_URL}/scoreTableYearly`} />
        <Route component={MobileAccountStatement} exact path={`${process.env.PUBLIC_URL}/AccountStatement`} />
        <Route component={MobileRankingInfo} exact path={`${process.env.PUBLIC_URL}/monthlyRankingInfo`} />
        <Route component={MobileRankingInfo} exact path={`${process.env.PUBLIC_URL}/yearlyRankingInfo`} />
        <Route component={MobileRankingInfo} exact path={`${process.env.PUBLIC_URL}/jumperRankingInfo`} />
        <Route component={MobileRankingInfo} exact path={`${process.env.PUBLIC_URL}/favoriteProductInfo`} />
      </Dashboard>
    </Switch>
  </ConnectedRouter>
);
