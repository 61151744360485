import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg x="0px" y="0px" viewBox="0 0 64 64">
      <path d="M46.851,4.086c-7.348,0-13.469,5.232-14.85,12.171C30.614,9.318,24.493,4.086,17.149,4.086 c-8.368,0-15.145,6.784-15.145,15.146C2.004,42.352,32,60,32,60s29.997-17.648,29.997-40.768 C61.997,10.87,55.216,4.086,46.851,4.086z" />
    </svg>
  </SvgIcon>
);
