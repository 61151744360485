import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { SocialActions } from '../../../../../redux';

import { getAuth, getUserId, getUserCountryCode } from '../../../../../redux/selector/auth';

import Dropdown from './Dropdown';
import SearchInput from './SearchInput';

import { MEDIA_FILTER } from '../../../../../constants/common';

import styles from './index.module.scss';

const SearchPanel = ({
  // variables
  userId,
  countryCode,
  auth,
  // actions
  getSocial,
  setSearch,
  setOwner,
  setCountry,
  setNewPopupOpen,
}) => {
  const options = [
    {
      label: i18n.t('uploadMediaDropdownFilterSelectOption1.name'),
      value: MEDIA_FILTER.allPosts,
    },
    {
      label: i18n.t('uploadMediaDropdownFilterSelectOption2.name'),
      value: MEDIA_FILTER.myPosts,
    },
    {
      label: i18n.t('uploadMediaDropdownFilterSelectOption3.name'),
      value: MEDIA_FILTER.myCountry,
    },
  ];

  const onSelect = value => {
    setNewPopupOpen(false);
    switch (value) {
      case MEDIA_FILTER.allPosts:
        setOwner({ id: null });
        setCountry({ code: null });
        getNewMedia();
        break;

      case MEDIA_FILTER.myPosts:
        setOwner({ id: userId });
        setCountry({ code: null });
        getNewMedia();
        break;

      case MEDIA_FILTER.myCountry:
        setOwner({ id: null });
        setCountry({ code: countryCode });
        getNewMedia();
        break;

      default:
        break;
    }
  };

  const onSearchInput = value => {
    setSearch({ value });
    getNewMedia();
  };

  const getNewMedia = () => {
    getSocial({
      auth: auth,
      offset: 0,
      limit: 6,
    });
  };

  return (
    <div className={styles.container}>
      <Dropdown {...{ options, onSelect }} />
      <SearchInput {...{ onSearchInput }} />
    </div>
  );
};

const mapStateToProps = state => ({
  userId: getUserId(state),
  countryCode: getUserCountryCode(state),
  auth: getAuth(state),
});

const mapDispatchToProps = dispatch => ({
  getSocial: payload => dispatch(SocialActions.getSocial(payload)),
  setSearch: payload => dispatch(SocialActions.setFilterString(payload)),
  setOwner: payload => dispatch(SocialActions.setFilterOwner(payload)),
  setCountry: payload => dispatch(SocialActions.setFilterCountry(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
