import { Colours } from '../../../../../resources';

export default {
  comment: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
    color: '#999',
    fontSize: 14,
    lineHeight: '18px',
    minHeight: 56,
    alignItems: 'center',
    backgroundColor: Colours.whiteGray,
    bottom: '0px',
    position: 'absolute',
    width: '100%',
  },
  commentTop: {
    display: 'none',
  },
  commentBtm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    // gridTmplateColumns: 'repeat(4, minmax(0, 1fr))',
    // columnGap: '0.5rem',
  },
  commentInput: {
    color: '#262626',
    fontSize: 'inherit',
    height: 38,
    maxHeight: 80,
    border: `1px solid ${Colours.darkGrayText}`,
    borderRadius: '5px',
    paddingLeft: '5px',
    width: '75%',
    // gridColumn: 'span 3 / span 3'
  },
  buttonText: {
    fontWeight: 'bold',
    padding: '5px 10px',
    backgroundColor: Colours.primary,
    color: Colours.white,
    minWidth: 'auto',
    width: '20%',
  },
};
